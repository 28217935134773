import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux_slices/auth";
import showToast from "../components/toast";
import { useSelector } from "react-redux";

const API_URL = process.env.REACT_APP_API_URL;

export function useCategoriesAPI() {
  const token = useSelector((state) => state.auth.auth.token);
  const branchId = useSelector((state) => state.branch.branch.activeId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getData = async () => {
    return await axios
      .get(`${API_URL}/list-categories?branchId=${branchId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "Error",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "Unknown Error",
            message: "An error occured. Please try again.",
          });
          return {
            status: false,
            message: "Could not load categories data. Please try again.",
          };
        }
      });
  };

  const getDataFromBranch = async (data) => {
    return await axios
      .get(`${API_URL}/list-categories?branchId=${data.branchId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "Error",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "Unknown Error",
            message: "An error occured. Please try again.",
          });
          return {
            status: false,
            message: "Could not load categories data. Please try again.",
          };
        }
      });
  };

  const addData = async (data) => {
    return await axios
      .post(
        `${API_URL}/create-category/`,
        {
          name: data.name,
          image: data.image,
          description: data.description,
          branchId: branchId,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not save category data. Please try again.",
          });
          return {
            status: false,
            message: "Could not save category data. Please try again.",
          };
        }
      });
  };

  const updateData = async (data) => {
    return await axios
      .post(
        `${API_URL}/update-category/`,
        {
          name: data.name,
          image: data.image,
          description: data.description,
          id: data.id,
          branchId: branchId,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not update category data. Please try again.",
          });
          return {
            status: false,
            message: "Could not update category data. Please try again.",
          };
        }
      });
  };

  const deleteData = async (data) => {
    return await axios
      .post(
        `${API_URL}/delete-category/`,
        {
          id: data.id,
          branchId: branchId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not delete category data. Please try again.",
          });
          return {
            status: false,
            message: "Could not delete category data. Please try again.",
          };
        }
      });
  };

  return {
    getData,
    getDataFromBranch,
    addData,
    updateData,
    deleteData,
  };
}
