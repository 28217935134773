import { useState, useRef } from "react";
import {
  createStyles,
  rem,
  Avatar,
  Table,
  Group,
  Text,
  ActionIcon,
  ScrollArea,
  NumberInput,
} from "@mantine/core";
import { IconTrash, IconMinus, IconPlus } from "@tabler/icons-react";
import EditCategoryModal from "./edit_category_modal";
import DeleteCategoryModal from "./delete_category_modal";
import CurrencyFormatter from "../../../utils/currency_formatter";
import getAvatarName from "../../../utils/get_avatar_name";

const PRODUCT_IMAGE_URL = process.env.REACT_APP_PRODUCT_IMAGE_URL;

const useStyles = createStyles((theme) => ({
  category: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopLeftRadius: theme.spacing.xs,
    borderBottomLeftRadius: theme.spacing.xs,
  },

  action: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopRightRadius: theme.spacing.xs,
    borderBottomRightRadius: theme.spacing.xs,
    textAlign: "right",
  },

  allThRight: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    textAlign: "right",
  },

  allThCenter: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    textAlign: "center",
  },

  allTh: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
  },

  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  //QuantityInput Style Satrts
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${rem(2)} ${rem(2)}`,
    borderRadius: theme.radius.sm,
    // border: `${rem(1)} solid ${
    //   theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    // }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    textAlign: "center",
    paddingRight: `${theme.spacing.sm} !important`,
    paddingLeft: `${theme.spacing.sm} !important`,
    width: rem(30),
    height: rem(28),
    flex: 1,
  },
  //QuantityInput Style Ends
}));

export function NewSalesListTable({
  products,
  deleteFunction,
  increaseQty,
  decreaseQty,
}) {
  const { classes, theme } = useStyles();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const product = useRef("");

  const rows = products.map((product, index) => (
    <tr key={index}>
      <td>
        <Group spacing="sm">
          <Avatar
            size={40}
            src={
              product?.image ? `${PRODUCT_IMAGE_URL}/${product.image}` : null
            }
            radius="sm"
            color={theme.primaryColor}
          >
            {getAvatarName(product?.name)}
          </Avatar>
          <div>
            <Text fz="sm" fw={500}>
              {product.name}
            </Text>
            <Text c="dimmed" fz="xs" fw={700}>
              {product.sku}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Group position="center">
          <QuantityInput
            product={product}
            increaseQty={increaseQty}
            decreaseQty={decreaseQty}
          />
        </Group>
      </td>
      <td>
        <Text fz="md" align="right">
          {CurrencyFormatter(product.price)}
        </Text>
      </td>
      <td>
        <Text fz="md" align="right">
          {CurrencyFormatter(product.price * product.quantity)}
        </Text>
      </td>
      <td>
        <Group spacing={0} position="right">
          <ActionIcon
            color="red"
            onClick={() => toggleRemoveModal(true, product)}
          >
            <IconTrash size="1.5rem" stroke={1.5} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  const toggleEditModal = (value) => {
    setOpenEditModal(value);
  };
  const toggleRemoveModal = (value, item) => {
    console.log("item", item);
    product.current = item;
    setOpenDeleteModal(value);
  };

  return (
    <>
      <EditCategoryModal
        opened={openEditModal}
        closeFunction={toggleEditModal}
      />
      <DeleteCategoryModal
        opened={openDeleteModal}
        closeFunction={toggleRemoveModal}
        deleteFunction={deleteFunction}
        product={product.current}
      />

      <ScrollArea>
        <Table sx={{ minWidth: 400 }} verticalSpacing="sm">
          <thead>
            <tr>
              <th className={classes.category} style={{ borderBottom: "none" }}>
                Name
              </th>
              <th
                className={classes.allThCenter}
                style={{ borderBottom: "none", textAlign: "center" }}
              >
                Quantity
              </th>
              <th
                className={classes.allThRight}
                style={{ borderBottom: "none", textAlign: "right" }}
              >
                Price (GHS)
              </th>
              <th
                className={classes.allThRight}
                style={{ borderBottom: "none", textAlign: "right" }}
              >
                Subtotal (GHS)
              </th>
              <th
                className={classes.action}
                style={{ borderBottom: "none" }}
              ></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
    </>
  );
}

const QuantityInput = ({ product, increaseQty, decreaseQty }) => {
  // const [value, setValue] = useState(1);
  const handlers = useRef();

  return (
    <Group spacing={5}>
      <ActionIcon
        size={36}
        variant="default"
        onClick={() => decreaseQty(product.id)}
      >
        <IconMinus size="1rem" stroke={2} color="red" />
      </ActionIcon>

      <NumberInput
        hideControls
        value={parseInt(product.quantity)}
        // onChange={(val) => setValue(val)}
        readOnly
        handlersRef={handlers}
        type="number"
        max={10}
        min={1}
        styles={{
          input: {
            width: rem(50),
            textAlign: "center",
            fontWeight: 500,
          },
        }}
      />

      <ActionIcon
        size={36}
        variant="default"
        onClick={() => increaseQty(product.id)}
      >
        <IconPlus size="1rem" stroke={2} color="teal" />
      </ActionIcon>
    </Group>
  );
};
