import { useState } from "react";
import {
  Button,
  Group,
  Menu,
  Select,
  createStyles,
  Text,
  Tooltip,
  UnstyledButton,
  Indicator,
} from "@mantine/core";
import {
  IconChecks,
  IconChevronDown,
  IconPoint,
  IconPointFilled,
} from "@tabler/icons-react";
import { IconFilterPlus } from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";

const useStyles = createStyles((theme) => ({
  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
}));

const SalesFilter = ({ salesFilter, setSalesFilter }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [componentFilter, setComponentFilter] = useState({
    reportType: "sales",
    dateFrom: salesFilter.dateFrom,
    dateTo: salesFilter.dateTo,
    sellerId: salesFilter.sellerId,
  });
  // const [dateFilter, setDateFilter] = useState();
  // const [sellerFilter, setSellerFilter] = useState();

  console.log("salesFilter", salesFilter);
  console.log("Now loaded");

  const { classes, theme, cx } = useStyles();

  const applyFilters = () => {
    setSalesFilter(componentFilter);
    setOpenMenu(false);
    console.log("Filter Applied");
  };

  const readFilters = () => {
    setComponentFilter({
      reportType: "sales",
      dateFrom: salesFilter.dateFrom,
      dateTo: salesFilter.dateTo,
      sellerId: salesFilter.sellerId,
    });
  };

  return (
    <Tooltip
      multiline
      width={220}
      withArrow
      label="Download the product list in PDF format."
    >
      <Menu
        shadow="md"
        width={250}
        opened={openMenu}
        // onChange={setOpenMenu}
        onOpen={() => {
          readFilters();
          setOpenMenu(true);
        }}
        onClose={() => {
          setOpenMenu(false);
          console.log("Close");
        }}
        //   trigger="hover"
        //   openDelay={100}
        //   closeDelay={400}
      >
        <Menu.Target>
          {/* <ActionIcon variant="subtle" color="blue">
                    <IconFilterPlus size="md" stroke={1.5} />
                  </ActionIcon> */}

          <UnstyledButton className={classes.user}>
            <Group spacing={5}>
              {salesFilter?.dateFrom || salesFilter.sellerId ? (
                <Indicator
                  color="teal"
                  position="middle-start"
                  size={8}
                  processing
                >
                  <Text
                    weight={500}
                    size="sm"
                    sx={{ lineHeight: 1 }}
                    ml={12}
                    // mr={3}
                    className={classes.userName}
                  >
                    Filtered By:
                  </Text>
                </Indicator>
              ) : (
                <Text
                  weight={500}
                  size="sm"
                  sx={{ lineHeight: 1 }}
                  ml={10}
                  // mr={3}
                  className={classes.userName}
                >
                  Filter By:
                </Text>
              )}

              <IconChevronDown size={12} stroke={3} />
            </Group>
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          {/* <Menu.Label>
                    <Text color={theme.primaryColor}>Filter By:</Text>
                  </Menu.Label>
                  <Menu.Divider /> */}
          <Menu.Label pl={0}>Date From</Menu.Label>
          <DatePickerInput
            maxDate={new Date()}
            placeholder="All"
            valueFormat="DD MMM YYYY"
            clearable={true}
            value={componentFilter.dateFrom}
            onChange={(value) =>
              setComponentFilter((currValue) => ({
                ...currValue,
                dateFrom: value,
              }))
            }
          />
          <Menu.Label pl={0}>Date To</Menu.Label>
          <DatePickerInput
            maxDate={new Date()}
            placeholder="All"
            valueFormat="DD MMM YYYY"
            disabled={componentFilter?.dateFrom === null ? true : false}
            minDate={componentFilter?.dateFrom}
            clearable={true}
            value={componentFilter.dateTo}
            onChange={(value) =>
              setComponentFilter((currValue) => ({
                ...currValue,
                dateTo: value,
              }))
            }
          />
          <Menu.Label pl={0}>Seller</Menu.Label>
          <Select
            placeholder="All"
            clearable
            data={[]}
            // data={sellersData}
            value={componentFilter.sellerId}
            // onChange={setSellerFilter}
          />
          <Button
            fullWidth={true}
            variant="outline"
            mt="sm"
            onClick={() => applyFilters()}
          >
            Apply
          </Button>
        </Menu.Dropdown>
      </Menu>
    </Tooltip>
  );
};

export default SalesFilter;
