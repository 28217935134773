import { useState } from "react";
import {
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Stepper,
  Box,
  Flex,
  Divider,
  Grid,
  ColorPicker,
  ColorSwatch,
  useMantineTheme,
  CheckIcon,
  rem,
} from "@mantine/core";
import bgImage from "./../../assets/images/login_bg.jpg";
import { useForm } from "@mantine/form";
import {
  IconUserCheck,
  IconShieldCheck,
  IconBuilding,
  IconCheck,
  IconUser,
} from "@tabler/icons-react";
import InputText from "../../components/input_text";

export function Register() {
  const theme = useMantineTheme();
  const [active, setActive] = useState(0);
  const [checked, setChecked] = useState(true);

  const registerForm = useForm({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      address: "",
      image: null,
      colour: "teal",
      //  sku: "",
      //  branchId: "",
      //  description: "",
      //  categoryId: null,
      //  price: "",
      //  quantity: "",
      //  available: true,
      //  alert: false,
      //  alertThreshold: 0,
    },

    validate: {
      name: (value) => value.length < 2,
      phone: (value) => value.length != 10,
      address: (value) => value.length < 2,
      colour: (value) => value === "",
      //  sku: (value) => value.length < 2,
      //  branchId: (value) => value === "",
      //  price: (value) => value === "" || value < 0.01,
      //  quantity: (value) => value === "" || value < 1,
      //  alertThreshold: (value, values) =>
      //    values.alert === true && (value === "" || value === null),
    },
  });

  const register = (values) => {
    console.log("values", values);
    //  saveProduct(values);
  };

  const setCompanyColour = (colour) => {
    registerForm.setValues({
      colour: colour,
    });
  };

  const next = () => {
    if (
      registerForm.isValid("name") &&
      registerForm.isValid("phone") &&
      registerForm.isValid("address")
    ) {
      setActive(1);
    } else {
      registerForm.validateField("name");
      registerForm.validateField("phone");
      registerForm.validateField("address");
    }
  };

  const back = () => {
    setActive(0);
  };

  return (
    <Container
      // size={660}
      my={40}
      fluid
      h="100vh"
      w="100vw"
      style={{
        backgroundImage: "url(" + bgImage + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Title
        align="center"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
      >
        Hello there!
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Just two steps and you are <br />
        ready to manage your stock.
      </Text>

      <Text color="dimmed" size="sm" align="center" mt="xl">
        <Text color="dimmed" size="sm" align="center" mt="lg">
          Already Signed Up?{" "}
          <Anchor size="sm" href="/login">
            Sign in here
          </Anchor>
        </Text>
      </Text>

      <Paper withBorder shadow="md" p="lg" mt={30} radius="md">
        {/* <Stepper size="md" active={active} onStepClick={setActive}>
          <Stepper.Step icon={<IconBuilding size="1.1rem" />}>
            {" "}
            Step 1 content: Create an account
          </Stepper.Step>
          <Stepper.Step icon={<IconUserCheck size="1.1rem" />}>
            {" "}
            Step 2 content: Verify email
          </Stepper.Step>
        </Stepper> */}

        <Flex
          //   gap="md"
          justify="center"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Box
            sx={(theme) => ({
              width: "2.9rem",
              textAlign: "center",
              height: "2.9rem",
              padding: "0.5rem",
              borderRadius: "1.45rem",
              cursor: "pointer",
              border: "0.2rem solid",
              ...(active >= 1
                ? { borderColor: theme.colors.teal[4] }
                : { borderColor: theme.colors.gray[4] }),
            })}
          >
            {active >= 1 ? (
              <IconCheck size="1.5rem" color="teal" />
            ) : (
              <IconBuilding size="1.5rem" color="gray" />
            )}
          </Box>
          <Box
            sx={(theme) => ({
              width: "5rem",
            })}
            ml="xs"
            mr="xs"
          >
            <Divider my="sm" size="md" orientation="horizontal" />
          </Box>
          <Box
            sx={(theme) => ({
              width: "2.9rem",
              textAlign: "center",
              height: "2.9rem",
              padding: "0.5rem",
              borderRadius: "1.45rem",
              cursor: "pointer",
              border: "0.2rem solid",
              ...(active > 1
                ? { borderColor: theme.colors.teal[4] }
                : { borderColor: theme.colors.gray[4] }),
            })}
          >
            {active > 1 ? (
              <IconCheck size="1.5rem" color="teal" />
            ) : (
              <IconUser size="1.5rem" color="gray" />
            )}
          </Box>
        </Flex>
        <Divider my="md" />
        <form onSubmit={registerForm.onSubmit((values) => register(values))}>
          <Grid>
            {active === 0 && (
              <>
                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputText
                    label="Organisation Name"
                    placeholder=""
                    required={true}
                    form={registerForm}
                    name="name"
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputText
                    label="Phone Number"
                    placeholder=""
                    required={true}
                    form={registerForm}
                    name="phone"
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputText
                    label="Email"
                    placeholder=""
                    form={registerForm}
                    name="email"
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputText
                    label="Location Address"
                    placeholder=""
                    required={true}
                    form={registerForm}
                    name="address"
                  />
                </Grid.Col>

                <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Divider
                    my="xs"
                    label="Select Organisation's Color"
                    labelPosition="center"
                  />
                  <Group position="center" spacing="xs">
                    <ColorSwatch
                      size={registerForm.values.colour === "red" ? 32 : 25}
                      color={theme.colors.red[6]}
                      onClick={() => setCompanyColour("red")}
                      sx={{ color: "#fff", cursor: "pointer" }}
                    >
                      {registerForm.values.colour === "red" && (
                        <CheckIcon width={rem(10)} />
                      )}
                    </ColorSwatch>

                    <ColorSwatch
                      size={registerForm.values.colour === "grape" ? 32 : 25}
                      color={theme.colors.grape[6]}
                      onClick={() => setCompanyColour("grape")}
                      sx={{ color: "#fff", cursor: "pointer" }}
                    >
                      {registerForm.values.colour === "grape" && (
                        <CheckIcon width={rem(10)} />
                      )}
                    </ColorSwatch>

                    <ColorSwatch
                      size={registerForm.values.colour === "violet" ? 32 : 25}
                      color={theme.colors.violet[6]}
                      onClick={() => setCompanyColour("violet")}
                      sx={{ color: "#fff", cursor: "pointer" }}
                    >
                      {registerForm.values.colour === "violet" && (
                        <CheckIcon width={rem(10)} />
                      )}
                    </ColorSwatch>

                    <ColorSwatch
                      size={registerForm.values.colour === "blue" ? 32 : 25}
                      color={theme.colors.blue[6]}
                      onClick={() => setCompanyColour("blue")}
                      sx={{ color: "#fff", cursor: "pointer" }}
                    >
                      {registerForm.values.colour === "blue" && (
                        <CheckIcon width={rem(10)} />
                      )}
                    </ColorSwatch>

                    <ColorSwatch
                      size={registerForm.values.colour === "teal" ? 32 : 25}
                      color={theme.colors.teal[6]}
                      onClick={() => setCompanyColour("teal")}
                      sx={{ color: "#fff", cursor: "pointer" }}
                    >
                      {registerForm.values.colour === "teal" && (
                        <CheckIcon width={rem(10)} />
                      )}
                    </ColorSwatch>

                    <ColorSwatch
                      size={registerForm.values.colour === "lime" ? 32 : 25}
                      color={theme.colors.lime[6]}
                      onClick={() => setCompanyColour("lime")}
                      sx={{ color: "#fff", cursor: "pointer" }}
                    >
                      {registerForm.values.colour === "lime" && (
                        <CheckIcon width={rem(10)} />
                      )}
                    </ColorSwatch>

                    <ColorSwatch
                      size={registerForm.values.colour === "yellow" ? 32 : 25}
                      color={theme.colors.yellow[6]}
                      onClick={() => setCompanyColour("yellow")}
                      sx={{ color: "#fff", cursor: "pointer" }}
                    >
                      {registerForm.values.colour === "yellow" && (
                        <CheckIcon width={rem(10)} />
                      )}
                    </ColorSwatch>

                    <ColorSwatch
                      size={registerForm.values.colour === "orange" ? 32 : 25}
                      color={theme.colors.orange[6]}
                      onClick={() => setCompanyColour("orange")}
                      sx={{ color: "#fff", cursor: "pointer" }}
                    >
                      {registerForm.values.colour === "orange" && (
                        <CheckIcon width={rem(10)} />
                      )}
                    </ColorSwatch>
                  </Group>
                </Grid.Col>

                <Grid.Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  offsetMd={3}
                  offsetLg={3}
                  offsetXl={3}
                >
                  <Button fullWidth mt="xl" onClick={() => next()}>
                    Next
                  </Button>
                </Grid.Col>
              </>
            )}
            {active === 1 && (
              <>
                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputText
                    label="First Name"
                    placeholder=""
                    required={true}
                    form={registerForm}
                    name="firstName"
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputText
                    label="Surname"
                    placeholder=""
                    required={true}
                    form={registerForm}
                    name="surname"
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputText
                    label="Email"
                    placeholder=""
                    required={true}
                    form={registerForm}
                    name="userEmail"
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputText
                    label="Phone Number"
                    placeholder=""
                    required={true}
                    form={registerForm}
                    name="userPhone"
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <PasswordInput
                    label="Password"
                    placeholder=""
                    withAsterisk={true}
                    {...registerForm.getInputProps("password")}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <PasswordInput
                    label="Confirm Password"
                    placeholder=""
                    withAsterisk={true}
                    {...registerForm.getInputProps("confirmPassword")}
                  />
                </Grid.Col>

                <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Checkbox
                    label={
                      <Text align="center">
                        <Text align="center">
                          I agree with the{" "}
                          <Anchor size="sm" href="/#">
                            terms and conditions
                          </Anchor>
                        </Text>
                      </Text>
                    }
                  />
                </Grid.Col>

                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Button
                    fullWidth
                    mt="xl"
                    variant="outline"
                    color="red"
                    onClick={() => back()}
                  >
                    Back
                  </Button>
                </Grid.Col>

                <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Button fullWidth mt="xl" onClick={() => register()}>
                    Register Now
                  </Button>
                </Grid.Col>
              </>
            )}
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
