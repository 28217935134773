// function Demo() {
//

//   return (

//   );
// }
import { useForm } from "@mantine/form";
import { useState } from "react";
import {
  createStyles,
  Card,
  Tabs,
  SegmentedControl,
  Timeline,
  Text,
  Container,
  Grid,
  Switch,
  useMantineTheme,
  Group,
  Divider,
  NumberInput,
  rem,
  Button,
} from "@mantine/core";
import {
  IconFoldUp,
  IconSquareToggle,
  IconShoppingBag,
  IconCurrencyCent,
  IconBell,
  IconCheck,
  IconX,
  IconDeviceFloppy,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({}));

const DetailsTab = ({ product, updateNotificationFn, modalLoading }) => {
  const { classes, theme } = useStyles();
  const [activeTab, setActiveTab] = useState("notification");
  const textColor =
    theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.dark[3];
  const fontWeightNormal = 500;
  const fontWeightBold = 700;

  return (
    <Card withBorder shadow={1} radius="md">
      <Card.Section inheritPadding py="xl" withBorder>
        <SegmentedControl
          color={theme.primaryColor}
          mt="sm"
          disabled={!product}
          // size="md"
          // fullWidth
          value={activeTab}
          onChange={setActiveTab}
          data={[
            { label: "Notification Info", value: "notification" },
            { label: "Product History", value: "history" },
            // { label: "Price Hsitory", value: "price" },
            // { label: "Sales History", value: "sales" },
          ]}
        />
      </Card.Section>

      <Tabs value={activeTab} onTabChange={setActiveTab}>
        <Tabs.Panel value="notification" mt="md">
          <InventoryInfo
            product={product}
            updateNotificationFn={updateNotificationFn}
            modalLoading={modalLoading}
          />
        </Tabs.Panel>
        <Tabs.Panel value="history" mt="md">
          <History />
        </Tabs.Panel>
        {/* <Tabs.Panel value="price">Second panel</Tabs.Panel>
        <Tabs.Panel value="sales">Second panel</Tabs.Panel> */}
      </Tabs>
    </Card>
  );
};

export default DetailsTab;

const InventoryInfo = ({ product, updateNotificationFn, modalLoading }) => {
  const theme = useMantineTheme();
  // const [available, setAvailable] = useState(
  //   product?.available === 1 ? true : false
  // );
  // const [stockAlert, setStockAlert] = useState(
  //   product?.alertOnLowStock === 1 ? true : false
  // );
  // const [value, setValue] = useState(0);
  const textColor =
    theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.dark[3];
  const fontWeightNormal = 500;
  const fontWeightBold = 700;

  const notificationForm = useForm({
    initialValues: {
      // id: product?.id,
      // sku: product?.sku,
      // branchId: product?.branchId,
      available: product?.available === 1 ? true : false,
      alert: product?.alertOnLowStock === 1 ? true : false,
      alertThreshold: product?.alertThreshold,
    },

    validate: {
      alertThreshold: (value, values) =>
        values.alert === true &&
        (value === "" || value === null || value > product?.quantity),
    },
  });

  const save = (values) => {
    updateNotificationFn(values);
  };

  // useEffect(() => {
  //   setAvailable(product?.available === 1 ? true : false);
  //   console.log("product?.available", product?.available);
  // }, []);

  return (
    <Container pb="xl" fluid={true}>
      <form onSubmit={notificationForm.onSubmit((values) => save(values))}>
        <Grid>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider label="Status" mb="xs" labelPosition="center" />
            <Group position="apart">
              <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                Make Available?
              </Text>
              <Switch
                // labelPosition={
                //   notificationForm.values.available ? "right" : "left"
                // }
                disabled={modalLoading || !product}
                checked={notificationForm.values.available}
                color="green"
                size="md"
                {...notificationForm.getInputProps("available", {
                  type: "radio",
                })}
                thumbIcon={
                  notificationForm.values.available ? (
                    <IconCheck
                      size="0.8rem"
                      color={theme.colors.teal[theme.fn.primaryShade()]}
                      stroke={3}
                    />
                  ) : (
                    <IconX
                      size="0.8rem"
                      color={theme.colors.red[theme.fn.primaryShade()]}
                      stroke={3}
                    />
                  )
                }
              />
            </Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider label="Stock Alert" mb="xs" labelPosition="center" />
            <Group position="apart">
              <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                Alert On Low Stock?
              </Text>
              <Switch
                disabled={modalLoading || !product}
                mt={8}
                // labelPosition="left"
                checked={notificationForm.values.alert}
                color="green"
                size="md"
                {...notificationForm.getInputProps("alert", {
                  type: "radio",
                })}
                thumbIcon={
                  notificationForm.values.alert ? (
                    <IconCheck
                      size="0.8rem"
                      color={theme.colors.teal[theme.fn.primaryShade()]}
                      stroke={3}
                    />
                  ) : (
                    <IconX
                      size="0.8rem"
                      color={theme.colors.red[theme.fn.primaryShade()]}
                      stroke={3}
                    />
                  )
                }
              />
            </Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Group position="apart">
              <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                Threshold
              </Text>
              <Group spacing={5}>
                <NumberInput
                  disabled={
                    !notificationForm.values.alert || modalLoading || !product
                  }
                  type="number"
                  hideControls
                  // value={value}
                  // onChange={(val) => setValue(val)}
                  max={product?.quantity}
                  min={0}
                  styles={{ input: { width: rem(54), textAlign: "center" } }}
                  {...notificationForm.getInputProps("alertThreshold")}
                />
              </Group>
            </Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider />
            <Group position="right" mt="md">
              <Button
                leftIcon={<IconDeviceFloppy size="1rem" />}
                // size="md"
                px="xl"
                type="submit"
                loading={modalLoading}
                disabled={!product}
              >
                Save
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    </Container>
  );
};

const History = () => {
  return (
    <Timeline active={5} bulletSize={32} lineWidth={5} color="teal">
      <Timeline.Item
        bullet={<IconFoldUp size={22} />}
        title="Restock"
        active
        color="green"
      >
        <Text color="dimmed" size="sm">
          Added 3 to the existing 17 stock making 20 in total
        </Text>
        <Text size="xs" mt={4}>
          2 hours ago
        </Text>
      </Timeline.Item>

      <Timeline.Item
        bullet={<IconCurrencyCent size={22} />}
        title="Price Change"
        active
        color="orange"
      >
        <Text color="dimmed" size="sm">
          Price changed from 300 to 450
        </Text>
        <Text size="xs" mt={4}>
          52 minutes ago
        </Text>
      </Timeline.Item>

      <Timeline.Item
        bullet={<IconShoppingBag size={22} />}
        title="Sales"
        active
        color="violet"
      >
        <Text color="dimmed" size="sm">
          Sold 30 amounting to $500
        </Text>
        <Text size="xs" mt={4}>
          Sold By: Sales Person
        </Text>
        <Text size="xs" mt={4}>
          52 minutes ago
        </Text>
      </Timeline.Item>

      <Timeline.Item
        bullet={<IconSquareToggle size={22} />}
        title="Status Changed"
        active
        color="pink"
      >
        <Text color="dimmed" size="sm">
          Changed status to Available
        </Text>
        <Text size="xs" mt={4}>
          52 minutes ago
        </Text>
      </Timeline.Item>

      <Timeline.Item
        bullet={<IconBell size={22} />}
        title="Sent Notification"
        active
        color="blue"
      >
        <Text color="dimmed" size="sm">
          Sent notification for low stock
        </Text>
        <Text size="xs" mt={4}>
          52 minutes ago
        </Text>
      </Timeline.Item>
    </Timeline>
  );
};
