import { useState, useEffect } from "react";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import SalesTable from "./components/sales_table";
import { useSalesAPI } from "../../services/sales";
import ShowLoader from "../../components/loader";

export default function Invoice() {
  document.title = "Invoice || Smart Stock";
  const { getSales } = useSalesAPI();

  const [salesData, setSalesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sellers, setSellers] = useState([]);
  const [filterData, setFilterData] = useState({ date: null, sellerId: null });

  const setFilter = async (value) => {
    setFilterData(value);
  };

  const searchSales = (value) => {
    const result = salesData.filter((obj) =>
      JSON.stringify(obj).toLowerCase().includes(value.toString().toLowerCase())
    );
    console.log("serahFor: ", value);
    console.log("result: ", result);
  };

  const formatSellersData = (sellers) => {
    // console.log("formatSellersData");
    let sellerArray = [];
    sellers.map((seller) => {
      if (
        sellerArray.filter((sellerData) => sellerData.value === seller?.soldBy)
          .length === 0
      ) {
        sellerArray.push({ value: seller?.soldBy, label: seller?.seller });
      }
    });
    return sellerArray;
  };

  const getSalesData = async () => {
    try {
      setIsLoading(true);

      const results = await getSales(filterData);
      if (results.status) {
        setSalesData(results.sales);
        if (sellers.length === 0) {
          setSellers(formatSellersData(results.sales));
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSalesData();
  }, [filterData]);

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch
            pageTitle="Invoice"
            onChangeFn={getSalesData}
            disableBranchSelector={isLoading}
          />
        </Grid.Col>
        {isLoading && <ShowLoader text="Loading Invoice..." mt={150} />}
        {!isLoading && (
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <SalesTable
              sales={salesData}
              filterFn={setFilter}
              filterData={filterData}
              sellersData={sellers}
              searchFn={searchSales}
            />
          </Grid.Col>
        )}
      </Grid>
    </Container>
  );
}
