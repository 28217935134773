import { useState } from "react";
import { Card, Group, createStyles, Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { UsersListTable } from "./users_list_table";
import AddBranchModal from "./add_branch_modal";
import { NavLink } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
}));

const UsersTable = ({
  users,
  isModalLoading,
  addBranchFn,
  updateBranchFn,
  deleteBranchFn,
}) => {
  const { classes, theme, cx } = useStyles();
  const [openAddModal, setOpenAddModal] = useState(false);

  const toggleAddModal = (value) => {
    setOpenAddModal(value);
  };

  return (
    <>
      <AddBranchModal
        opened={openAddModal}
        closeFunction={toggleAddModal}
        isModalLoading={isModalLoading}
        operationFn={addBranchFn}
      />

      <Card withBorder shadow={1} radius="md">
        <Card.Section inheritPadding py="xl">
          <Group position="right">
            <Group>
              <NavLink to="/add-new-user">
                <Button leftIcon={<IconPlus />} color={theme.primaryColor}>
                  Add New
                </Button>
              </NavLink>
            </Group>
          </Group>
        </Card.Section>
        <UsersListTable
          users={users}
          updateBranchFn={updateBranchFn}
          deleteBranchFn={deleteBranchFn}
          isModalLoading={isModalLoading}
        />
      </Card>
    </>
  );
};

export default UsersTable;
