import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux_slices/auth";
import showToast from "../components/toast";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const API_URL = process.env.REACT_APP_API_URL;

export function useReportsAPI() {
  const token = useSelector((state) => state.auth.auth.token);
  const branchId = useSelector((state) => state.branch.branch.activeId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getReport = async (data) => {
    console.log("data", data);

    let url = `${API_URL}/report?branchId=${branchId}&type=${data.reportType}`;

    // ********** Sales Filters ************
    // Add date  from filter if not null
    if (data?.dateFrom) {
      url = `${url}&datefrom=${dayjs(new Date(data?.dateFrom)).format(
        "YYYY-MM-DD"
      )}`;
    }

    // Add date to filter if not null
    if (data?.dateTo) {
      url = `${url}&dateto=${dayjs(new Date(data?.dateTo)).format(
        "YYYY-MM-DD"
      )}`;
    }

    // Add sellerId filter if not null
    if (data?.sellerId) {
      url = `${url}&sellerid=${data?.sellerId}`;
    }

    // ********** Inventory Filters ************
    // Add stock filter if not null
    if (data?.stock) {
      url = `${url}&stock=${data?.stock}`;
    }

    // Add status filter if not null
    if (data?.status) {
      url = `${url}&status=${data?.status}`;
    }

    // Add categoryId filter if not null
    if (data?.categoryId) {
      url = `${url}&categoryid=${data?.categoryId}`;
    }

    console.log("Final URL: ", url);

    return await axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "Error",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "Unknown Error",
            message: "An error occured. Please try again.",
          });
          return {
            status: false,
            message: "Could not load report data. Please try again.",
          };
        }
      });
  };

  // const searchSales = async ({ searchString }) => {
  //   return await axios
  //     .post(
  //       `${API_URL}/search-sales-product/`,
  //       { searchString: searchString, branchId: branchId },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (!res.data.status) {
  //         showToast({
  //           //Only show this if there's an error
  //           color: "red",
  //           title: "An error occured",
  //           message: res.data.message,
  //         });
  //       }
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       if (
  //         err?.response?.status === 422 &&
  //         err?.response?.data?.status === false &&
  //         err?.response?.data?.message === "Token Expired"
  //       ) {
  //         showToast({
  //           color: "red",
  //           title: "Session Expired",
  //           message: "Please login to continue",
  //         });
  //         navigate("/login");
  //         dispatch(logout()); //Clearing the user data in the redux state manager
  //       } else {
  //         showToast({
  //           color: "red",
  //           title: "An error occured",
  //           message: "Could not get product data. Please try again.",
  //         });
  //         return {
  //           status: false,
  //           message: "Could not get product data. Please try again.",
  //         };
  //       }
  //     });
  // };

  // const completeSale = async ({ products, customerId }) => {
  //   return await axios
  //     .post(
  //       `${API_URL}/complete-sales/`,
  //       { products: products, customerId: customerId, branchId: branchId },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (!res.data.status) {
  //         showToast({
  //           //Only show this if there's an error
  //           color: "red",
  //           title: "An error occured",
  //           message: res.data.message,
  //         });
  //       }
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       if (
  //         err?.response?.status === 422 &&
  //         err?.response?.data?.status === false &&
  //         err?.response?.data?.message === "Token Expired"
  //       ) {
  //         showToast({
  //           color: "red",
  //           title: "Session Expired",
  //           message: "Please login to continue",
  //         });
  //         navigate("/login");
  //         dispatch(logout()); //Clearing the user data in the redux state manager
  //       } else {
  //         showToast({
  //           color: "red",
  //           title: "An error occured",
  //           message: "Could not complete the sales. Please try again.",
  //         });
  //         return {
  //           status: false,
  //           message: "Could not get product data. Please try again.",
  //         };
  //       }
  //     });
  // };

  // const saleDetails = async ({ id, invoice }) => {
  //   return await axios
  //     .get(`${API_URL}/sale-details?id=${id}&invoice=${invoice}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       if (!res.data.status) {
  //         showToast({
  //           //Only show this if there's an error
  //           color: "red",
  //           title: "Error",
  //           message: res.data.message,
  //         });
  //       }
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       if (
  //         err?.response?.status === 422 &&
  //         err?.response?.data?.status === false &&
  //         err?.response?.data?.message === "Token Expired"
  //       ) {
  //         showToast({
  //           color: "red",
  //           title: "Session Expired",
  //           message: "Please login to continue",
  //         });
  //         navigate("/login");
  //         dispatch(logout()); //Clearing the user data in the redux state manager
  //       } else {
  //         showToast({
  //           color: "red",
  //           title: "An error occured",
  //           message: "Could not load sale data. Please try again.",
  //         });
  //         return {
  //           status: false,
  //           message: "Could not load sale data. Please try again.",
  //         };
  //       }
  //     });
  // };

  return {
    getReport,
  };
}
