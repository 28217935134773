import { useState } from "react";
import {
  Card,
  Group,
  ActionIcon,
  createStyles,
  Divider,
  Tooltip,
  Select,
} from "@mantine/core";
import { IconPrinter } from "@tabler/icons-react";

// import { ProductsListTable } from "./products_list_table";
import { NavLink } from "react-router-dom";
import { SalesReportListTable } from "./sales_report_list_table";
import { InventoryReportListTable } from "./inventory_report_list_table";
import SalesFilter from "./sales_filter";
import InventoryFilter from "./inventory_filter";
import { useEffect } from "react";

const useStyles = createStyles((theme) => ({
  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
}));

const ReportsCard = ({
  reports,
  reportType,
  changeReportTypeFn,
  salesFilter,
  setSalesFilter,
  inventoryFilter,
  filterReportFn,
  modalLoading,
}) => {
  const { classes, theme, cx } = useStyles();
  return (
    <Card withBorder shadow={1} radius="md" mih={500}>
      <Card.Section inheritPadding py="xl">
        <Group position="apart">
          <Select
            data={[
              { value: "sales", label: "Sales" },
              { value: "inventory", label: "Inventory" },
            ]}
            // data={sellersData}
            value={reportType}
            onChange={(value) => changeReportTypeFn(value)}
          />

          <Group>
            {/* <NavLink
              to="/add-new-product"
              //   style={({ isActive }) => ({
              //     color: isActive ? "greenyellow" : "white",
              //   })}
            >
              <Button leftIcon={<IconPlus />} color={theme.primaryColor}>
                Add New
              </Button>
            </NavLink> */}

            {reportType === "sales" && (
              <SalesFilter
                salesFilter={salesFilter}
                setSalesFilter={setSalesFilter}
              />
            )}
            {reportType === "inventory" && (
              <InventoryFilter inventoryFilter={inventoryFilter} />
            )}

            <Divider orientation="vertical" />

            <Tooltip
              multiline={true}
              width={140}
              withArrow
              label={`Print the ${reportType} report list`}
            >
              <NavLink
                to={`/print-${reportType}-report`}
                // target="_blank"
                state={{ data: reports, filter: salesFilter }}
              >
                <ActionIcon variant="subtle" color="blue">
                  <IconPrinter size="md" stroke={1.5} />
                </ActionIcon>
              </NavLink>
            </Tooltip>
          </Group>
        </Group>
        <Divider mt="md" />
      </Card.Section>

      {reportType === "sales" && <SalesReportListTable sales={reports.items} />}
      {reportType === "inventory" && (
        <InventoryReportListTable inventory={reports.items} />
      )}
    </Card>
  );
};

export default ReportsCard;
