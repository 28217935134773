import { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Divider,
  Group,
  Text,
  Button,
  Image,
  rem,
  Modal,
  useMantineTheme,
  Flex,
  NumberInput,
} from "@mantine/core";

import { IconDeviceFloppy, IconX } from "@tabler/icons-react";

const PRODUCT_IMAGE_URL = process.env.REACT_APP_PRODUCT_IMAGE_URL;

const RestockProductModal = ({
  opened,
  closeFunction,
  operationFn,
  isLoading,
  product,
}) => {
  console.log("Restock-product", product);

  const theme = useMantineTheme();
  const [currentQty, setCurrentQty] = useState(product?.quantity || 0);
  const [additionalQty, setAdditionalQty] = useState(0);
  const [totalQty, setTotalQty] = useState(product?.quantity || 0);

  const closeModal = () => {
    setAdditionalQty(0);
    setTotalQty(product?.quantity);
    closeFunction(false);
  };

  const calculateTotalQty = () => {
    if (typeof additionalQty === "number") {
      setTotalQty(currentQty + additionalQty);
    } else {
      setTotalQty(currentQty);
    }
  };

  useEffect(() => {
    setCurrentQty(product?.quantity || 0);
    setTotalQty(product?.quantity || 0);
  }, [product]);

  useEffect(() => {
    calculateTotalQty();
  }, [additionalQty]);

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={false}
      size="lg"
      title="Restock Product"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <Grid>
          <Grid.Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ minHeight: rem(100) }}
          >
            <Group>
              <Image
                radius="md"
                // src={imageUrl}
                width={100}
                height={100}
                fit="contain"
                src={
                  product?.image
                    ? `${PRODUCT_IMAGE_URL}/${product?.image}`
                    : null
                }
                // alt="With custom placeholder"
                withPlaceholder
              />
              <Flex
                gap="xs"
                justify="flex-start"
                align="flex-start"
                direction="column"
                wrap="wrap"
              >
                <Text size="lg" truncate={true}>
                  {product?.name}
                </Text>
                <Text size="xs" color={theme.primaryColor} fw={700}>
                  {product?.sku}
                </Text>
              </Flex>
            </Group>
            <Divider mt="sm" />
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Group position="apart">
              <Text>Current Quantity:</Text>
              <NumberInput
                disabled={isLoading}
                size="md"
                value={currentQty}
                // placeholder="18"
                // disabled
                readOnly
                type="number"
                hideControls
                styles={{ input: { width: rem(100), textAlign: "right" } }}
              />
            </Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Group position="apart">
              <Text>Quantity To Add:</Text>
              <NumberInput
                disabled={isLoading}
                size="md"
                value={additionalQty}
                placeholder="0"
                min={0}
                type="number"
                hideControls
                onChange={(value) => setAdditionalQty(value)}
                styles={{ input: { width: rem(100), textAlign: "right" } }}
              />
            </Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Group position="apart">
              <Text>Total Quantity:</Text>
              <NumberInput
                disabled={isLoading}
                size="md"
                value={totalQty}
                // defaultValue={0}
                // placeholder="0"
                readOnly
                type="number"
                hideControls
                styles={{ input: { width: rem(100), textAlign: "right" } }}
              />
            </Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider mb="md" />
            <Flex
              gap="md"
              justify="flex-end"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Button
                variant="outline"
                leftIcon={<IconX size="1rem" />}
                color="red"
                onClick={closeModal}
                px="xl"
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                leftIcon={<IconDeviceFloppy size="1rem" />}
                color={theme.primaryColor}
                // size="md"
                px="xl"
                onClick={() =>
                  operationFn({
                    id: product?.id,
                    sku: product?.sku,
                    additionalQty: additionalQty,
                  })
                }
                loading={isLoading}
                disabled={currentQty === totalQty}
              >
                {isLoading ? "Updating..." : "Update"}
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};

export default RestockProductModal;
