import { useState } from "react";
import {
  Button,
  Group,
  Menu,
  Select,
  createStyles,
  Text,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { IconFilterPlus } from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";

const useStyles = createStyles((theme) => ({
  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
}));

const InventoryFilter = ({ inventoryFilter }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [dateFilter, setDateFilter] = useState();
  const [sellerFilter, setSellerFilter] = useState();

  const { classes, theme, cx } = useStyles();

  return (
    <Tooltip
      multiline
      width={220}
      withArrow
      label="Download the product list in PDF format."
    >
      <Menu
        shadow="md"
        width={200}
        opened={openMenu}
        onChange={setOpenMenu}
        //   trigger="hover"
        //   openDelay={100}
        //   closeDelay={400}
      >
        <Menu.Target>
          {/* <ActionIcon variant="subtle" color="blue">
                    <IconFilterPlus size="md" stroke={1.5} />
                  </ActionIcon> */}

          <UnstyledButton className={classes.user}>
            <Group spacing={7}>
              <Text
                weight={500}
                size="sm"
                sx={{ lineHeight: 1 }}
                mr={3}
                className={classes.userName}
              >
                Filter By:
              </Text>
              <IconChevronDown size={12} stroke={1.5} />
            </Group>
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          {/* <Menu.Label>
                    <Text color={theme.primaryColor}>Filter By:</Text>
                  </Menu.Label>
                  <Menu.Divider /> */}
          <Menu.Label pl={0}>Stock</Menu.Label>
          <Select
            placeholder="All"
            clearable
            data={[
              { value: "all", label: "All" },
              { value: "instock", label: "In Stock" },
              { value: "lowonstock", label: "Low On Stock" },
              { value: "outofstock", label: "Out Of Stock" },
            ]}
            // data={sellersData}
            value={sellerFilter}
            onChange={setSellerFilter}
          />
          <Menu.Label pl={0}>Status</Menu.Label>
          <Select
            placeholder="All"
            clearable
            data={[
              { value: "all", label: "All" },
              { value: "available", label: "Available" },
              { value: "unavailable", label: "Unavailable" },
            ]}
            // data={sellersData}
            value={sellerFilter}
            onChange={setSellerFilter}
          />
          <Menu.Label pl={0}>Category</Menu.Label>
          <Select
            placeholder="All"
            clearable
            data={[]}
            // data={sellersData}
            value={sellerFilter}
            onChange={setSellerFilter}
          />
          <Button
            fullWidth={true}
            variant="outline"
            mt="sm"
            // onClick={() => applyFilter()}
          >
            Search
          </Button>
        </Menu.Dropdown>
      </Menu>
    </Tooltip>
  );
};

export default InventoryFilter;
