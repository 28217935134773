import { useState } from "react";
import {
  createStyles,
  Card,
  Tabs,
  SegmentedControl,
  Timeline,
  Text,
  Container,
  Grid,
  Switch,
  useMantineTheme,
  Group,
  Button,
} from "@mantine/core";
import {
  IconFoldUp,
  IconSquareToggle,
  IconShoppingBag,
  IconCurrencyCent,
  IconBell,
  IconCheck,
  IconX,
  IconDeviceFloppy,
} from "@tabler/icons-react";
import ToggleUserStatusModal from "./toggle_user_status_modal";

const useStyles = createStyles((theme) => ({}));

const DetailsTab = ({ user }) => {
  const { classes, theme } = useStyles();
  const [activeTab, setActiveTab] = useState("security");
  // const textColor = theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.dark[3];;
  // const fontWeightNormal = 500;
  // const fontWeightBold = 700;

  return (
    <Card withBorder shadow={1} radius="md">
      <Card.Section inheritPadding py="xl" withBorder>
        <SegmentedControl
          color={theme.primaryColor}
          mt="sm"
          // size="md"
          // fullWidth
          value={activeTab}
          onChange={setActiveTab}
          data={[
            { label: "Security", value: "security" },
            { label: "Sessions", value: "sessions" },
          ]}
        />
      </Card.Section>

      <Tabs value={activeTab} onTabChange={setActiveTab}>
        <Tabs.Panel value="security" mt="md">
          <SecurityTab user={user} />
        </Tabs.Panel>
        <Tabs.Panel value="sessions" mt="md">
          <SessionTabs />
        </Tabs.Panel>
      </Tabs>
    </Card>
  );
};

export default DetailsTab;

const SecurityTab = ({ user }) => {
  const theme = useMantineTheme();
  const [userActive, setUserActive] = useState(
    user?.status === 1 ? true : false
  );
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const textColor =
    theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.dark[3];
  const fontWeightNormal = 500;
  const fontWeightBold = 700;

  const revertUserStatus = () => {
    setUserActive(!userActive);
  };

  const toggleserStatusModal = () => {
    setOpenStatusModal(!openStatusModal);
  };

  const modalCancelFn = () => {
    revertUserStatus();
    toggleserStatusModal();
  };

  const saveUserStatus = () => {
    console.log("userStatusSaved");
    toggleserStatusModal();
  };

  return (
    <Container pb="xl" fluid={true}>
      <ToggleUserStatusModal
        opened={openStatusModal}
        closeFunction={modalCancelFn}
        newStatus={userActive}
        operationFn={saveUserStatus}
      />

      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Group position="apart">
            <Text fz="md" fw={fontWeightNormal} color={textColor}>
              Status
            </Text>
            <Switch
              mt={8}
              labelPosition="left"
              checked={userActive}
              onChange={(event) => {
                setUserActive(event.currentTarget.checked);
                toggleserStatusModal();
              }}
              color="teal"
              size="md"
              //   label="Alert On Low Stock?"
              thumbIcon={
                userActive ? (
                  <IconCheck
                    size="0.8rem"
                    color={theme.colors.teal[theme.fn.primaryShade()]}
                    stroke={3}
                  />
                ) : (
                  <IconX
                    size="0.8rem"
                    color={theme.colors.red[theme.fn.primaryShade()]}
                    stroke={3}
                  />
                )
              }
            />
          </Group>
        </Grid.Col>
        {/* 
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Group position="right" mt="md">
            <Button
              leftIcon={<IconDeviceFloppy size="1rem" />}
              // size="md"
              px="xl"
            >
              Save
            </Button>
          </Group>
        </Grid.Col> */}
      </Grid>
    </Container>
  );
};

const SessionTabs = () => {
  return (
    <Timeline active={5} bulletSize={32} lineWidth={5} color="teal">
      <Timeline.Item
        bullet={<IconFoldUp size={22} />}
        title="Restock"
        active
        color="green"
      >
        <Text color="dimmed" size="sm">
          Added 3 to the existing 17 stock making 20 in total
        </Text>
        <Text size="xs" mt={4}>
          2 hours ago
        </Text>
      </Timeline.Item>

      <Timeline.Item
        bullet={<IconCurrencyCent size={22} />}
        title="Price Change"
        active
        color="orange"
      >
        <Text color="dimmed" size="sm">
          Price changed from 300 to 450
        </Text>
        <Text size="xs" mt={4}>
          52 minutes ago
        </Text>
      </Timeline.Item>

      <Timeline.Item
        bullet={<IconShoppingBag size={22} />}
        title="Sales"
        active
        color="violet"
      >
        <Text color="dimmed" size="sm">
          Sold 30 amounting to $500
        </Text>
        <Text size="xs" mt={4}>
          Sold By: Sales Person
        </Text>
        <Text size="xs" mt={4}>
          52 minutes ago
        </Text>
      </Timeline.Item>

      <Timeline.Item
        bullet={<IconSquareToggle size={22} />}
        title="Status Changed"
        active
        color="pink"
      >
        <Text color="dimmed" size="sm">
          Changed status to Available
        </Text>
        <Text size="xs" mt={4}>
          52 minutes ago
        </Text>
      </Timeline.Item>

      <Timeline.Item
        bullet={<IconBell size={22} />}
        title="Sent Notification"
        active
        color="blue"
      >
        <Text color="dimmed" size="sm">
          Sent notification for low stock
        </Text>
        <Text size="xs" mt={4}>
          52 minutes ago
        </Text>
      </Timeline.Item>
    </Timeline>
  );
};
