import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import InvoiceFormatter from "../../../utils/invoice_formatter";
import CurrencyFormatter from "../../../utils/currency_formatter";
import { useEffect } from "react";

const PRODUCT_IMAGE_URL = process.env.REACT_APP_PRODUCT_IMAGE_URL;
const COMPANY_IMAGE_URL = process.env.REACT_APP_COMPANY_IMAGE_URL;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    //595
    margin: 38,
  },
  topSection: {
    display: "Flex",
    flexDirection: "row",
    alignContent: "space-between",
    marginBottom: "30px",
  },
  section: {
    // margin: 10,
    // padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  companyLogo: {
    backgroundColor: "#eeeeee",
    width: 55,
    height: 55,
  },
  companyName: {
    textAlign: "right",
  },

  textHeader: {
    fontSize: 18,
  },

  textTitle: {
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 700,
    fontFamily: "Times-Bold",
  },
  textBody: {
    fontSize: 12,
    lineHeight: 1.4,
    fontFamily: "Times-Roman",
  },

  //

  table: {
    display: "table",
    width: 519,
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    fontFamily: "Times-Roman",
    // marginBottom: 38,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    fontFamily: "Times-Roman",
  },
  tableColNo: {
    width: "6%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableColItem: {
    width: "56%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    flexDirection: "row",
  },
  tableColPrice: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableColQty: {
    width: "8%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableColSubTotal: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableCol: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
  },
  tableCellHeadLeft: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Bold",
  },
  tableCellHeadRight: {
    textAlign: "right",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Bold",
  },
  tableCellHeadCenter: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Bold",
  },
  tableCellImage: {
    backgroundColor: "#eeeeee",
    marginLeft: 3,
    marginTop: 3,
    marginBottom: 3,
    marginRight: 3,
    width: 25,
    height: 25,
  },
  tableCellLeft: {
    marginTop: 3,
    marginBottom: 1,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Roman",
  },
  tableCellRight: {
    textAlign: "right",
    marginTop: 3,
    marginBottom: 3,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Roman",
  },
  tableCellCenter: {
    textAlign: "center",
    marginTop: 3,
    marginBottom: 3,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Roman",
  },
  tableCellSKULeft: {
    marginTop: 1,
    marginBottom: 3,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 9,
    color: "#3c3f42",
    fontFamily: "Times-Roman",
  },
  tableColGrandTotal: {
    display: "Flex",
    flexDirection: "row",
    width: 519,
  },
  tableColGrandTotalEmpty: {
    width: "62%",
    borderStyle: "solid",
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableColGrandTotalText: {
    width: "19%",
    borderStyle: "solid",
    borderTopWidth: 1.5,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableColGrandTotaLAmount: {
    width: "19%",
    borderStyle: "solid",
    borderTopWidth: 1.5,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
});

// Create Document Component
export default function PrintInvoice() {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location?.state?.data;

  // const checkData = () => {
  console.log("data", data);
  if (!data) {
    navigate("/sales");
  }
  // };

  // useEffect(() => {
  //   checkData();
  // }, []);

  const rows = data?.items.map((item, index) => (
    <View style={styles.tableRow} key={index}>
      <View style={styles.tableColNo}>
        <Text style={styles.tableCellRight}>{index + 1}</Text>
      </View>
      <View style={styles.tableColItem}>
        <Image
          style={styles.tableCellImage}
          src={item?.image ? `${PRODUCT_IMAGE_URL}/${item?.image}` : "NoImage"}
        />
        <div>
          <Text style={styles.tableCellLeft}>{item?.name}</Text>
          <Text style={styles.tableCellSKULeft}>{item?.productSKU}</Text>
        </div>
      </View>
      <View style={styles.tableColPrice}>
        <Text style={styles.tableCellRight}>
          {item?.price ? CurrencyFormatter(item?.price) : 0.0}
        </Text>
      </View>
      <View style={styles.tableColQty}>
        <Text style={styles.tableCellCenter}>{item?.quantity}</Text>
      </View>
      <View style={styles.tableColSubTotal}>
        <Text style={styles.tableCellRight}>
          {item?.subtotal ? CurrencyFormatter(item?.subtotal) : 0.0}
        </Text>
      </View>
    </View>
  ));

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          {/* TOP HEADER */}

          <View style={styles.topSection}>
            <div
              style={{
                deplay: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // alignContent: "space-between",
                width: 519,
              }}
            >
              <div>
                <Image
                  style={styles.companyLogo}
                  src={
                    data?.company?.logo
                      ? `${COMPANY_IMAGE_URL}/${data?.company?.logo}`
                      : "NoImage"
                  }
                />
              </div>
              <div style={{ textAlign: "right", width: "100%" }}>
                <Text style={styles.textTitle}>
                  {data?.company?.name ? data?.company?.name : null}
                </Text>
                <Text style={styles.textBody}>
                  {data?.company?.branchAddress
                    ? data?.company?.branchAddress
                    : data.company.mainAddress}
                </Text>
                <Text style={styles.textBody}>
                  {data?.company?.branchPhone
                    ? data?.company?.branchPhone
                    : data?.company?.mainPhone}
                </Text>
              </div>
            </div>
          </View>

          {/* DATA HEADER */}

          <View style={styles.topSection}>
            <div
              style={{
                deplay: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // alignContent: "space-between",
                width: 519,
              }}
            >
              <div>
                <Text style={styles.textTitle}>BILL TO:</Text>
                <Text style={styles.textBody}>
                  {data?.details?.name ? data?.details?.name : null}
                </Text>
                <Text style={styles.textBody}>
                  {data?.details?.phone ? data?.details?.phone : null}
                </Text>
              </div>
              <div style={{ textAlign: "right", width: "100%" }}>
                <Text style={styles.textTitle}>INVOICE</Text>
                <Text style={styles.textBody}>
                  {data?.details?.invoiceNo
                    ? "Invoice No: " +
                      InvoiceFormatter(data?.details?.invoiceNo)
                    : "Invoice No: 0000000"}
                </Text>
                <Text style={styles.textBody}>
                  {data?.details?.dateCreated
                    ? // ? data?.details?.dateCreated

                      dayjs(new Date(data?.details?.dateCreated)).format(
                        "DD MMM YYYY HH:mm:ss"
                      )
                    : null}
                </Text>
              </div>
            </div>
          </View>

          {/* TABLE DATA */}

          {/* Row Headers */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColNo}>
                <Text style={styles.tableCellHeadRight}>No.</Text>
              </View>
              <View style={styles.tableColItem}>
                <Text style={styles.tableCellHeadLeft}>Item</Text>
              </View>
              <View style={styles.tableColPrice}>
                <Text style={styles.tableCellHeadRight}>Price (GHS)</Text>
              </View>
              <View style={styles.tableColQty}>
                <Text style={styles.tableCellHeadCenter}>Qty</Text>
              </View>
              <View style={styles.tableColSubTotal}>
                <Text style={styles.tableCellHeadRight}>Total (GHS)</Text>
              </View>
            </View>

            {/* Row Items  */}
            {rows}
          </View>

          {/* Row Empty */}
          <View style={{ width: 519, height: 15 }}></View>

          {/* Row Grand Total */}
          <div style={styles.tableColGrandTotal}>
            <View style={styles.tableColGrandTotalEmpty}>
              <Text></Text>
            </View>
            <View style={styles.tableColGrandTotalText}>
              <Text style={styles.tableCellHeadRight}>Grand Total (GHS)</Text>
            </View>
            <View style={styles.tableColGrandTotaLAmount}>
              <Text style={styles.tableCellHeadRight}>
                {data?.details?.totalAmount
                  ? CurrencyFormatter(data?.details?.totalAmount)
                  : 0}
              </Text>
            </View>
          </div>
        </Page>
      </Document>
    </PDFViewer>
  );
}
