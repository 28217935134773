import { useState, useEffect } from "react";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import DetailsDisplay from "./components/details_display";
import DetailsTab from "./components/details_tab";
import { useSearchParams } from "react-router-dom";
import ShowLoader from "../../components/loader";
import { useProductsAPI } from "../../services/products";

export default function ProductDetails() {
  document.title = "Product Details || Smart Stock";
  const [searchParams] = useSearchParams();
  const {
    getDetails,
    updatePrice,
    updateStock,
    updateDetails,
    updateNotification,
  } = useProductsAPI();

  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setModalIsLoading] = useState(false);

  const id = searchParams.get("id");
  const sku = searchParams.get("sku");

  const getProductData = async () => {
    try {
      setIsLoading(true);

      const results = await getDetails({
        id: id,
        sku: sku,
      });
      if (results.status) {
        setProductData(results.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const updateProductPrice = async ({ newPrice }) => {
    try {
      setModalIsLoading(true);

      const results = await updatePrice({
        newPrice: newPrice,
        id: productData?.details?.id,
        sku: productData?.details?.sku,
      });
      if (results.status) {
        getProductData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  const updateProductStock = async ({ additionalQty }) => {
    try {
      setModalIsLoading(true);

      const results = await updateStock({
        quantity: additionalQty,
        id: productData?.details?.id,
        sku: productData?.details?.sku,
      });
      if (results.status) {
        getProductData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  const editProduct = async ({ name, image, categoryId, description }) => {
    try {
      setModalIsLoading(true);

      const results = await updateDetails({
        name: name,
        image: image,
        categoryId: categoryId,
        description: description,
        id: productData?.details?.id,
        sku: productData?.details?.sku,
      });
      if (results.status) {
        getProductData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  const editNotification = async ({ available, alert, alertThreshold }) => {
    try {
      setModalIsLoading(true);

      const results = await updateNotification({
        available: available,
        alert: alert,
        alertThreshold: alertThreshold,
        id: productData?.details?.id,
        sku: productData?.details?.sku,
      });
      if (results.status) {
        getProductData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  useEffect(() => {
    getProductData();
  }, []);

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch
            pageTitle={productData?.details?.name}
            hideBranchSelector={true}
          />
        </Grid.Col>
        {isLoading && <ShowLoader text="Loading Data..." mt={150} />}

        {!isLoading && (
          <>
            <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <DetailsDisplay
                product={productData?.details}
                modalLoading={isModalLoading}
                priceModalFn={updateProductPrice}
                restockModalFn={updateProductStock}
                editModalFn={editProduct}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <DetailsTab
                product={productData?.details}
                updateNotificationFn={editNotification}
                modalLoading={isModalLoading}
              />
            </Grid.Col>
          </>
        )}
      </Grid>
    </Container>
  );
}
