import { useState, useEffect } from "react";
import mailVerifyImg from "./../../assets/images/mail_verify.gif";
import bgImage from "./../../assets/images/login_bg.jpg";
import ShowLoader from "../../components/loader";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  createStyles,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Flex,
  Center,
  rem,
  // useMantineTheme,
} from "@mantine/core";
import { verifyEmailAPI } from "../../services/auth";
import { AnimatePresence, motion } from "framer-motion";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(26),
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  controls: {
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column-reverse",
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
}));

export default function VerifyEmail() {
  document.title = "Verify Email || Smart Stock"; // Page Title
  // const theme = useMantineTheme();

  // const { classes } = useStyles();
  // const ref = useRef(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const code = searchParams.get("code");
  const accid = searchParams.get("accid");

  const [isLoading, setIsLoading] = useState(false);
  const [apiResults, setAPIResults] = useState(null);

  const verify = async () => {
    try {
      const data = { code: code, accid: accid };
      setIsLoading(true);
      setAPIResults(null);
      const results = await verifyEmailAPI(data);
      console.log("results", results);
      setAPIResults(results);
    } catch (error) {
      console.log("error", error);
      // showToast({
      //   color: "red",
      //   message: "An error occured. Please try again.",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (code === null || accid === null) {
      //Return to the login screen if the code or the account id is null/ invalid
      navigate("/login", { replace: true });
    } else {
      verify();
    }
  }, []);

  const color1 = "#0f8f69";
  const color2 = "#16e3a5";

  return (
    <Container
      fluid
      // size={420}
      h="100vh"
      w="100vw"
      style={{
        backgroundImage: "url(" + bgImage + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundColor: "#12b886",
        // backgroundImage: "linear-gradient(180deg, #0f8f69 0%, #16e3a5 100%)",
        background: `linear-gradient(180deg,  ${color1} 0%,${color2} 100%)`,
      }}
    >
      <Flex
        h="100vh"
        justify="center"
        align="center"
        direction="column"
        wrap="wrap"
      >
        <div>
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ x: "100vh", opacity: 0 }}
              animate={{ x: "0vh", opacity: 1 }}
              exit={{ x: "-100vh", opacity: 0 }}
              transition={{ duration: 0.15 }}
              // variants={variants}
            >
              <Paper
                withBorder
                shadow="md"
                p={30}
                radius="md"
                mt="xl"
                maw={450}
              >
                <Title
                  align="center"
                  size="h2"
                  mb="md"
                  sx={(theme) => ({
                    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                    fontWeight: 700,
                  })}
                  color={apiResults?.status === false ? "red.7" : "teal.7"}
                >
                  {isLoading && " Verifying..."}
                  {apiResults?.status === false && "Verification Failed"}
                  {apiResults?.status && "Verification Successful"}
                </Title>

                <Center mb="md">
                  <img
                    src={mailVerifyImg}
                    alt="mail"
                    style={{ height: "150px" }}
                  />
                </Center>

                {/* <Title
                  align="center"
                  size="h4"
                  sx={(theme) => ({
                    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                    fontWeight: 700,
                  })}
                  //
                >
                  Verifying Your Email
                </Title> */}

                {isLoading && (
                  <>
                    <Text
                      color="gray.7"
                      size="sm"
                      align="center"
                      mb={1}
                      mt="md"
                    >
                      We are verifying your email. Please wait...{" "}
                    </Text>

                    <ShowLoader text="" mt={16} />
                  </>
                )}
                {apiResults?.status === false && (
                  <Text color="gray.7" size="sm" align="center" mb={1} mt="md">
                    {apiResults?.message}
                  </Text>
                )}

                {apiResults?.action === null && (
                  <Button
                    type="submit"
                    fullWidth
                    mt="xl"
                    component="a"
                    target="_self"
                    rel="noopener noreferrer"
                    href="/"
                  >
                    Back Home
                  </Button>
                )}
                {apiResults?.action === "login" && (
                  <Button
                    type="submit"
                    fullWidth
                    mt="xl"
                    component="a"
                    target="_self"
                    rel="noopener noreferrer"
                    href="login"
                  >
                    Login
                  </Button>
                )}
                {apiResults?.action === "retry" && (
                  <Button
                    type="submit"
                    fullWidth
                    mt="xl"
                    onClick={() => verify()}
                  >
                    Try again
                  </Button>
                )}
              </Paper>
            </motion.div>
          </AnimatePresence>
        </div>
      </Flex>
    </Container>
  );
}

export const variants = {
  show: {
    x: "0vh",
    opacity: 1,
  },
  hide: {
    x: "100vh",
    opacity: 0,
  },
  close: {
    x: "-100vh",
    opacity: 0,
  },
};
