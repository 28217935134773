import { useState } from "react";
import {
  Card,
  Group,
  ActionIcon,
  Autocomplete,
  createStyles,
  Divider,
  Button,
  Paper,
  Tooltip,
} from "@mantine/core";
import {
  IconSearch,
  IconPlus,
  IconPrinter,
  IconFileText,
} from "@tabler/icons-react";
import { CategoriesListTable } from "./categories_list_table";
import AddCategoryModal from "./add_category_modal";
import ShowEmptyList from "../../../components/show_empty_list";

const useStyles = createStyles((theme) => ({
  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
}));

const CategoriesTable = ({
  categories,
  isModalLoading,
  addCategoryFn,
  updateCategoryFn,
  deleteCategoryFn,
}) => {
  const { classes, theme, cx } = useStyles();
  const [openAddModal, setOpenAddModal] = useState(false);

  const toggleAddModal = (value) => {
    setOpenAddModal(value);
  };

  return (
    <>
      <AddCategoryModal
        opened={openAddModal}
        closeFunction={toggleAddModal}
        isModalLoading={isModalLoading}
        operationFn={addCategoryFn}
      />

      {/* <Card withBorder shadow={1} radius="md"> */}
      <Paper shadow="xl" radius="md" p="xl">
        <Card.Section inheritPadding py="xl">
          <Group position="apart">
            <Autocomplete
              className={classes.search}
              placeholder="Search Category"
              icon={<IconSearch size="1rem" stroke={1.5} />}
              data={
                [
                  // "React",
                  // "Angular",
                  // "Vue",
                  // "Next.js",
                  // "Riot.js",
                  // "Svelte",
                  // "Blitz.js",
                ]
              }
            />

            <Group>
              <Button
                leftIcon={<IconPlus />}
                color={theme.primaryColor}
                onClick={() => toggleAddModal(true)}
              >
                Add New
              </Button>
              {/* 
              <Divider orientation="vertical" />

              <Tooltip
                multiline
                width={220}
                withArrow
                label="Download the product list in PDF format."
              >
                <ActionIcon variant="subtle" color="orange">
                  <IconFileText size="md" stroke={1.5} />
                </ActionIcon>
              </Tooltip> */}

              {/* <Tooltip
                multiline
                width={220}
                withArrow
                label="Print the product list."
              >
                <ActionIcon variant="subtle" color="blue">
                  <IconPrinter size="md" stroke={1.5} />
                </ActionIcon>
              </Tooltip> */}
            </Group>
          </Group>
        </Card.Section>
        <CategoriesListTable
          categories={categories}
          updateCategoryFn={updateCategoryFn}
          deleteCategoryFn={deleteCategoryFn}
          isModalLoading={isModalLoading}
        />
        {categories?.length == 0 && (
          <ShowEmptyList label="No categories available!" />
        )}
      </Paper>
      {/* </Card> */}
    </>
  );
};

export default CategoriesTable;
