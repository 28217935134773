import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { Provider } from "react-redux";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
// import ComColour from "./utils/com_colour";

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);

// const myTheme = {
//   colorScheme: "dark",
//   primaryColor: "orange",
//   // defaultRadius: 0,
// };

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MantineProvider
        theme={{ primaryColor: "teal", loader: "oval" }}
        withNormalizeCSS
        withGlobalStyles
      >
        <Notifications />
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </MantineProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
