import { useState, useEffect } from "react";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import BranchesTable from "./components/branches_table";
import { useBranchesAPI } from "../../services/branches";
import ShowLoader from "../../components/loader";

export default function Branches() {
  document.title = "Branches || Smart Stock";
  const { getBran, addBran, updateBran, deleteBran } = useBranchesAPI();

  const [branchesData, setBranchesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setModalIsLoading] = useState(false);

  const getBranchesData = async () => {
    try {
      setIsLoading(true);

      const results = await getBran();
      if (results.status) {
        setBranchesData(results.branches);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const addBranch = async ({ name, phone, address }) => {
    try {
      setModalIsLoading(true);

      const results = await addBran({
        name: name,
        phone: phone,
        address: address,
      });
      if (results.status) {
        getBranchesData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  const updateBranch = async ({ id, name, phone, address }) => {
    try {
      setModalIsLoading(true);

      const results = await updateBran({
        name: name,
        phone: phone,
        address: address,
        id: id,
      });
      if (results.status) {
        getBranchesData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  const deleteBranch = async ({ id }) => {
    try {
      setModalIsLoading(true);

      const results = await deleteBran({
        id: id,
      });
      if (results.status) {
        getBranchesData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  useEffect(() => {
    getBranchesData();
  }, []);

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch pageTitle="Branches" hideBranchSelector={true} />
        </Grid.Col>
        {isLoading && <ShowLoader text="Loading Data..." mt={150} />}
        {!isLoading && (
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <BranchesTable
              branches={branchesData}
              isModalLoading={isModalLoading}
              addBranchFn={addBranch}
              updateBranchFn={updateBranch}
              deleteBranchFn={deleteBranch}
            />
          </Grid.Col>
        )}
      </Grid>
    </Container>
  );
}
