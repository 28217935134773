import {
  Card,
  Group,
  Autocomplete,
  createStyles,
  Button,
  Paper,
} from "@mantine/core";
import { IconSearch, IconPlus } from "@tabler/icons-react";
import { ProductsListTable } from "./products_list_table";
import { NavLink } from "react-router-dom";
import ShowEmptyList from "../../../components/show_empty_list";

const useStyles = createStyles((theme) => ({
  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
}));

const ProductsTable = ({
  products,
  modalLoading,
  priceModalFn,
  restockModalFn,
  editModalFn,
}) => {
  const { classes, theme, cx } = useStyles();
  return (
    // <Card withBorder shadow={1} radius="md">
    <Paper shadow="xl" radius="md" p="xl">
      <Card.Section inheritPadding py="xl">
        <Group position="apart">
          <Autocomplete
            className={classes.search}
            placeholder="Search Product"
            icon={<IconSearch size="1rem" stroke={1.5} />}
            data={
              [
                // "React",
                // "Angular",
                // "Vue",
                // "Next.js",
                // "Riot.js",
                // "Svelte",
                // "Blitz.js",
              ]
            }
          />

          <Group>
            <NavLink to="/add-new-product">
              <Button leftIcon={<IconPlus />} color={theme.primaryColor}>
                Add New
              </Button>
            </NavLink>
          </Group>
        </Group>
      </Card.Section>
      <ProductsListTable
        products={products}
        modalLoading={modalLoading}
        priceModalFn={priceModalFn}
        restockModalFn={restockModalFn}
        editModalFn={editModalFn}
      />
      {products?.length == 0 && (
        <ShowEmptyList label="No products available!" />
      )}
    </Paper>
    // </Card>
  );
};

export default ProductsTable;
