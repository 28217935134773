import { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Textarea,
  Divider,
  Group,
  Text,
  Button,
  Image,
  rem,
  Box,
  Modal,
  useMantineTheme,
  Flex,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dropzone } from "@mantine/dropzone";
import InputText from "../../../components/input_text";
import InputSelect from "../../../components/input_select";
import {
  IconDeviceFloppy,
  IconX,
  IconUpload,
  IconPhoto,
} from "@tabler/icons-react";

const AddCategoryModal = ({
  opened,
  closeFunction,
  operationFn,
  isModalLoading,
}) => {
  const theme = useMantineTheme();
  const [files, setFiles] = useState([]);

  const categoryForm = useForm({
    initialValues: {
      name: "",
      image: null,
      description: null,
    },

    validate: {
      name: (value) => value.length < 2,
    },
  });

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Box maw={100} mx="auto" key={index}>
        <Image
          radius="md"
          key={index}
          src={imageUrl}
          width={100}
          height={100}
          fit="contain"
          imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
        />
      </Box>
    );
  });

  const save = (values) => {
    operationFn(values);
  };

  const closeModal = () => {
    categoryForm.reset();
    setFiles([]);
    closeFunction(false);
  };

  useEffect(() => {
    categoryForm.setFieldValue("image", files[0]);
  }, [files]);

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={false}
      size="lg"
      title="Add New Category"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <form onSubmit={categoryForm.onSubmit((values) => save(values))}>
          <Grid>
            <Grid.Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ minHeight: rem(100) }}
            >
              <Text size="sm" fw={500}>
                Photo
              </Text>

              <Dropzone
                accept={["image/png", "image/jpeg"]}
                onDrop={setFiles}
                maxFiles={1}
                multiple={false}
              >
                {files.length <= 0 && (
                  <Group
                    position="center"
                    //   spacing="xl"
                    style={{ minHeight: rem(95), pointerEvents: "none" }}
                  >
                    <Dropzone.Accept>
                      <IconUpload
                        size="2.2rem"
                        stroke={1.5}
                        color={
                          theme.colors[theme.primaryColor][
                            theme.colorScheme === "dark" ? 4 : 6
                          ]
                        }
                      />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <IconX
                        size="2.2rem"
                        stroke={1.5}
                        color={
                          theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
                        }
                      />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      <IconPhoto size="2.2rem" stroke={1.5} />
                    </Dropzone.Idle>

                    <div>
                      <Text size="sm" color="dimmed" mt={2} align="center">
                        Drag image here or click to select. Accepts PNG and JPG
                        files
                      </Text>
                    </div>
                  </Group>
                )}

                {previews}
              </Dropzone>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputText
                label="Category Name"
                // placeholder="Xbox One Controller - Black"
                required={true}
                form={categoryForm}
                name="name"
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Textarea
                placeholder="Description here"
                label="Description"
                minRows={4}
                maxRows={4}
                {...categoryForm.getInputProps("description")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Text c="dimmed" size="sm" fw={300}>
                <Text span c="red" fw="bold" inherit size="sm">
                  NB:{" "}
                </Text>
                To change the branch for this category, close this modal and set
                the branch at the top to the desired branch location before
                proceeding.
              </Text>
              <Divider my="xs" />
            </Grid.Col>
            <Grid.Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              // mt="xl"
            >
              <Flex
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  variant="outline"
                  leftIcon={<IconX size="1rem" />}
                  color="red"
                  onClick={closeModal}
                  disabled={isModalLoading}
                  px="xl"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  leftIcon={<IconDeviceFloppy size="1rem" />}
                  color={theme.primaryColor}
                  loading={isModalLoading}
                  px="xl"
                >
                  Save
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </Modal>
  );
};

export default AddCategoryModal;
