import { useState } from "react";
import {
  Grid,
  Container,
  Textarea,
  Button,
  Modal,
  useMantineTheme,
  Flex,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import InputText from "../../../components/input_text";
import { IconDeviceFloppy, IconX } from "@tabler/icons-react";

const AddBranchModal = ({
  opened,
  closeFunction,
  operationFn,
  isModalLoading,
}) => {
  const theme = useMantineTheme();

  const branchForm = useForm({
    initialValues: {
      name: "",
      phone: null,
      address: null,
    },

    validate: {
      name: (value) => value.length < 2,
    },
  });

  const save = (values) => {
    operationFn(values);
  };

  const closeModal = () => {
    branchForm.reset();
    closeFunction(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={false}
      size="lg"
      title="Add New Branch"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <form onSubmit={branchForm.onSubmit((values) => save(values))}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputText
                label="Branch Name"
                disabled={isModalLoading}
                required={true}
                form={branchForm}
                name="name"
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputText
                label="Phone Number"
                disabled={isModalLoading}
                placeholder=""
                form={branchForm}
                name="phone"
                // maxLength={10}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Textarea
                placeholder="Address here"
                label="Address"
                minRows={3}
                maxRows={3}
                {...branchForm.getInputProps("address")}
                disabled={isModalLoading}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Flex
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  variant="outline"
                  leftIcon={<IconX size="1rem" />}
                  color="red"
                  onClick={closeModal}
                  px="xl"
                  disabled={isModalLoading}
                >
                  Cancel
                </Button>
                <Button
                  leftIcon={<IconDeviceFloppy size="1rem" />}
                  color={theme.primaryColor}
                  type="submit"
                  // size="md"
                  px="xl"
                  loading={isModalLoading}
                >
                  Save
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </Modal>
  );
};

export default AddBranchModal;
