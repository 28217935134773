import {
  Grid,
  Container,
  Text,
  Modal,
  useMantineTheme,
  Loader,
  Center,
  Stack,
} from "@mantine/core";

const LoadingModal = ({ opened }) => {
  const theme = useMantineTheme();

  return (
    <Modal
      opened={opened}
      centered={true}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size="sm"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <Grid mt="xxl" mb="xxl">
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12} mt="lg">
            <Center>
              <Loader size="xl" />
            </Center>
          </Grid.Col>

          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12} mt="sm" mb="lg">
            <Center>
              <Stack align="center" spacing="xs">
                <Text fw={400} align="center">
                  Sales In Progress.
                  <br />
                  Please Wait...
                </Text>
              </Stack>
            </Center>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};

export default LoadingModal;
