import { useState, useEffect } from "react";
// import mailVerifyImg from "./../../assets/images/mail_verify.gif";
import bgImage from "./../../assets/images/login_bg.jpg";
import ShowLoader from "../../components/loader";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  // createStyles,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Flex,
  Center,
  // rem,
  Alert,
  // useMantineTheme,
} from "@mantine/core";
import { resetPasswordNowAPI } from "../../services/auth";
import { AnimatePresence, motion } from "framer-motion";
import { IconShieldLock } from "@tabler/icons-react";

export default function ResetPassword() {
  document.title = "Reset Password || Smart Stock"; // Page Title

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get("token");
  const accid = searchParams.get("accid");

  const [isLoading, setIsLoading] = useState(true);
  const [apiResults, setAPIResults] = useState(true);

  const resetPassword = async () => {
    try {
      const data = { token: token, accid: accid };
      setIsLoading(true);
      setAPIResults(null);
      const results = await resetPasswordNowAPI(data);
      console.log("results", results);
      setAPIResults(results);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token === null || accid === null) {
      //Return to the login screen if the token or the account id is null/ invalid
      navigate("/login", { replace: true });
    } else {
      resetPassword();
    }
  }, []);

  const color1 = "#0f8f69";
  const color2 = "#16e3a5";

  return (
    <Container
      fluid
      // size={420}
      h="100vh"
      w="100vw"
      style={{
        backgroundImage: "url(" + bgImage + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundColor: "#12b886",
        // backgroundImage: "linear-gradient(180deg, #0f8f69 0%, #16e3a5 100%)",
        background: `linear-gradient(180deg,  ${color1} 0%,${color2} 100%)`,
      }}
    >
      <Flex
        h="100vh"
        justify="center"
        align="center"
        direction="column"
        wrap="wrap"
      >
        <div>
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ x: "100vh", opacity: 0 }}
              animate={{ x: "0vh", opacity: 1 }}
              exit={{ x: "-100vh", opacity: 0 }}
              transition={{ duration: 0.15 }}
              // variants={variants}
            >
              <Paper
                withBorder
                shadow="md"
                p={30}
                radius="md"
                mt="xl"
                maw={350}
              >
                <Title
                  align="center"
                  size="h2"
                  mb="md"
                  sx={(theme) => ({
                    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                    fontWeight: 700,
                  })}
                  color={apiResults?.status === false ? "red.7" : "teal.7"}
                >
                  {isLoading && " Resetting Password..."}
                  {apiResults?.status === false && "Password Reset Failed"}
                  {apiResults?.status && "Password Reset Successful"}
                </Title>

                <Center mb="md">
                  {/* <img
                    src={mailVerifyImg}
                    alt="mail"
                    style={{ height: "150px" }}
                  /> */}
                  <IconShieldLock color="teal" size={150} stroke={0.7} />
                </Center>

                {/* <Title
                  align="center"
                  size="h4"
                  sx={(theme) => ({
                    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                    fontWeight: 700,
                  })}
                  //
                >
                  Verifying Your Email
                </Title> */}

                {isLoading && (
                  <>
                    <Text
                      color="gray.7"
                      size="sm"
                      align="center"
                      mb={1}
                      mt="md"
                    >
                      We are resetting your password. Please wait...{" "}
                    </Text>

                    <ShowLoader text="" mt={16} />
                  </>
                )}
                {apiResults?.status === false && (
                  <Alert
                    // icon={<IconLockExclamation size="1rem" />}
                    // title="Password Reset Failed!"
                    color="red"
                  >
                    {apiResults?.message}
                  </Alert>
                )}
                {apiResults?.status === true && (
                  <Alert
                    // icon={<IconLockCheck size="1rem" />}
                    // title="Password Reset Successful!"
                    color="teal"
                  >
                    {apiResults?.message}
                  </Alert>
                )}

                {!isLoading && (
                  <Button
                    type="submit"
                    fullWidth
                    mt="xl"
                    component="a"
                    target="_self"
                    rel="noopener noreferrer"
                    href="login"
                  >
                    Back To Login
                  </Button>
                )}
              </Paper>
            </motion.div>
          </AnimatePresence>
        </div>
      </Flex>
    </Container>
  );
}

export const variants = {
  show: {
    x: "0vh",
    opacity: 1,
  },
  hide: {
    x: "100vh",
    opacity: 0,
  },
  close: {
    x: "-100vh",
    opacity: 0,
  },
};
