import {
  Modal,
  useMantineTheme,
  Text,
  Title,
  Center,
  ScrollArea,
} from "@mantine/core";

const PrivacyPolicyModal = ({ opened, closeFunction }) => {
  const theme = useMantineTheme();

  const closeModal = () => {
    closeFunction(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={true}
      closeOnEscape={true}
      scrollAreaComponent={ScrollArea.Autosize}
      size="lg"
      // title="Privacy Policy"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <div>
        <Center>
          <Title order={3}>Privacy Policy</Title>
        </Center>
        <br />
        <Text c="dark">
          Effective Date: 01/01/2024
          <br />
          <br />
          GANO Tech ("we," "us," or "our") is committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information when you use our Smart Stock service.
          By using Smart Stock, you agree to the terms of this Privacy Policy.
          <br />
          <br />
          <b>1. Information We Collect</b>
          <br /> We collect several types of information from and about users of
          our Smart Stock service, including:
          <br />
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Personal Information:
          Information that identifies you personally, such as your name, email
          address, phone number, and billing information.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Business Information:
          Information about your business, including business name, address, and
          inventory data.
          <br />
          <br />
          <b>2. How We Collect Information</b>
          <br /> We collect information in the following ways:
          <br />
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Directly from You: When you
          register for an account, subscribe to our service, or contact us for
          support.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Automatically: Through cookies
          and similar tracking technologies as you navigate our service.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> From Third Parties:
          Information from third parties, such as payment processors, to
          complete transactions.
          <br />
          <br />
          <b>3. Use of Your Information</b>
          <br /> We use the information we collect for various purposes,
          including:
          <br />
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> To Provide and Improve Our
          Service: To set up and maintain your account, process transactions,
          and improve the functionality and user experience of Smart Stock.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> To Communicate with You: To
          send you service updates, promotional materials, and other information
          related to your use of Smart Stock.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> To Ensure Security: To protect
          against unauthorized access, detect and prevent fraud, and enforce our
          terms and conditions.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> To Comply with Legal
          Obligations: To comply with applicable laws, regulations, and legal
          processes.
          <br />
          <br />
          <b>4. Sharing Your Information</b>
          <br /> We may share your information in the following circumstances:
          <br />
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> With Service Providers: With
          third-party service providers who perform services on our behalf, such
          as payment processing and data analysis.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> For Legal Reasons: When
          required by law or to respond to legal process, or to protect the
          rights, property, and safety of GANO Tech, our users, or others.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Business Transfers: In
          connection with a merger, acquisition, or sale of all or a portion of
          our assets.
          <br />
          <br />
          <b>5. Data Security</b>
          <br />
          We implement reasonable security measures to protect your information
          from unauthorized access, use, or disclosure. However, no
          internet-based service can be 100% secure, and we cannot guarantee
          absolute security.
          <br />
          <br />
          <b>6. Your Data Rights</b>
          <br /> Depending on your account status, you may have the following
          rights regarding your personal information:
          <br />
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Access: To request access to
          your personal information.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Correction: To request
          correction of inaccurate or incomplete personal information.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Deletion: To request the
          deletion of your personal information.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Restriction: To request the
          restriction of processing of your personal information.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Portability: To request the
          transfer of your personal information to another party.
          <br />
          <br />
          To exercise these rights, please contact us at
          info@smartstock.ganotechgh.com.
          <br />
          <br />
          <b>7. Cookies and Tracking Technologies</b>
          <br />
          We use cookies and similar tracking technologies to enhance your
          experience with Smart Stock. You can control the use of cookies
          through your browser settings. However, disabling cookies may affect
          the functionality of our service.
          <br />
          <br />
          <b>8. Children’s Privacy</b>
          <br /> Smart Stock is not intended for children under the age of 13.
          We do not knowingly collect personal information from children under
          13. If we become aware that we have inadvertently received personal
          information from a child under 13, we will delete such information
          from our records.
          <br />
          <br />
          <b>9. Changes to This Privacy Policy</b>
          <br />
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on our website
          and updating the effective date. Your continued use of Smart Stock
          after any changes indicates your acceptance of the new Privacy Policy.
          <br />
          <br />
          <b>10. Contact Us</b>
          <br />
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
          <br />
          <br />
          Email: info@smartstock.ganotechgh.com
          <br />
          Phone: (233) 0504 919 472
          <br /> Address: No. 3 El-Senoussi Street, Ringway Estate, Accra
          <br />
          <br />
          Thank you for choosing <b>Smart Stock</b> by{" "}
          <a
            href="https://ganotechgh.com"
            rel="nofollow"
            style={{ color: "#12b886" }}
          >
            GANO Tech
          </a>
          . We look forward to helping you manage your inventory effectively and
          efficiently.
        </Text>
      </div>
    </Modal>
  );
};

export default PrivacyPolicyModal;
