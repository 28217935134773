export default function InvoiceFormatter(number, targeted_length = 7) {
  let strNumber = number.toString();
  let result = "";
  // padding zeros
  if (strNumber.length < targeted_length) {
    let padding = new Array(targeted_length - strNumber.length + 1).join("0");
    result = padding + strNumber;
  } else {
    result = strNumber;
  }

  return result;
}
