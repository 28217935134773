import { useState } from "react";
import {
  createStyles,
  rem,
  Avatar,
  Table,
  Group,
  Text,
  ActionIcon,
  ScrollArea,
  Badge,
  Switch,
} from "@mantine/core";
import {
  IconCheck,
  IconEye,
  IconPencil,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import EditBranchModal from "./edit_branch_modal";
// import DeleteBranchModal from "./delete_branch_modal";
import getAvatarName from "../../../utils/get_avatar_name";
import TimeAgo from "react-timeago";
import MyPagination from "../../../components/pagination";
import { NavLink } from "react-router-dom";

const USER_IMAGE_URL = process.env.REACT_APP_USER_IMAGE_URL;

const useStyles = createStyles((theme) => ({
  category: {
    padding: theme.spacing.xs,
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopLeftRadius: theme.spacing.xs,
    borderBottomLeftRadius: theme.spacing.xs,
  },

  action: {
    padding: theme.spacing.xs,
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopRightRadius: theme.spacing.xs,
    borderBottomRightRadius: theme.spacing.xs,
    textAlign: "right",
  },

  allTh: {
    padding: theme.spacing.xs,
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
  },

  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));

export function UsersListTable({
  users,
  updateBranchFn,
  deleteBranchFn,
  isModalLoading,
}) {
  const { classes, theme } = useStyles();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [branchData, setBranchData] = useState({});
  const [currentUsers, setCurrentUsers] = useState([]);

  const textColor =
    theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.dark[3];
  const fontWeightNormal = 500;
  const fontWeightBold = 700;

  const getCurrentUsers = (items) => {
    setCurrentUsers(items);
  };

  const rows = currentUsers.map((item, index) => (
    <tr key={index}>
      <td>
        <Group spacing="sm">
          <Avatar
            size={46}
            src={item?.avatar ? `${USER_IMAGE_URL}/${item?.avatar}` : null}
            radius="xl"
            alt={index}
            color={theme.primaryColor}
          >
            {getAvatarName(item?.firstName + " " + item?.surname)}
          </Avatar>
          <div>
            <Text fz="sm" fw={fontWeightBold} color={textColor}>
              {item?.firstName + " " + item?.surname}
            </Text>
            <Text fz="xs" fw={300} color={theme.primaryColor}>
              {item?.email}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Text fz="sm" fw={fontWeightNormal} color={textColor}>
          {item?.type
            ? item?.type.charAt(0).toUpperCase() + item?.type.slice(1)
            : ""}
        </Text>
      </td>
      <td>
        <Text fz="sm" fw={fontWeightNormal} color={textColor}>
          {/* {item?.lastLogIn} */}
          {item?.lastLogIn ? (
            <TimeAgo
              date={item?.lastLogIn ? item?.lastLogIn : ""}
              live={false}
            />
          ) : (
            "Not logged in"
          )}
        </Text>
      </td>
      <td>
        {/* <Switch
          mt={8}
          labelPosition="left"
          // defaultChecked={true}
          checked={item?.status === 1 ? true : false}
          // onChange={(event) => setStockAlert(event.currentTarget.checked)}
          onChange={(event) => console.log("All is well")}
          color="teal"
          size="md"
          thumbIcon={
            item?.status === 1 ? (
              <IconCheck
                size="0.8rem"
                color={theme.colors.teal[theme.fn.primaryShade()]}
                stroke={3}
              />
            ) : (
              <IconX
                size="0.8rem"
                color={theme.colors.red[theme.fn.primaryShade()]}
                stroke={3}
              />
            )
          }
        /> */}
        {item?.status === 1 ? (
          <Badge variant="outline" size="xs" color="teal">
            Active
          </Badge>
        ) : (
          <Badge variant="outline" size="xs" color="red">
            Disabled
          </Badge>
        )}
      </td>
      <td>
        <Group spacing={0} position="right">
          <NavLink
            to={`/user-details?id=${item.id}`}
            style={{ textDecoration: "none" }}
          >
            {" "}
            <ActionIcon
              color={theme.primaryColor}
              onClick={() => {
                setBranchData(item);
                toggleEditModal(
                  item?.id,
                  item?.name,
                  item?.phone,
                  item?.address,
                  true
                );
              }}
            >
              <IconEye size="1.5rem" stroke={2} />
            </ActionIcon>
          </NavLink>

          {/* <ActionIcon
            color="red"
            onClick={() => toggleDeleteModal(item?.id, item?.name, true)}
          >
            <IconTrash size="1.22rem" stroke={2} />
          </ActionIcon> */}
        </Group>
      </td>
    </tr>
  ));

  const toggleEditModal = (value) => {
    setOpenEditModal(value);
  };
  const toggleDeleteModal = (branchId, branchName, value) => {
    setBranchData({ id: branchId, name: branchName });
    setOpenDeleteModal(value);
  };

  return (
    <>
      <EditBranchModal
        opened={openEditModal}
        closeFunction={toggleEditModal}
        branch={branchData}
        operationFn={updateBranchFn}
        isModalLoading={isModalLoading}
      />
      {/* <DeleteBranchModal
        branchData={branchData}
        opened={openDeleteModal}
        closeFunction={toggleDeleteModal}
        operationFn={deleteBranchFn}
        isModalLoading={isModalLoading}
      /> */}

      <ScrollArea>
        <Table sx={{ minWidth: 400 }} verticalSpacing="sm">
          <tr>
            <th className={classes.category}>Name</th>
            <th className={classes.allTh}>Role</th>
            <th className={classes.allTh}>Last Login</th>
            <th className={classes.allTh}>Status</th>
            <th className={classes.action}></th>
          </tr>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
      <MyPagination allItems={users} currentItems={getCurrentUsers} />
    </>
  );
}
