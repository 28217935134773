import {
  Grid,
  Container,
  Text,
  Button,
  Modal,
  Divider,
  useMantineTheme,
  Flex,
} from "@mantine/core";

import { IconLock, IconLockOpen, IconX } from "@tabler/icons-react";

const ToggleUserStatusModal = ({
  branchData,
  opened,
  closeFunction,
  newStatus,
  operationFn,
  isModalLoading,
}) => {
  const theme = useMantineTheme();

  const closeModal = () => {
    closeFunction(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={false}
      size="lg"
      title={newStatus ? "Enable User Account?" : "Disable User Account?"}
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <Grid>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Text fw={400}>
              {newStatus
                ? "This will enable this account to be used to login into the system."
                : "This will prevent this account to be used to login into the system."}
            </Text>
          </Grid.Col>

          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Flex
              gap="md"
              justify="flex-end"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Button
                variant="outline"
                leftIcon={<IconX size="1rem" />}
                color="gray"
                onClick={closeModal}
                px="xl"
              >
                No
              </Button>
              <Button
                variant="outline"
                leftIcon={
                  newStatus ? (
                    <IconLockOpen size="1rem" />
                  ) : (
                    <IconLock size="1rem" />
                  )
                }
                color={newStatus ? theme.primaryColor : "red"}
                onClick={() => operationFn({ id: branchData?.id })}
                loading={isModalLoading}
                px="xl"
              >
                Yes
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};

export default ToggleUserStatusModal;
