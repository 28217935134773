import { forwardRef, useState, useEffect, useRef } from "react";
import { useDebouncedState } from "@mantine/hooks";
import {
  Group,
  Avatar,
  Text,
  Select,
  Badge,
  Loader,
  useMantineTheme,
} from "@mantine/core";
import showToast from "./../../../components/toast";
import { useSalesAPI } from "../../../services/sales";
import CurrencyFormatter from "../../../utils/currency_formatter";
import getAvatarName from "../../../utils/get_avatar_name";

const PRODUCT_IMAGE_URL = process.env.REACT_APP_PRODUCT_IMAGE_URL;

export default function ProductSearch({ addProductFunction }) {
  const { searchSales } = useSalesAPI();
  const theme = useMantineTheme();

  const [options, setOptions] = useState([""]);
  const [data, setData] = useState([]);
  const [searchString, setSearchString] = useDebouncedState("", 1000);
  const [isLoading, setIsLoading] = useState(false);
  const productSelected = useRef("");

  const onSearchStringChange = (value) => {
    setSearchString(value);
  };

  const SelectItem = forwardRef(
    (
      {
        id,
        sku,
        name,
        image,
        label,
        description,
        qty_left,
        qty,
        price,
        ...others
      },
      ref
    ) => (
      <div ref={ref} {...others}>
        <Group noWrap={true} position="apart">
          <Group noWrap={true}>
            <Avatar
              src={image ? `${PRODUCT_IMAGE_URL}/${image}` : null}
              color={theme.primaryColor}
            >
              {getAvatarName(name)}
            </Avatar>
            <div>
              <Text size="sm">{label}</Text>
              <Text size="xs" opacity={0.65} truncate={true}>
                {sku}
              </Text>
            </div>
          </Group>
          <div>
            <Text size="sm" fw={600} align="right">
              <Text size="xs" span fw={300}>
                GHS
              </Text>
              {CurrencyFormatter(price)}
            </Text>
            <Group position="right">
              {qty_left > 0 ? (
                <Badge color="teal" size="xs" variant="filled">
                  {qty_left} Left
                </Badge>
              ) : (
                <Badge color="red" size="xs" variant="filled">
                  {/* {qty_left}  */} Out Of Stock
                </Badge>
              )}
            </Group>
          </div>
        </Group>
      </div>
    )
  );

  useEffect(() => {
    if (
      searchString != "" &&
      searchString.length > 2 &&
      productSelected.current === ""
    ) {
      getData();
    } else if (searchString === "") {
      setData([]);
    }
  }, [searchString]);

  const getData = async () => {
    try {
      setIsLoading(true);

      const results = await searchSales({
        searchString: searchString,
      });
      if (results.status) {
        setOptions(results.products);
        setData(transformSearchData(results.products));
      }
    } catch (error) {
      showToast({
        color: "red",
        title: "An Error Occured",
        message: "Could not get products. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const transformSearchData = (products) => {
    let transformedData = [];

    products.map((product, index) => {
      return transformedData.push({
        value: product.id,
        label: product.name,
        id: product.id,
        sku: product.sku,
        image: product.image,
        description: product.description,
        price: product.price,
        quantity: 1,
        name: product.name,
        qty_left: product.quantity,
        ...(product.quantity < 1 ? { disabled: true } : { disabled: false }),
      });
    });
    // console.log("transformedData", transformedData);
    return transformedData;
  };

  const selectedProduct = (value) => {
    let item = data.find((item) => item.id === value);
    // console.log("item", item);
    if (item) {
      addProductFunction(item);
    }
  };

  return (
    <Select
      label="Search Product"
      placeholder="Enter part (3 characters least) of product's name, SKU or description"
      itemComponent={SelectItem}
      data={data}
      searchable
      clearable
      value={productSelected}
      onChange={selectedProduct}
      onSearchChange={onSearchStringChange}
      rightSection={isLoading && <Loader size="sm" />}
      // searchValue={(value) => setSearchString(value)}
      maxDropdownHeight={400}
      // nothingFound="No Product Found"
      // filter={(value, item) =>
      //   item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
      //   item.description.toLowerCase().includes(value.toLowerCase().trim())
      // }
    />
  );
}
