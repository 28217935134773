import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  branch: {
    all: [],
    activeId: 0,
  },
};

export const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    setActiveBranchId: (state, action) => {
      state.branch.activeId = action.payload;
      console.log("state.branch.activeId ", state.branch.activeId);
    },
    setBranches: (state, action) => {
      state.branch.all = action.payload;
      console.log("state.branch.all", state.branch.all);
    },
  },
});

export const { setActiveBranchId, setBranches } = branchSlice.actions;
export default branchSlice.reducer;
