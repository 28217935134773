import { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Container,
  Textarea,
  Divider,
  Switch,
  Group,
  useMantineTheme,
  Text,
  NumberInput,
  Button,
  Image,
  rem,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dropzone } from "@mantine/dropzone";
import InputText from "../../../components/input_text";
import InputSelect from "../../../components/input_select";
import {
  IconCheck,
  IconDeviceFloppy,
  IconX,
  IconUpload,
  IconPhoto,
} from "@tabler/icons-react";

const AddProductForm = ({
  saveProduct,
  isLoading,
  isLoadingCategory,
  setBranch,
  categories,
  branches,
}) => {
  const theme = useMantineTheme();
  const [files, setFiles] = useState([]);

  const productForm = useForm({
    initialValues: {
      name: "",
      image: null,
      sku: "",
      branchId: "",
      description: "",
      categoryId: null,
      price: 0,
      quantity: 0,
      available: true,
      alert: false,
      alertThreshold: 0,
    },

    validate: {
      name: (value) => value.length < 2,
      sku: (value) => value.length < 2,
      branchId: (value) => value === "",
      price: (value) => value === "" || value < 0.01,
      quantity: (value) => value === "" || value < 1,
      alertThreshold: (value, values) =>
        values.alert === true &&
        (value === "" || value === null || value > values.quantity),
    },
  });

  const save = (values) => {
    saveProduct(values);
  };

  const selectBranch = (value) => {
    productForm.setFieldValue("categoryId", null);
    setBranch(value);
  };

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Box maw={100} mx="auto" key={index}>
        <Image
          radius="md"
          key={index}
          src={imageUrl}
          width={100}
          height={100}
          fit="contain"
          imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
        />
      </Box>
    );
  });

  useEffect(() => {
    productForm.setFieldValue("image", files[0]);
  }, [files]);

  return (
    <Card withBorder shadow={1} radius="md">
      <Container fluid={true}>
        <form onSubmit={productForm.onSubmit((values) => save(values))}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider my="xs" label="Product Details" labelPosition="center" />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputText
                label="Product Name"
                placeholder=""
                required={true}
                form={productForm}
                name="name"
                disabled={isLoading}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputText
                disabled={isLoading}
                label="SKU"
                placeholder="XOC1672"
                required={true}
                useToolTip={true}
                toolTipLabel="This uniquely identifies the product in the inventory."
                form={productForm}
                name="sku"
              />
            </Grid.Col>{" "}
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputSelect
                disabled={isLoading}
                label="Branch"
                placeholder="Select Branch"
                required={true}
                form={productForm}
                data={branches}
                name="branchId"
                changeFunction={selectBranch}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Textarea
                disabled={isLoading}
                placeholder="Enter the description of the product"
                label="Description"
                minRows={5}
                maxRows={5}
                maxLength={230}
                {...productForm.getInputProps("description")}
              />
            </Grid.Col>
            <Grid.Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{ minHeight: rem(100) }}
            >
              <Text size="sm" fw={500}>
                Photo
              </Text>

              <Dropzone
                accept={["image/png", "image/jpeg"]}
                // onDrop={setFiles}
                onDrop={setFiles}
                maxFiles={1}
                multiple={false}
              >
                {files.length <= 0 && (
                  <Group
                    position="center"
                    //   spacing="xl"
                    style={{ minHeight: rem(95), pointerEvents: "none" }}
                  >
                    <Dropzone.Accept>
                      <IconUpload
                        size="2.2rem"
                        stroke={1.5}
                        color={
                          theme.colors[theme.primaryColor][
                            theme.colorScheme === "dark" ? 4 : 6
                          ]
                        }
                      />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <IconX
                        size="2.2rem"
                        stroke={1.5}
                        color={
                          theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
                        }
                      />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      <IconPhoto size="2.2rem" stroke={1.5} />
                    </Dropzone.Idle>

                    <div>
                      <Text size="sm" color="dimmed" mt={2} align="center">
                        Drag image here or click to select. Accepts PNG and JPG
                        files
                      </Text>
                    </div>
                  </Group>
                )}

                {previews}
              </Dropzone>

              {/* <SimpleGrid
              cols={4}
              breakpoints={[{ maxWidth: "sm", cols: 1 }]}
              mt={previews.length > 0 ? "xl" : 0}
            > */}
              {/* {previews} */}
              {/* </SimpleGrid> */}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputSelect
                // leftIcon={
                //   <ActionIcon
                //     variant="light"
                //     color="teal"
                //     onClick={() => console.log("object :>> ")}
                //   >
                //     <IconAdjustments size="1.125rem" />
                //   </ActionIcon>
                // }
                disabled={isLoading}
                data={categories}
                label="Category"
                placeholder="Select Category"
                form={productForm}
                name="categoryId"
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <NumberInput
                hideControls
                disabled={isLoading}
                withAsterisk={true}
                // type="number"
                precision={2}
                removeTrailingZeros
                min={0.01}
                label="Price (GHS)"
                placeholder="100"
                styles={(theme) => ({
                  input: {
                    "&:focus-within": {
                      borderColor: theme.colors[theme.primaryColor][5],
                    },
                  },
                })}
                {...productForm.getInputProps("price")}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <NumberInput
                hideControls
                disabled={isLoading}
                withAsterisk={true}
                type="number"
                min={1}
                label="Quantity"
                placeholder="1"
                styles={(theme) => ({
                  input: {
                    "&:focus-within": {
                      borderColor: theme.colors[theme.primaryColor][5],
                    },
                  },
                })}
                {...productForm.getInputProps("quantity")}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider my="xs" label="Inventory Info" labelPosition="center" />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Text size="sm" fw={500}>
                Make Available For Purchase?
              </Text>
              <Switch
                mt={8}
                defaultChecked
                labelPosition="left"
                color="teal"
                size="md"
                {...productForm.getInputProps("available", {
                  type: "radio",
                })}
                thumbIcon={
                  productForm.values.available ? (
                    <IconCheck
                      size="0.8rem"
                      color={theme.colors.teal[theme.fn.primaryShade()]}
                      stroke={3}
                    />
                  ) : (
                    <IconX
                      size="0.8rem"
                      color={theme.colors.red[theme.fn.primaryShade()]}
                      stroke={3}
                    />
                  )
                }
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Text size="sm" fw={500}>
                Alert On Low Stock?
              </Text>
              <Switch
                mt={8}
                labelPosition="left"
                // checked={stockAlert}
                // onChange={(event) => setStockAlert(event.currentTarget.checked)}
                color="teal"
                size="md"
                {...productForm.getInputProps("alert", {
                  type: "radio",
                })}
                thumbIcon={
                  productForm.values.alert ? (
                    <IconCheck
                      size="0.8rem"
                      color={theme.colors.teal[theme.fn.primaryShade()]}
                      stroke={3}
                    />
                  ) : (
                    <IconX
                      size="0.8rem"
                      color={theme.colors.red[theme.fn.primaryShade()]}
                      stroke={3}
                    />
                  )
                }
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <NumberInput
                disabled={!productForm.values.alert}
                withAsterisk={productForm.values.alert}
                type="number"
                min={0}
                max={productForm.values.quantity}
                label="Quantity To Alert"
                placeholder="0"
                styles={(theme) => ({
                  input: {
                    "&:focus-within": {
                      borderColor: theme.colors[theme.primaryColor][5],
                    },
                  },
                })}
                {...productForm.getInputProps("alertThreshold")}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider my="xs" />
            </Grid.Col>
            <Grid.Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              offsetMd={8}
              offsetLg={8}
              offsetXl={8}
              // mt="xl"
            >
              <Group position="right">
                <Button
                  type="submit"
                  leftIcon={<IconDeviceFloppy size="1rem" />}
                  // size="md"
                  loading={isLoading}
                  px="xl"
                >
                  Save
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </Card>
  );
};

export default AddProductForm;
