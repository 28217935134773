import { useState, useEffect } from "react";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import DetailsDisplay from "./components/details_display";
import DetailsTab from "./components/details_tab";
import { useSearchParams } from "react-router-dom";
import ShowLoader from "../../components/loader";
import { useUsersAPI } from "../../services/users";

export default function UserDetails() {
  document.title = "User Details || Smart Stock";
  const [searchParams] = useSearchParams();
  const { getUserDetails } = useUsersAPI();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setModalIsLoading] = useState(false);

  const id = searchParams.get("id");

  const getUserData = async () => {
    try {
      setIsLoading(true);

      const results = await getUserDetails({
        id: id,
      });
      if (results.status) {
        setUserData(results.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  //   const editProduct = async ({ name, image, categoryId, description }) => {
  //     try {
  //       setModalIsLoading(true);

  //       const results = await updateDetails({
  //         name: name,
  //         image: image,
  //         categoryId: categoryId,
  //         description: description,
  //         id: productData?.details?.id,
  //       });
  //       if (results.status) {
  //         getUserData();
  //       }
  //     } catch (error) {
  //     } finally {
  //       setModalIsLoading(false);
  //     }
  //   };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch pageTitle="User Details" hideBranchSelector={true} />
        </Grid.Col>
        {isLoading && <ShowLoader text="Loading Profile..." mt={150} />}

        {!isLoading && (
          <>
            <Grid.Col xs={12} sm={12} md={5} lg={4} xl={4}>
              <DetailsDisplay
                user={userData?.details}
                modalLoading={isModalLoading}
                // editModalFn={editProduct}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={7} lg={8} xl={8}>
              <DetailsTab user={userData?.details} />
            </Grid.Col>
          </>
        )}
      </Grid>
    </Container>
  );
}
