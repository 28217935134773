import { Select, Tooltip, Center, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

const InputSelect = ({
  label,
  placeholder,
  useToolTip,
  toolTipLabel,
  data,
  required,
  leftIcon,
  disabled,
  shouldCreate,
  createLabel,
  form,
  name,
  changeFunction,
}) => {
  const toolTip = (
    <Tooltip
      label={toolTipLabel}
      position="top-end"
      withArrow
      transitionProps={{ transition: "pop-bottom-right" }}
    >
      <Text color="dimmed" sx={{ cursor: "help" }}>
        <Center>
          <IconInfoCircle size="1.1rem" stroke={1.5} />
        </Center>
      </Text>
    </Tooltip>
  );
  return (
    <Select
      icon={leftIcon ? leftIcon : null}
      rightSection={useToolTip ? toolTip : null}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      shouldCreate={shouldCreate}
      getCreateLabel={createLabel}
      data={data || []}
      withAsterisk={required}
      styles={(theme) => ({
        input: {
          "&:focus-within": {
            borderColor: theme.colors[theme.primaryColor][5],
          },
        },
      })}
      {...form.getInputProps(name)}
      onChange={(val) => {
        if (changeFunction) {
          changeFunction(val);
        }
        form.getInputProps(name).onChange(val);
      }}
    />
  );
};

export default InputSelect;
