import axios from "axios";
import showToast from "../components/toast";

const API_URL = process.env.REACT_APP_API_URL;
const LOGIN_URL = `${API_URL}/login/`;

const signInAPI = async (userData) => {
  return await axios
    .post(LOGIN_URL, userData, {})
    .then((res) => {
      //   console.log("signInAPI", res.data);
      if (!res.data.status) {
        showToast({
          //Only show this if there's an error
          color: "red",
          title: "Error",
          message: res.data.message,
        });
      }
      return res.data;
    })
    .catch((err) => {
      console.log("signInAPI", err.message);
      showToast({
        color: "red",
        title: "Unknown Error",
        message: "An error occured. Please try again.",
      });
      return { status: false, message: "An error occured. Please try again." };
    });
};

const createAccountAPI = async (data) => {
  console.log("register API => ", data);

  return await axios
    .post(`${API_URL}/register/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (!res.data.status) {
        showToast({
          //Only show this if there's an error
          color: "red",
          title: "An error occured",
          message: res.data.message,
        });
      }
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      showToast({
        color: "red",
        title: "An error occured",
        message: "Could not create account. Please try again.",
      });
      return {
        status: false,
        message: "Could not create account. Please try again.",
      };
    });
};

const verifyEmailAPI = async ({ code, accid }) => {
  // console.log("verifyEmailAPI => ", data);
  // console.log("verifyEmailAPI Code => ", code);
  // console.log("verifyEmailAPI AccID => ", accid);

  return await axios
    // .post(`${API_URL}/verify`, data, {})
    .get(`${API_URL}/verify?code=${code}&accid=${accid}`, {})
    .then((res) => {
      // if (!res.data.status) {
      //   showToast({
      //     //Only show this if there's an error
      //     color: "red",
      //     title: "An error occured",
      //     message: res.data.message,
      //   });
      // }
      return res.data;
    })
    .catch((err) => {
      showToast({
        color: "red",
        title: "An error occured",
        message: "Could not verify email. Please try again.",
      });
      return {
        status: false,
        message: "Could not verify email. Please try again.",
        action: "retry",
      };
    });
};

const resetPasswordAPI = async (data) => {
  return await axios
    .post(`${API_URL}/reset-password/`, data, {})
    .then((res) => {
      if (!res.data.status) {
        showToast({
          //Only show this if there's an error
          color: "red",
          title: "An error occured",
          message: res.data.message,
        });
      }
      return res.data;
    })
    .catch((err) => {
      showToast({
        color: "red",
        title: "An error occured",
        message: "Could not reset your password. Please try again.",
      });
      return {
        status: false,
        message: "Could not reset your password. Please try again.",
      };
    });
};

const resetPasswordNowAPI = async ({ token, accid }) => {
  return await axios
    .get(`${API_URL}/reset-password?token=${token}&accid=${accid}`, {})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showToast({
        color: "red",
        title: "An error occured",
        message: "Could not reset password. Please try again.",
      });
      return {
        status: false,
        message: "Could not reset password. Please try again.",
      };
    });
};

export {
  signInAPI,
  createAccountAPI,
  verifyEmailAPI,
  resetPasswordAPI,
  resetPasswordNowAPI,
};
