import {
  Card,
  Table,
  // Image,
  // Badge,
  Group,
  // Menu,
  // Button,
  Text,
  Flex,
  Avatar,
  useMantineTheme,
  Stack,
} from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import CurrencyFormatter from "../../../utils/currency_formatter";
import InvoiceFormatter from "../../../utils/invoice_formatter";
import dayjs from "dayjs";
import getAvatarName from "../../../utils/get_avatar_name";

const PRODUCT_IMAGE_URL = process.env.REACT_APP_PRODUCT_IMAGE_URL;
const COMPANY_IMAGE_URL = process.env.REACT_APP_COMPANY_IMAGE_URL;

const DetailsDisplay = ({ sale }) => {
  const theme = useMantineTheme();
  const textColor =
    theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.dark[3];
  const fontWeightNormal = 500;
  const fontWeightBold = 700;
  // console.log("product", product);

  const rows = sale?.items.map((item, index) => (
    <tr key={index}>
      <td>
        <Text fz="sm" fw={fontWeightNormal} color={textColor}>
          {index + 1}
        </Text>
      </td>
      <td>
        <Group spacing="xs">
          <Avatar
            color={theme.primaryColor}
            size="2.1rem"
            src={item?.image ? `${PRODUCT_IMAGE_URL}/${item.image}` : null}
            alt="image"
          >
            <IconPhoto color={theme.primaryColor} />
          </Avatar>
          <div>
            <Text fz="sm" fw={fontWeightNormal} color={textColor}>
              {item?.name}
            </Text>
            <Text c="dimmed" fz="xs">
              {item?.productSKU}
            </Text>
          </div>
        </Group>
      </td>
      <td className="text-end">
        <Text fz="sm" fw={fontWeightNormal} color={textColor}>
          {CurrencyFormatter(item?.price)}
        </Text>
      </td>
      <td className="text-center">
        <Text fz="sm" fw={fontWeightNormal} color={textColor}>
          {item.quantity}
        </Text>
      </td>
      <td className="text-end">
        <Text fz="sm" fw={fontWeightNormal} color={textColor}>
          {CurrencyFormatter(item.subtotal)}
        </Text>
      </td>
    </tr>
  ));

  return (
    <>
      <Card withBorder shadow={1} radius="sm" className="w-100">
        <Card.Section inheritPadding py="xl">
          <Flex
            gap="xs"
            justify="space-between"
            align="flex-start"
            direction="row"
            wrap="wrap"
            mb="xl"
          >
            <Avatar
              size="xl"
              src={
                sale?.company?.logo
                  ? `${COMPANY_IMAGE_URL}/${sale?.company?.logo}`
                  : null
              }
              radius="sm"
              color={theme.primaryColor}
            >
              {getAvatarName(sale?.company?.name ?? "")}
            </Avatar>
            <Stack align="flex-end" justify="flex-start" spacing={5}>
              <Text fz="md" fw={fontWeightBold} color={textColor}>
                {sale?.company?.name}
              </Text>
              <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                {sale?.company?.branchAddress ?? sale?.company?.mainAddress}
              </Text>
              <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                {sale?.company?.branchPhone ?? sale?.company?.mainPhone}
              </Text>
            </Stack>
          </Flex>
          <Flex
            gap="xs"
            justify="space-between"
            align="flex-start"
            direction="row"
            wrap="wrap"
            pt="xl"
            pb="xl"
          >
            <Stack align="flex-start" justify="flex-start" spacing={5}>
              <Text fz="md" fw={300} color={theme.primaryColor}>
                BILL TO
              </Text>
              <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                {sale?.details?.name ?? "No Name"}
              </Text>
              <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                {sale?.details?.phone ?? ""}
              </Text>
            </Stack>
            <Stack align="flex-end" justify="flex-start" spacing={5}>
              <Text fz="md" fw={fontWeightBold} color={theme.primaryColor}>
                INVOICE
              </Text>
              <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                Invoice #: {InvoiceFormatter(sale?.details?.invoiceNo)}
              </Text>
              <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                Date:{" "}
                {dayjs(new Date(sale?.details?.dateCreated)).format(
                  "DD MMM YYYY HH:mm:ss"
                )}
              </Text>
            </Stack>
          </Flex>

          <Table mt="xl">
            <thead>
              <tr>
                <th
                  className=" text-white"
                  style={{
                    backgroundColor: theme.colors[theme.primaryColor][7],
                  }}
                >
                  No.
                </th>
                <th
                  className=" text-white"
                  style={{
                    backgroundColor: theme.colors[theme.primaryColor][7],
                  }}
                >
                  Item
                </th>
                <th
                  className="text-end  text-white"
                  style={{
                    backgroundColor: theme.colors[theme.primaryColor][7],
                  }}
                >
                  Price (GHS)
                </th>
                <th
                  className="text-center text-white"
                  style={{
                    backgroundColor: theme.colors[theme.primaryColor][7],
                  }}
                >
                  Qty
                </th>
                <th
                  className="text-end text-white"
                  style={{
                    backgroundColor: theme.colors[theme.primaryColor][7],
                  }}
                >
                  Subtotal (GHS)
                </th>
              </tr>
            </thead>
            <tbody>
              {rows}
              <tr>
                <td style={{ paddingTop: "2rem" }}></td>
                <td style={{ paddingTop: "2rem" }}></td>
                <td style={{ paddingTop: "2rem" }}></td>
                <td style={{ paddingTop: "2rem" }}>
                  <Text
                    fz="sm"
                    align="right"
                    fw={fontWeightNormal}
                    color={textColor}
                  >
                    TOTAL (GHS):
                  </Text>
                </td>
                <td style={{ paddingTop: "2rem" }} className="text-end">
                  <Text fz="sm" fw={fontWeightBold} color={textColor}>
                    {CurrencyFormatter(sale?.details?.totalAmount)}
                  </Text>
                </td>
              </tr>
            </tbody>
          </Table>

          {/* <Group position="apart">
            <Image
              fit="contain"
              radius="md"
              height={150}
              width={150}
              src={
                product?.image ? `${PRODUCT_IMAGE_URL}/${product?.image}` : null
              }
              alt={product?.name}
              withPlaceholder
            />

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button variant="outline">Action</Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconPencil size={14} />}
                  onClick={() => toggleEditModal(true)}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  icon={<IconFoldUp size={14} />}
                  onClick={() => toggleRestockModal(true)}
                >
                  Restock
                </Menu.Item>
                <Menu.Item
                  icon={<IconCurrencyCent size={14} />}
                  onClick={() => toggleChangePriceModal(true)}
                >
                  Change Price
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group> */}
        </Card.Section>

        <Table verticalSpacing="sm" striped withBorder withColumnBorders>
          {/* <tbody>
            <tr>
              <td>Name</td>
              <td>{product?.name}</td>
            </tr>
            <tr>
              <td>SKU</td>
              <td>{product?.sku}</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>{product?.description}</td>
            </tr>
            <tr>
              <td>Category</td>
              <td>{product?.categoryName}</td>
            </tr>
            <tr>
              <td>Price</td>
              <td>
                <Text span fw="light" size="xs">
                  GHS{" "}
                </Text>
                {CurrencyFormatter(product?.price)}
              </td>
            </tr>
            <tr>
              <td>Quantity</td>
              <td>{product?.quantity}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                {product?.available === 1 ? (
                  <Badge color="teal">Available</Badge>
                ) : (
                  <Badge color="red">Unavailable</Badge>
                )}
              </td>
            </tr>
          </tbody> */}
        </Table>
      </Card>
    </>
  );
};

export default DetailsDisplay;
