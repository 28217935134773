import "./../index/ud-styles.css";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import smartStockLogoOnly from "./../../assets/images/logo_only.png";
import mailSentImg from "./../../assets/images/mail_sent.png";
import bgImage from "./../../assets/images/login_bg.jpg";
import { useNavigate } from "react-router-dom";
import { setAuth } from "../../redux_slices/auth";
import { useForm } from "@mantine/form";
import {
  createStyles,
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Checkbox,
  Grid,
  Text,
  Container,
  Group,
  Button,
  Flex,
  Center,
  Divider,
  Box,
  Image,
  rem,
  useMantineTheme,
  ColorSwatch,
  CheckIcon,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { signInAPI, createAccountAPI } from "../../services/auth";
import showToast from "../../components/toast";
import InputText from "../../components/input_text";
import { AnimatePresence, motion } from "framer-motion";
import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconX,
  IconUpload,
  IconPhoto,
  IconArrowLeft,
  IconBuilding,
  IconUser,
} from "@tabler/icons-react";
import ForgotPasswordTab from "./components/forgot_password_tab";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(26),
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  controls: {
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column-reverse",
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
}));

export function Login() {
  // document.title = `${activeCardTitle} || Smart Stock`; // Page Title
  const [pageTitle, setPageTitle] = useState("Login");
  const theme = useMantineTheme();

  const { classes } = useStyles();
  const ref = useRef(null);

  // const userAuth = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeCard, setActiveCard] = useState("login");
  const [isLoading, setIsLoading] = useState(false);
  const [registrationData, setRegistrationData] = useState(null);
  const [active, setActive] = useState(0);
  const [files, setFiles] = useState([null, null]);

  const signInForm = useForm({
    initialValues: { email: "", password: "" },

    // functions will be used to validate values at corresponding key
    validate: {
      password: (value) => value.length < 4,
      email: (value) => !/^\S+@\S+$/.test(value),
    },
  });

  const resetForm = useForm({
    initialValues: { email: "" },

    validate: {
      email: (value) => !/^\S+@\S+$/.test(value),
    },
  });

  const registerForm = useForm({
    initialValues: {
      name: "",
      phone: "",
      comEmail: "",
      address: "",
      image: null,
      colour: "teal",
      firstName: "",
      surname: "",
      userEmail: "",
      userPhone: "",
      password: "",
      confirmPassword: "",
      tnc: false,
      //  alert: false,
      //  alertThreshold: 0,
    },

    validate: {
      name: (value) => value.length < 2,
      phone: (value) => value.length !== 10,
      comEmail: (value) => value.length < 2,
      address: (value) => value.length < 2,
      colour: (value) => value === "",
      firstName: (value) => value.length < 2,
      surname: (value) => value.length < 2,
      userEmail: (value) => value === "" || value < 0.01,
      userPhone: (value) => value.length !== 10,
      password: (value) => value === "" || value.length < 8,
      confirmPassword: (value, values) =>
        value === "" || value.length < 8 || value !== values.password,
      tnc: (value) => value === false,

      //    values.alert === true && (value === "" || value === null),
    },

    transformValues: (values) => ({
      // Transforming the values to fields required by the register endpoint
      // fullName: `${values.firstName} ${values.surname}`,
      name: values.name,
      phone: values.phone,
      comEmail: values.comEmail,
      address: values.address,
      comLogo: values.image[0] || null,
      userLogo: values.image[1] || null,
      colour: values.colour,
      firstName: values.firstName,
      surname: values.surname,
      userEmail: values.userEmail,
      userPhone: values.userPhone,
      password: values.password,
      // age: Number(values.age) || 0,
    }),
  });

  const handleRegisterInputError = (errors) => {
    if (errors.password) {
      notifications.show({
        title: "Invalid Password",
        message: "Password must be at least 8 characters",
        color: "red",
      });
    } else if (errors.confirmPassword) {
      notifications.show({
        title: "Passwords Mismatch",
        message: "The entered passwords do not match",
        color: "red",
      });
    } else if (errors.tnc) {
      notifications.show({
        title: "T&C Not Agreed",
        message: "You must agree to the terms and conditions",
        color: "red",
      });
    }
  };

  const setAuthCard = (value) => {
    setActiveCard(value);

    //Setting the appropriate pagfe title based on the card value
    if ((value = "login")) {
      setPageTitle("Login || Smart Stock");
    } else if ((value = "register")) {
      setPageTitle("Register || Smart Stock");
    } else if ((value = "forgot")) {
      setPageTitle("Forgot Password");
    }
    console.log("setPageTitle =>", pageTitle);
  };

  const signIn = async (values) => {
    try {
      setIsLoading(true);
      const results = await signInAPI(values);
      if (results.status) {
        dispatch(setAuth(results)); //Storing the user data in the redux state manager
        navigate("/dashboard");
      }
    } catch (error) {
      showToast({
        color: "red",
        message: "An error occured. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const register = async (values) => {
    try {
      setIsLoading(true);

      const results = await createAccountAPI(values);
      console.log("results", results);
      if (results.status) {
        setRegistrationData(results);
        setActiveCard("register_success");
        // dispatch(setAuth(results)); //Storing the user data in the redux state manager
        // navigate("/dashboard");
      }
    } catch (error) {
      console.log("error => ", error);
      // showToast({
      //   color: "red",
      //   message: "An error occured. Please try again.",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  const setCompanyColour = (colour) => {
    registerForm.setValues({
      colour: colour,
    });
  };

  const next = () => {
    if (
      registerForm.isValid("name") &&
      registerForm.isValid("phone") &&
      registerForm.isValid("comEmail") &&
      registerForm.isValid("address")
    ) {
      setActive(1);
      ref.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      registerForm.validateField("name");
      registerForm.validateField("phone");
      registerForm.validateField("comEmail");
      registerForm.validateField("address");
    }
  };

  const back = () => {
    setActive(0);
  };

  const previews = files.map((file, index) => {
    if (index === 0 && file !== null) {
      const imageUrl = URL.createObjectURL(file);
      return (
        <Box maw={100} mx="auto" key={index}>
          <Image
            radius="md"
            key={index}
            src={imageUrl}
            width={100}
            height={100}
            fit="contain"
            imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
          />
        </Box>
      );
    }
  });

  //   files.map((file, index) => {
  //   const imageUrl = URL.createObjectURL(file);
  //   return (
  //     <Box maw={100} mx="auto" key={index}>
  //       <Image
  //         radius="md"
  //         key={index}
  //         src={imageUrl}
  //         width={100}
  //         height={100}
  //         fit="contain"
  //         imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
  //       />
  //     </Box>
  //   );
  // });
  // const previews = files.map((file, index) => {
  //   const imageUrl = URL.createObjectURL(file);
  //   return (
  //     <Box maw={100} mx="auto" key={index}>
  //       <Image
  //         radius="md"
  //         key={index}
  //         src={imageUrl}
  //         width={100}
  //         height={100}
  //         fit="contain"
  //         imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
  //       />
  //     </Box>
  //   );
  // });

  const previewsUserImage = files.map((file, index) => {
    if (index === 1 && file !== null) {
      const imageUrl = URL.createObjectURL(file);
      return (
        <Box maw={100} mx="auto" key={index}>
          <Image
            radius="md"
            key={index}
            src={imageUrl}
            width={100}
            height={100}
            fit="contain"
            imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
          />
        </Box>
      );
    }
  });

  useEffect(() => {
    console.log("files", files);
    registerForm.setFieldValue("image", files);
  }, [files]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  // const color1 = "#0f8f69";
  // const color2 = "#16e3a5";

  return (
    <Container
      fluid
      // size={420}
      h="100vh"
      w="100vw"
      style={{
        backgroundImage: "url(" + bgImage + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundColor: "#12b886",
        // backgroundImage: "linear-gradient(180deg, #0f8f69 0%, #16e3a5 100%)",
        // background: `linear-gradient(180deg,  ${color1} 0%,${color2} 100%)`,
      }}
    >
      <Flex
        h="100vh"
        justify="center"
        align="center"
        direction="column"
        wrap="wrap"
      >
        <div>
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ x: "100vh", opacity: 0 }}
              animate={{ x: "0vh", opacity: 1 }}
              exit={{ x: "-100vh", opacity: 0 }}
              key={activeCard}
              transition={{ duration: 0.15 }}
              // variants={variants}
            >
              {activeCard === "login" && (
                <Paper withBorder shadow="xl" p={30} radius="md" maw={350}>
                  <Center mb="md">
                    <img
                      src={smartStockLogoOnly}
                      alt="smart stock"
                      style={{ height: "80px" }}
                    />
                  </Center>

                  <form
                    onSubmit={signInForm.onSubmit((values) => signIn(values))}
                  >
                    <Title
                      align="center"
                      size="h2"
                      sx={(theme) => ({
                        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                        fontWeight: 700,
                      })}
                      //
                    >
                      Welcome!
                    </Title>
                    <Text color="dimmed" size="sm" align="center" mb="md">
                      Please sign in to access your account
                    </Text>
                    <Divider mb="md" color="gray.1" />

                    <TextInput
                      label="Email"
                      placeholder="email@domain.com"
                      withAsterisk={true}
                      {...signInForm.getInputProps("email")}
                      disabled={isLoading}
                    />
                    <PasswordInput
                      label="Password"
                      placeholder="********"
                      withAsterisk={true}
                      {...signInForm.getInputProps("password")}
                      mt="md"
                      disabled={isLoading}
                    />
                    <Group position="right" mt="lg">
                      {/* <Checkbox label="Remember me" /> */}

                      <Anchor
                        size="sm"
                        // href="#"
                        onClick={() => setAuthCard("forgot")}
                      >
                        Forgot password?
                      </Anchor>
                    </Group>
                    <Button
                      type="submit"
                      fullWidth
                      mt="xl"
                      // onClick={() => signIn()}
                      loading={isLoading}
                    >
                      Sign in
                    </Button>
                    <Text color="dimmed" size="sm" align="center" mt="lg">
                      Do not have an account yet?{" "}
                      <Anchor
                        size="sm"
                        // href="#"
                        onClick={() => setAuthCard("register")}
                      >
                        Create account
                      </Anchor>
                    </Text>
                  </form>
                </Paper>
              )}

              {activeCard === "register" && (
                <Paper
                  withBorder
                  shadow="xl"
                  p={30}
                  m="auto"
                  radius="md"
                  maw={650}
                  style={{
                    maxHeight: "87vh",
                    overflowY: "scroll",
                  }}
                  className="show-scroll"
                >
                  <Title
                    ref={ref}
                    align="center"
                    size="h2"
                    sx={(theme) => ({
                      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                      fontWeight: 700,
                    })}
                    //
                  >
                    Register!
                  </Title>
                  <Text color="dimmed" size="sm" align="center" mb="md">
                    Just two steps, and you're ready to manage your stock
                  </Text>
                  <Divider mb="md" color="gray.1" />
                  <Flex
                    //   gap="md"
                    justify="center"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                    <Flex
                      justify="center"
                      align="center"
                      direction="column"
                      wrap="wrap"
                    >
                      <Box
                        sx={(theme) => ({
                          width: "2.9rem",
                          textAlign: "center",
                          height: "2.9rem",
                          padding: "0.5rem",
                          borderRadius: "1.45rem",
                          cursor: "pointer",
                          border: "0.2rem solid",
                          ...(active >= 1
                            ? { borderColor: theme.colors.teal[4] }
                            : { borderColor: theme.colors.gray[4] }),
                        })}
                      >
                        {active >= 1 ? (
                          <IconCheck size="1.5rem" color="teal" />
                        ) : (
                          <IconBuilding size="1.5rem" color="gray" />
                        )}
                      </Box>
                      <Text
                        color={active >= 1 ? "teal.9" : "dimmed"}
                        align="center"
                        size="sm"
                        fw={700}
                        style={{ lineHeight: 1 }}
                        mt="xs"
                      >
                        Organisation <br />
                        Info
                      </Text>
                    </Flex>

                    <Box
                      sx={(theme) => ({
                        width: "5rem",
                      })}
                      ml="xs"
                      mr="xs"
                    >
                      <Divider
                        // my="sm"
                        pb={40}
                        size="md"
                        color={active >= 1 ? "teal.4" : "gray.4"}
                        orientation="horizontal"
                      />
                    </Box>

                    <Flex
                      justify="center"
                      align="center"
                      direction="column"
                      wrap="wrap"
                    >
                      <Box
                        sx={(theme) => ({
                          width: "2.9rem",
                          textAlign: "center",
                          height: "2.9rem",
                          padding: "0.5rem",
                          borderRadius: "1.45rem",
                          cursor: "pointer",
                          border: "0.2rem solid",
                          ...(active > 1
                            ? { borderColor: theme.colors.teal[4] }
                            : { borderColor: theme.colors.gray[4] }),
                        })}
                      >
                        {active > 1 ? (
                          <IconCheck size="1.5rem" color="teal" />
                        ) : (
                          <IconUser size="1.5rem" color="gray" />
                        )}
                      </Box>
                      <Text
                        color={active >= 2 ? "teal.9" : "dimmed"}
                        align="center"
                        size="sm"
                        fw={700}
                        style={{ lineHeight: 1 }}
                        mt="xs"
                      >
                        Administrator <br />
                        Info
                      </Text>
                    </Flex>
                  </Flex>
                  {/* <Divider
                    my="sm"
                    size="sm"
                    variant="dashed"
                    orientation="horizontal"
                    color="gray.4"
                  /> */}
                  <form
                    onSubmit={registerForm.onSubmit(
                      (values) => register(values),
                      (errors) => handleRegisterInputError(errors)
                    )}
                  >
                    <Grid>
                      {active === 0 && (
                        <>
                          <Grid.Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            style={{ minHeight: rem(100) }}
                            mt="sm"
                          >
                            <Text size="sm" fw={500}>
                              Organisation's logo
                            </Text>

                            <Dropzone
                              accept={["image/png", "image/jpeg"]}
                              // onDrop={setFiles}
                              onDrop={(file) => {
                                const newFiles = [...files];
                                newFiles[0] = file[0];
                                setFiles(newFiles);
                              }}
                              maxFiles={1}
                              multiple={false}
                            >
                              {files[0] === null && (
                                <Group
                                  position="center"
                                  //   spacing="xl"
                                  style={{
                                    minHeight: rem(95),
                                    pointerEvents: "none",
                                  }}
                                >
                                  <Dropzone.Accept>
                                    <IconUpload
                                      size="2.2rem"
                                      stroke={1.5}
                                      color={
                                        theme.colors[theme.primaryColor][
                                          theme.colorScheme === "dark" ? 4 : 6
                                        ]
                                      }
                                    />
                                  </Dropzone.Accept>
                                  <Dropzone.Reject>
                                    <IconX
                                      size="2.2rem"
                                      stroke={1.5}
                                      color={
                                        theme.colors.red[
                                          theme.colorScheme === "dark" ? 4 : 6
                                        ]
                                      }
                                    />
                                  </Dropzone.Reject>
                                  <Dropzone.Idle>
                                    <IconPhoto size="2.2rem" stroke={1.5} />
                                  </Dropzone.Idle>

                                  <div>
                                    <Text
                                      size="sm"
                                      color="dimmed"
                                      mt={2}
                                      align="center"
                                    >
                                      Drag image here or click to select.
                                      Accepts PNG and JPG files
                                    </Text>
                                  </div>
                                </Group>
                              )}

                              {previews}
                            </Dropzone>
                          </Grid.Col>

                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputText
                              disabled={isLoading}
                              label="Organisation Name"
                              placeholder=""
                              required={true}
                              form={registerForm}
                              name="name"
                            />
                          </Grid.Col>
                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputText
                              disabled={isLoading}
                              label="Phone Number"
                              placeholder=""
                              required={true}
                              form={registerForm}
                              name="phone"
                            />
                          </Grid.Col>

                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputText
                              disabled={isLoading}
                              label="Email"
                              placeholder=""
                              required={true}
                              form={registerForm}
                              name="comEmail"
                            />
                          </Grid.Col>
                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputText
                              disabled={isLoading}
                              label="Location Address"
                              placeholder=""
                              required={true}
                              form={registerForm}
                              name="address"
                            />
                          </Grid.Col>

                          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Divider
                              my="xs"
                              label="Select Organisation's Color"
                              labelPosition="center"
                            />
                            <Group position="center" spacing="xs">
                              <ColorSwatch
                                size={
                                  registerForm.values.colour === "red" ? 32 : 25
                                }
                                color={theme.colors.red[6]}
                                onClick={() => setCompanyColour("red")}
                                sx={{ color: "#fff", cursor: "pointer" }}
                              >
                                {registerForm.values.colour === "red" && (
                                  <CheckIcon width={rem(10)} />
                                )}
                              </ColorSwatch>

                              <ColorSwatch
                                size={
                                  registerForm.values.colour === "grape"
                                    ? 32
                                    : 25
                                }
                                color={theme.colors.grape[6]}
                                onClick={() => setCompanyColour("grape")}
                                sx={{ color: "#fff", cursor: "pointer" }}
                              >
                                {registerForm.values.colour === "grape" && (
                                  <CheckIcon width={rem(10)} />
                                )}
                              </ColorSwatch>

                              <ColorSwatch
                                size={
                                  registerForm.values.colour === "violet"
                                    ? 32
                                    : 25
                                }
                                color={theme.colors.violet[6]}
                                onClick={() => setCompanyColour("violet")}
                                sx={{ color: "#fff", cursor: "pointer" }}
                              >
                                {registerForm.values.colour === "violet" && (
                                  <CheckIcon width={rem(10)} />
                                )}
                              </ColorSwatch>

                              <ColorSwatch
                                size={
                                  registerForm.values.colour === "blue"
                                    ? 32
                                    : 25
                                }
                                color={theme.colors.blue[6]}
                                onClick={() => setCompanyColour("blue")}
                                sx={{ color: "#fff", cursor: "pointer" }}
                              >
                                {registerForm.values.colour === "blue" && (
                                  <CheckIcon width={rem(10)} />
                                )}
                              </ColorSwatch>

                              <ColorSwatch
                                size={
                                  registerForm.values.colour === "teal"
                                    ? 32
                                    : 25
                                }
                                color={theme.colors.teal[6]}
                                onClick={() => setCompanyColour("teal")}
                                sx={{ color: "#fff", cursor: "pointer" }}
                              >
                                {registerForm.values.colour === "teal" && (
                                  <CheckIcon width={rem(10)} />
                                )}
                              </ColorSwatch>

                              <ColorSwatch
                                size={
                                  registerForm.values.colour === "lime"
                                    ? 32
                                    : 25
                                }
                                color={theme.colors.lime[6]}
                                onClick={() => setCompanyColour("lime")}
                                sx={{ color: "#fff", cursor: "pointer" }}
                              >
                                {registerForm.values.colour === "lime" && (
                                  <CheckIcon width={rem(10)} />
                                )}
                              </ColorSwatch>

                              <ColorSwatch
                                size={
                                  registerForm.values.colour === "yellow"
                                    ? 32
                                    : 25
                                }
                                color={theme.colors.yellow[6]}
                                onClick={() => setCompanyColour("yellow")}
                                sx={{ color: "#fff", cursor: "pointer" }}
                              >
                                {registerForm.values.colour === "yellow" && (
                                  <CheckIcon width={rem(10)} />
                                )}
                              </ColorSwatch>

                              <ColorSwatch
                                size={
                                  registerForm.values.colour === "orange"
                                    ? 32
                                    : 25
                                }
                                color={theme.colors.orange[6]}
                                onClick={() => setCompanyColour("orange")}
                                sx={{ color: "#fff", cursor: "pointer" }}
                              >
                                {registerForm.values.colour === "orange" && (
                                  <CheckIcon width={rem(10)} />
                                )}
                              </ColorSwatch>
                            </Group>
                          </Grid.Col>

                          <Grid.Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            offsetMd={3}
                            offsetLg={3}
                            offsetXl={3}
                          >
                            <Button
                              fullWidth
                              mt="xl"
                              disabled={isLoading}
                              onClick={() => next()}
                            >
                              Next
                            </Button>
                          </Grid.Col>

                          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Text
                              color="dimmed"
                              size="sm"
                              align="center"
                              mt="xl"
                            >
                              <Text
                                color="dimmed"
                                size="sm"
                                align="center"
                                mt={0}
                              >
                                Already Signed Up?{" "}
                                <Anchor
                                  size="sm"
                                  // href="#"
                                  onClick={() => setAuthCard("login")}
                                >
                                  Sign in here
                                </Anchor>
                              </Text>
                            </Text>
                          </Grid.Col>
                        </>
                      )}

                      {active === 1 && (
                        <>
                          <Grid.Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            style={{ minHeight: rem(100) }}
                            mt="sm"
                          >
                            <Text size="sm" fw={500}>
                              User's Image
                            </Text>

                            <Dropzone
                              accept={["image/png", "image/jpeg"]}
                              // onDrop={(file) =>
                              //   setFiles((image)=>(...image, image[1] = file)))
                              // }
                              onDrop={(file) => {
                                const newFiles = [...files];
                                newFiles[1] = file[0];
                                setFiles(newFiles);
                              }}
                              maxFiles={1}
                              multiple={false}
                              disabled={isLoading}
                            >
                              {files[1] === null && (
                                <Group
                                  position="center"
                                  //   spacing="xl"
                                  style={{
                                    minHeight: rem(95),
                                    pointerEvents: "none",
                                  }}
                                >
                                  <Dropzone.Accept>
                                    <IconUpload
                                      size="2.2rem"
                                      stroke={1.5}
                                      color={
                                        theme.colors[theme.primaryColor][
                                          theme.colorScheme === "dark" ? 4 : 6
                                        ]
                                      }
                                    />
                                  </Dropzone.Accept>
                                  <Dropzone.Reject>
                                    <IconX
                                      size="2.2rem"
                                      stroke={1.5}
                                      color={
                                        theme.colors.red[
                                          theme.colorScheme === "dark" ? 4 : 6
                                        ]
                                      }
                                    />
                                  </Dropzone.Reject>
                                  <Dropzone.Idle>
                                    <IconPhoto size="2.2rem" stroke={1.5} />
                                  </Dropzone.Idle>

                                  <div>
                                    <Text
                                      size="sm"
                                      color="dimmed"
                                      mt={2}
                                      align="center"
                                    >
                                      Drag image here or click to select.
                                      Accepts PNG and JPG files
                                    </Text>
                                  </div>
                                </Group>
                              )}

                              {previewsUserImage}
                            </Dropzone>
                          </Grid.Col>

                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputText
                              disabled={isLoading}
                              label="First Name"
                              placeholder=""
                              required={true}
                              form={registerForm}
                              name="firstName"
                            />
                          </Grid.Col>
                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputText
                              disabled={isLoading}
                              label="Surname"
                              placeholder=""
                              required={true}
                              form={registerForm}
                              name="surname"
                            />
                          </Grid.Col>
                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputText
                              disabled={isLoading}
                              label="Email"
                              placeholder=""
                              required={true}
                              form={registerForm}
                              name="userEmail"
                            />
                          </Grid.Col>
                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputText
                              disabled={isLoading}
                              label="Phone Number"
                              placeholder=""
                              required={true}
                              form={registerForm}
                              name="userPhone"
                            />
                          </Grid.Col>
                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <PasswordInput
                              disabled={isLoading}
                              label="Password"
                              placeholder=""
                              withAsterisk={true}
                              {...registerForm.getInputProps("password")}
                            />
                          </Grid.Col>
                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <PasswordInput
                              disabled={isLoading}
                              label="Confirm Password"
                              placeholder=""
                              withAsterisk={true}
                              {...registerForm.getInputProps("confirmPassword")}
                            />
                          </Grid.Col>
                          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Checkbox
                              {...registerForm.getInputProps("tnc")}
                              disabled={isLoading}
                              label={
                                <Text align="center">
                                  <Text align="center">
                                    I agree with the{" "}
                                    <Anchor size="sm" href="/#">
                                      terms and conditions
                                    </Anchor>
                                  </Text>
                                </Text>
                              }
                            />
                          </Grid.Col>
                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Button
                              fullWidth
                              mt="xl"
                              variant="subtle"
                              color="yellow"
                              disabled={isLoading}
                              onClick={() => back()}
                            >
                              <IconArrowLeft size={rem(18)} stroke={1.5} />{" "}
                              Previous Step
                            </Button>
                          </Grid.Col>
                          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Button
                              fullWidth
                              mt="xl"
                              type="submit"
                              loaderPosition="center"
                              loaderProps={{ size: "sm", color: "white" }}
                              loading={isLoading}
                            >
                              {isLoading ? "" : "Register Now"}
                            </Button>
                          </Grid.Col>
                        </>
                      )}
                    </Grid>
                  </form>
                </Paper>
              )}

              {activeCard === "forgot" && (
                // <></>
                <ForgotPasswordTab setAuthCard={setAuthCard} />
                // <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                //   <Center mb="md">
                //     <img
                //       src={smartStockLogoOnly}
                //       alt="smart stock"
                //       style={{ height: "80px" }}
                //     />
                //   </Center>

                //   <Title
                //     align="center"
                //     size="h2"
                //     sx={(theme) => ({
                //       fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                //       fontWeight: 700,
                //     })}
                //     //
                //   >
                //     Forgot Password?
                //   </Title>
                //   <Text color="dimmed" size="sm" align="center" mb="md">
                //     Enter your email to reset the password
                //   </Text>
                //   <Divider mb="md" color="gray.1" />

                //   <TextInput
                //     label="Your email"
                //     placeholder="email@domain.com"
                //     required
                //   />
                //   <Group position="apart" mt="lg" className={classes.controls}>
                //     <Anchor
                //       color="dimmed"
                //       size="sm"
                //       className={classes.control}
                //     >
                //       <Center inline>
                //         <IconArrowLeft size={rem(12)} stroke={1.5} />
                //         <Box ml={5} onClick={() => setAuthCard("login")}>
                //           Back to the login page
                //         </Box>
                //       </Center>
                //     </Anchor>
                //     <Button className={classes.control}>Reset password</Button>
                //   </Group>
                // </Paper>
              )}

              {activeCard === "register_success" && (
                <Paper
                  withBorder
                  shadow="md"
                  p={30}
                  radius="md"
                  mt="xl"
                  maw={450}
                >
                  <Title
                    align="center"
                    size="h2"
                    mb="md"
                    sx={(theme) => ({
                      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                      fontWeight: 700,
                    })}
                    color="teal.7"
                  >
                    Account Created
                  </Title>

                  <Center mb="md">
                    <img
                      src={mailSentImg}
                      alt="mail"
                      style={{ height: "150px" }}
                    />
                  </Center>

                  <Title
                    align="center"
                    size="h4"
                    sx={(theme) => ({
                      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                      fontWeight: 700,
                    })}
                    //
                  >
                    Please Verify Your Email
                  </Title>

                  <Text color="gray.7" size="sm" align="center" mb={1} mt="md">
                    We've sent an email to{" "}
                    <Text span c="teal.8" fw="bold" inherit size="sm">
                      {registrationData.email}
                    </Text>{" "}
                    to verify your email address and activate your account.
                    <br /> The link in the email will expire in 24 hours.
                  </Text>
                </Paper>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </Flex>
    </Container>
  );
}

export const variants = {
  show: {
    x: "0vh",
    opacity: 1,
  },
  hide: {
    x: "100vh",
    opacity: 0,
  },
  close: {
    x: "-100vh",
    opacity: 0,
  },
};
