import { useState } from "react";
import {
  Card,
  Table,
  Image,
  Badge,
  Group,
  Menu,
  Button,
  Text,
  Avatar,
  useMantineTheme,
  Stack,
  Divider,
  Flex,
  Tooltip,
  Center,
} from "@mantine/core";
import {
  IconCurrencyCent,
  IconFoldUp,
  IconInfoCircle,
  IconPencil,
} from "@tabler/icons-react";
import EditProductModal from "./edit_user_modal";
import CurrencyFormatter from "../../../utils/currency_formatter";
import getAvatarName from "../../../utils/get_avatar_name";
import dayjs from "dayjs";

const USER_IMAGE_URL = process.env.REACT_APP_USER_IMAGE_URL;

const DetailsDisplay = ({ user, modalLoading, editModalFn }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const theme = useMantineTheme();
  const textColor = theme.colorScheme === "dark" ? "white" : "dark.3";
  const fontWeightNormal = 500;
  const fontWeightBold = 700;

  const toggleEditModal = (value) => {
    setOpenEditModal(value);
  };

  return (
    <>
      {/* <EditProductModal
        opened={openEditModal}
        closeFunction={toggleEditModal}
        operationFn={editModalFn}
        isLoading={modalLoading}
        product={product}
      /> */}
      <Card withBorder shadow={1} radius="md">
        <Card.Section inheritPadding py="xl">
          <Stack align="center">
            <Avatar
              fit="contain"
              radius={55}
              size={110}
              color={theme.primaryColor}
              src={user?.avatar ? `${USER_IMAGE_URL}/${user?.avatar}` : null}
              alt={user?.name}
              // withPlaceholder
            >
              {getAvatarName(`${user?.firstName} ${user?.surname}`)}
            </Avatar>{" "}
            <Text fz="md" fw={fontWeightBold} color={textColor}>
              {`${user?.firstName} ${user?.surname}`}
            </Text>
            <Badge>{user?.email}</Badge>
          </Stack>
        </Card.Section>

        <Card.Section inheritPadding py="xl">
          <Flex
            gap="md"
            justify="space-between"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Text fz="md" fw={fontWeightBold} color={textColor}>
              Details
            </Text>
            <Button variant="outline" size="xs">
              Edit
            </Button>
          </Flex>
          <Divider mt="xs" mb="md" />

          <div className="mb-3">
            <Text fz="md" fw={fontWeightBold} color={textColor}>
              Phone Number
            </Text>
            <Text fz="sm" fw={fontWeightNormal} color={textColor}>
              {user?.phoneNo ? user?.phoneNo : "N/A"}
            </Text>
          </div>
          <div className="mb-3">
            <Text fz="md" fw={fontWeightBold} color={textColor}>
              Branch
            </Text>
            <Text fz="sm" fw={fontWeightNormal} color={textColor}>
              {user?.branch}
            </Text>
          </div>
          <div className="mb-3">
            <Flex align="center" gap="xs">
              <Text fz="md" fw={fontWeightBold} color={textColor}>
                Two Factor Auth.
              </Text>
              <Tooltip
                label="Adds an extra layer of protection"
                position="top-end"
                withArrow
                transitionProps={{ transition: "pop-bottom-right" }}
              >
                <Text color="dimmed" sx={{ cursor: "help" }}>
                  <Center>
                    <IconInfoCircle size="1.1rem" stroke={1.5} />
                  </Center>
                </Text>
              </Tooltip>
            </Flex>

            {user?.twoFactor ? (
              <Text fz="sm" fw={fontWeightNormal} color="teal">
                Yes
              </Text>
            ) : (
              <Text fz="sm" fw={fontWeightNormal} color="red">
                No
              </Text>
            )}
          </div>
          <div className="mb-3">
            <Text fz="md" fw={fontWeightBold} color={textColor}>
              Last Login
            </Text>
            <Text fz="sm" fw={fontWeightNormal} color={textColor}>
              {user?.lastLogIn
                ? dayjs(new Date(user?.lastLogIn)).format("DD MMM YYYY @ HH:mm")
                : "N/A"}
            </Text>
          </div>
        </Card.Section>
      </Card>
    </>
  );
};

export default DetailsDisplay;
