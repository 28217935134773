import { useState } from "react";
import {
  createStyles,
  rem,
  Avatar,
  Table,
  Group,
  Text,
  ActionIcon,
  Menu,
  ScrollArea,
  Badge,
} from "@mantine/core";
import {
  IconPencil,
  IconInfoSquareRounded,
  IconFoldUp,
  IconCurrencyCent,
  IconDots,
} from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import EditProductModal from "./edit_product_modal";
import RestockProductModal from "./restock_product_modal";
import PriceChangeModal from "./price_change_modal";
import getAvatarName from "../../../utils/get_avatar_name";
import CurrencyFormatter from "../../../utils/currency_formatter";
import MyPagination from "../../../components/pagination";
import { ThemeColourTableHeadText } from "../../../utils/theme_colour";

const PRODUCT_IMAGE_URL = process.env.REACT_APP_PRODUCT_IMAGE_URL;

const useStyles = createStyles((theme) => ({
  category: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    // background:
    //   theme.colorScheme === "dark"
    //     ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2) //theme.colors[theme.primaryColor][3] //theme.colors.dark[4]
    //     : theme.colors[theme.primaryColor][1], // theme.colors.gray[1],
    borderTopLeftRadius: theme.spacing.xs,
    borderBottomLeftRadius: theme.spacing.xs,
  },

  action: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    // background:
    //   theme.colorScheme === "dark"
    //     ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2) //theme.colors[theme.primaryColor][3] //theme.colors.dark[4]
    //     : theme.colors[theme.primaryColor][1],
    borderTopRightRadius: theme.spacing.xs,
    borderBottomRightRadius: theme.spacing.xs,
    textAlign: "right",
  },

  allTh: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    // background:
    //   theme.colorScheme === "dark"
    //     ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2) //theme.colors[theme.primaryColor][3] //theme.colors.dark[4]
    //     : theme.colors[theme.primaryColor][1],
  },

  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));

export function ProductsListTable({
  products,
  modalLoading,
  priceModalFn,
  restockModalFn,
  editModalFn,
}) {
  const { classes, theme } = useStyles();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openRestockModal, setOpenRestockModal] = useState(false);
  const [openChangePriceModal, setOpenChangePriceModal] = useState(false);
  const [activeProduct, setActiveProduct] = useState({});
  const [currentProducts, setCurrentProducts] = useState([]);

  const textColor =
    theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.dark[3];
  const fontWeightNormal = 500;
  const fontWeightBold = 700;

  const getCurrentProducts = (items) => {
    setCurrentProducts(items);
  };

  const rows = currentProducts.map((item, index) => (
    <tr key={index}>
      <td>
        <Group spacing="sm">
          <Avatar
            size={40}
            src={item?.image ? `${PRODUCT_IMAGE_URL}/${item.image}` : null}
            radius="sm"
            color={theme.primaryColor}
          >
            {getAvatarName(item?.name)}
          </Avatar>
          <div>
            <Text fz="sm" fw={fontWeightBold} color={textColor}>
              {item.name}
            </Text>
            <Text c="dimmed" fz="xs" fw={400}>
              {item.sku}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Text fz="sm" fw={fontWeightNormal} color={textColor}>
          {item?.categoryName ?? "None"}
        </Text>
      </td>
      <td className="text-end">
        {/* <Text> */}
        {/* <Text span fw="light" size="xs">
            GHS{" "}
          </Text> */}
        <Text span fz="sm" fw={fontWeightBold} color={textColor}>
          {CurrencyFormatter(item.price)}
        </Text>
        {/* </Text> */}
      </td>
      <td>
        <Text
          fz="sm"
          fw={fontWeightNormal}
          color={textColor}
          className="text-center"
        >
          {item.quantity}
        </Text>
      </td>
      <td>
        {item.available === 1 ? (
          <Badge variant="outline" size="xs" color="teal">
            Available
          </Badge>
        ) : (
          <Badge variant="outline" size="xs" color="red">
            Unavailable
          </Badge>
        )}
      </td>
      <td>
        <Group spacing={0} position="right">
          <ActionIcon
            color={theme.primaryColor}
            onClick={() => {
              setActiveProduct(item);
              toggleEditModal(true);
            }}
          >
            <IconPencil size="1.22rem" stroke={2} />
          </ActionIcon>
          <Menu
            transitionProps={{ transition: "pop" }}
            withArrow
            position="bottom-end"
            withinPortal
          >
            <Menu.Target>
              <ActionIcon color={theme.primaryColor}>
                <IconDots size="1.22rem" stroke={2} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <NavLink
                to={`/product-details?id=${item.id}&sku=${item.sku}`}
                style={{ textDecoration: "none" }}
              >
                <Menu.Item
                  icon={<IconInfoSquareRounded size="1rem" stroke={1.5} />}
                >
                  View Details
                </Menu.Item>
              </NavLink>

              <Menu.Item
                icon={<IconFoldUp size="1rem" stroke={1.5} />}
                onClick={() => {
                  setActiveProduct(item);
                  toggleRestockModal(true);
                }}
              >
                Restock Quantity
              </Menu.Item>
              <Menu.Item
                icon={<IconCurrencyCent size="1rem" stroke={1.5} />}
                onClick={() => {
                  setActiveProduct(item);
                  toggleChangePriceModal(true);
                }}
              >
                Change Price
              </Menu.Item>
              {/* <Menu.Item
                icon={<IconTrash size="1rem" stroke={1.5} />}
                color="red"
              >
                Terminate contract
              </Menu.Item> */}
            </Menu.Dropdown>
          </Menu>
        </Group>
      </td>
    </tr>
  ));

  const toggleEditModal = (value) => {
    setOpenEditModal(value);
  };
  const toggleRestockModal = (value) => {
    setOpenRestockModal(value);
  };
  const toggleChangePriceModal = (value) => {
    setOpenChangePriceModal(value);
  };

  return (
    <>
      <EditProductModal
        opened={openEditModal}
        closeFunction={toggleEditModal}
        operationFn={editModalFn}
        isLoading={modalLoading}
        product={activeProduct}
      />
      <RestockProductModal
        opened={openRestockModal}
        closeFunction={toggleRestockModal}
        operationFn={restockModalFn}
        isLoading={modalLoading}
        product={activeProduct}
      />
      <PriceChangeModal
        opened={openChangePriceModal}
        closeFunction={toggleChangePriceModal}
        operationFn={priceModalFn}
        isLoading={modalLoading}
        product={activeProduct}
      />
      <ScrollArea>
        <Table sx={{ minWidth: 800 }} verticalSpacing="sm" mb="lg">
          <thead>
            <tr>
              <th
                className={classes.category}
                style={{
                  borderBottom: "none",
                  color: ThemeColourTableHeadText(),
                }}
              >
                Name
              </th>
              <th
                className={classes.allTh}
                style={{
                  borderBottom: "none",
                  color: ThemeColourTableHeadText(),
                }}
              >
                Category
              </th>
              <th
                className={classes.allTh}
                style={{
                  textAlign: "right",
                  borderBottom: "none",
                  color: ThemeColourTableHeadText(),
                }}
              >
                Price<small> (GHS)</small>
              </th>
              <th
                className={classes.allTh}
                style={{
                  textAlign: "center",
                  borderBottom: "none",
                  color: ThemeColourTableHeadText(),
                }}
              >
                Qty
              </th>
              <th
                className={classes.allTh}
                style={{
                  borderBottom: "none",
                  color: ThemeColourTableHeadText(),
                }}
              >
                Status
              </th>
              <th
                className={classes.action}
                style={{
                  borderBottom: "none",
                  textAlign: "right",
                  color: ThemeColourTableHeadText(),
                }}
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
      <MyPagination allItems={products} currentItems={getCurrentProducts} />
    </>
  );
}
