import { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import AddUserForm from "./components/add_user_form";
import { useUsersAPI } from "../../services/users";
import { useNavigate } from "react-router-dom";

export default function AddNewUser() {
  document.title = "Add New User || Smart Stock";
  const { addUser } = useUsersAPI();
  const branchData = useSelector((state) => state.branch.branch.all || []);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formatData = (data) => {
    let itemList = [];
    data.map((item) => {
      itemList.push({ value: item.id, label: item.name });
    });
    return itemList;
  };

  const saveUser = async (values) => {
    try {
      setIsLoading(true);
      const results = await addUser(values);
      if (results.status) {
        navigate("/users");
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch pageTitle="Add New User" hideBranchSelector={true} />
        </Grid.Col>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <AddUserForm
            branches={formatData(branchData) || []}
            isLoading={isLoading}
            saveUser={saveUser}
          />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
