import { useState, useEffect } from "react";
import { Grid, Container, Button } from "@mantine/core";
import Branch from "../../components/branch";
import DetailsDisplay from "./components/details_display";
import { useSearchParams } from "react-router-dom";
import ShowLoader from "../../components/loader";
import { useSalesAPI } from "../../services/sales";
import InvoiceFormatter from "../../utils/invoice_formatter";
import { IconPrinter } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";

export default function InvoiceDetails() {
  document.title = "Invoice Details || Smart Stock";
  const { saleDetails } = useSalesAPI();
  const [searchParams] = useSearchParams();

  const [saleData, setSaleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const id = searchParams.get("id");
  const invoiceNo = searchParams.get("invoice");

  const getSaleData = async () => {
    try {
      setIsLoading(true);

      const results = await saleDetails({
        id: id,
        invoice: invoiceNo,
      });
      if (results.status) {
        setSaleData(results.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSaleData();
  }, []);

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch
            pageTitle={
              saleData?.details?.invoiceNo
                ? "Invoice No: " +
                  InvoiceFormatter(saleData?.details?.invoiceNo)
                : null
            }
            hideBranchSelector={true}
            component={
              saleData ? (
                <NavLink
                  to="/print-invoice"
                  // target="_blank"
                  state={{ data: saleData }}
                >
                  <Button
                    leftIcon={<IconPrinter />}
                    // color={theme.primaryColor}
                    variant="outline"
                  >
                    Print Invoice
                  </Button>
                </NavLink>
              ) : null
            }
          />
        </Grid.Col>
        {isLoading && <ShowLoader text="Loading Data..." mt={150} />}
        {!isLoading && <DetailsDisplay sale={saleData} />}
      </Grid>
    </Container>
  );
}
