import {
  Modal,
  useMantineTheme,
  Text,
  Title,
  Center,
  ScrollArea,
} from "@mantine/core";

const TermsAndConditionsModal = ({ opened, closeFunction }) => {
  const theme = useMantineTheme();

  const closeModal = () => {
    closeFunction(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={true}
      closeOnEscape={true}
      scrollAreaComponent={ScrollArea.Autosize}
      size="lg"
      // title="Terms And Conditions"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <div>
        <Center>
          <Title order={3}>Terms And Conditions</Title>
        </Center>
        <br />
        <Text c="dark">
          Welcome to Smart Stock, a stock-keeping service provided by GANO Tech.
          By using our services, you agree to comply with and be bound by the
          following terms and conditions. Please read them carefully.
          <br />
          <br />
          <b>1. Acceptance of Terms</b>
          <br /> By accessing or using the Smart Stock service, you agree to be
          bound by these terms and conditions (the "Terms"). If you do not agree
          with any part of the Terms, you may not use our service.
          <br />
          <br />
          <b>2. Service Description</b>
          <br /> Smart Stock is a stock-keeping service that helps you manage
          your inventory efficiently. GANO Tech provides this service to assist
          businesses in tracking their stock levels, orders, and deliveries.
          <br />
          <br />
          <b>3. Registration and Account Security</b>
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Eligibility: You must be at
          least 18 years old to use Smart Stock. <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Account Information: You agree
          to provide accurate, current, and complete information during the
          registration process and to update such information to keep it
          accurate, current, and complete. <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Account Security: You are
          responsible for maintaining the confidentiality of your account login
          credentials and for all activities that occur under your account. You
          agree to notify GANO Tech immediately of any unauthorized use of your
          account.
          <br />
          <br />
          <b>4. User Obligations</b>
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Compliance: You agree to
          comply with all applicable laws, regulations, and these Terms when
          using the Smart Stock service.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Prohibited Activities: You
          shall not use the service for any unlawful purpose or engage in any
          activity that could harm GANO Tech or its users, including but not
          limited to hacking, data theft, and unauthorized access.
          <br />
          <br />
          <b>5. Fees and Payment</b>
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Subscription Fees: Use of the
          Smart Stock service may require payment of subscription fees. Details
          of the fees and payment terms will be provided at the time of
          registration.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Payment Terms: All payments
          are due in advance according to the billing cycle you choose. GANO
          Tech reserves the right to change its fees and billing methods at any
          time.
          <br />
          <br />
          <b>6. Data Privacy and Security</b>
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Data Collection: GANO Tech
          collects and uses personal data in accordance with its Privacy Policy.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Data Security: GANO Tech
          employs reasonable security measures to protect your data. However, no
          method of transmission over the internet or electronic storage is 100%
          secure.
          <br />
          <br />
          <b>7. Intellectual Property</b>
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Ownership: All content,
          trademarks, and data on the Smart Stock service are the property of
          GANO Tech or its licensors. You may not use any of this content
          without prior written permission from GANO Tech.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> License: GANO Tech grants you
          a limited, non-exclusive, non-transferable license to use the service
          for your internal business purposes.
          <br />
          <br />
          <b>8. Termination</b>
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Termination by You: You may
          terminate your account at any time by contacting GANO Tech customer
          service.
          <br />
          <b style={{ fontSize: 18 }}>&#8226;</b> Termination by GANO Tech: GANO
          Tech reserves the right to suspend or terminate your account if you
          violate these Terms or if your use of the service poses a risk to GANO
          Tech or its users.
          <br />
          <br />
          <b>9. Limitation of Liability</b>
          <br /> To the maximum extent permitted by law, GANO Tech shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses resulting from your use of the Smart Stock service.
          <br />
          <br />
          <b>10. Indemnification</b>
          <br /> You agree to indemnify and hold harmless GANO Tech and its
          officers, directors, employees, and agents from any claims,
          liabilities, damages, losses, and expenses, including reasonable
          attorney's fees, arising out of or in any way connected with your use
          of the Smart Stock service or your violation of these Terms.
          <br />
          <br />
          <b>11. Modifications to Terms</b>
          <br /> GANO Tech reserves the right to modify these Terms at any time.
          Any changes will be effective immediately upon posting on our website.
          Your continued use of the service following the posting of revised
          Terms means that you accept and agree to the changes.
          <br />
          <br />
          <b>12. Governing Law</b>
          <br /> These Terms shall be governed by and construed in accordance
          with the laws of the jurisdiction in which GANO Tech operates, without
          regard to its conflict of law principles.
          <br />
          <br />
          <b>13. Contact Information</b>
          <br /> If you have any questions about these Terms, please contact
          GANO Tech at:
          <br />
          <br />
          Email: info@smartstock.ganotechgh.com
          <br />
          Phone: (233) 0504 919 472
          <br /> Address: No. 3 El-Senoussi Street, Ringway Estate, Accra
          <br />
          <br />
          Thank you for choosing <b>Smart Stock</b> by{" "}
          <a
            href="https://ganotechgh.com"
            rel="nofollow"
            style={{ color: "#12b886" }}
          >
            GANO Tech
          </a>
          . We look forward to helping you manage your inventory effectively and
          efficiently.
        </Text>
      </div>
    </Modal>
  );
};

export default TermsAndConditionsModal;
