import { Flex, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { ThemeColourEmptyList } from "../utils/theme_colour";

const ShowEmptyList = ({ label, iconSize, iconStroke }) => {
  return (
    <Flex
      gap="xs"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
      my="xl"
    >
      <IconAlertCircle
        size={iconSize ?? "5rem"}
        stroke={iconStroke ?? 0.7}
        color={ThemeColourEmptyList()}
      />
      <Text c={ThemeColourEmptyList()}>{label ?? "Nothing here..."}</Text>
    </Flex>
  );
};

export default ShowEmptyList;
