import { useState } from "react";
import {
  createStyles,
  rem,
  Avatar,
  Table,
  Group,
  Text,
  ActionIcon,
  ScrollArea,
  Badge,
} from "@mantine/core";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import EditBranchModal from "./edit_branch_modal";
import DeleteBranchModal from "./delete_branch_modal";
import MyPagination from "../../../components/pagination";

const useStyles = createStyles((theme) => ({
  category: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopLeftRadius: theme.spacing.xs,
    borderBottomLeftRadius: theme.spacing.xs,
  },

  action: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopRightRadius: theme.spacing.xs,
    borderBottomRightRadius: theme.spacing.xs,
    textAlign: "right",
  },

  allTh: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
  },

  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));

export function BranchesListTable({
  branches,
  updateBranchFn,
  deleteBranchFn,
  isModalLoading,
}) {
  const { classes, theme } = useStyles();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [branchData, setBranchData] = useState({});
  const [currentBranches, setCurrentBranches] = useState([]);

  const getCurrentBranches = (items) => {
    setCurrentBranches(items);
  };

  const rows = currentBranches.map((item, index) => (
    <tr key={index}>
      <td>
        <Text fz="sm" fw={500}>
          {item?.name}
        </Text>
      </td>
      <td>
        <Text fz="sm" fw={500}>
          {item?.phone === null || item?.phone === "" ? "N/A" : item?.phone}
        </Text>
      </td>
      <td>
        <Text fz="sm">
          {item?.address === null || item?.address === ""
            ? "N/A"
            : item?.address}
        </Text>
      </td>
      <td>
        <Text fz="sm" weight="bold" className="text-center">
          <Badge>{item?.items}</Badge>
        </Text>
      </td>
      {/* <td>
        <Badge>Available</Badge>
      </td> */}
      <td>
        <Group spacing={0} position="right">
          <ActionIcon
            color={theme.primaryColor}
            onClick={() => {
              setBranchData(item);
              toggleEditModal(item?.id, item?.name, item?.address, true);
            }}
          >
            <IconPencil size="1.22rem" stroke={2} />
          </ActionIcon>
          <ActionIcon
            color="red"
            onClick={() => toggleDeleteModal(item?.id, item?.name, true)}
          >
            <IconTrash size="1.22rem" stroke={2} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  const toggleEditModal = (value) => {
    setOpenEditModal(value);
  };
  const toggleDeleteModal = (branchId, branchName, value) => {
    setBranchData({ id: branchId, name: branchName });
    setOpenDeleteModal(value);
  };

  return (
    <>
      <EditBranchModal
        opened={openEditModal}
        closeFunction={toggleEditModal}
        branch={branchData}
        operationFn={updateBranchFn}
        isModalLoading={isModalLoading}
      />
      <DeleteBranchModal
        branchData={branchData}
        opened={openDeleteModal}
        closeFunction={toggleDeleteModal}
        operationFn={deleteBranchFn}
        isModalLoading={isModalLoading}
      />

      <ScrollArea>
        <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
          <tr>
            <th className={classes.category}>Name</th>
            <th className={classes.allTh}>Contact</th>
            <th className={classes.allTh}>Address</th>
            <th className={`text-center ${classes.allTh}`}>Total Items</th>
            {/* <th className={classes.allTh}>Status</th> */}
            <th className={classes.action}>Action</th>
          </tr>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
      <MyPagination allItems={branches} currentItems={getCurrentBranches} />
    </>
  );
}
