import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: {
    isUserAuthenticated: false,
    token: null,
    userData: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = {
        isUserAuthenticated: true,
        token: action.payload.token,
        userData: action.payload.user,
      };
      console.log("state.auth", state.auth);
    },
    logout: (state) => {
      state.auth = {
        isUserAuthenticated: false,
        token: null,
        userData: null,
      };
      console.log("state.auth", state.auth);
    },
  },
});

export const { setAuth, logout } = authSlice.actions;
export default authSlice.reducer;
