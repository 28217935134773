import { Group, Pagination } from "@mantine/core";
import { useEffect, useState } from "react";

const ITEMS_PER_PAGE = process.env.REACT_APP_ITEMS_PER_PAGE;

export default function MyPagination({ allItems, currentItems }) {
  const numberPerPage = ITEMS_PER_PAGE;

  const getTotalCount = () => {
    if (allItems === undefined || allItems.length === 0) {
      return 0;
    } else {
      return Math.ceil(allItems.length / numberPerPage);
    }
  };

  const [totalCount, setTotalCount] = useState(0);

  const setCurrentItems = (value) => {
    console.log("value", value);

    if (allItems === undefined || allItems.length === 0) {
      return [];
    } else {
      let start = (value - 1) * numberPerPage;
      return allItems.slice(start, value * numberPerPage);
    }
  };

  useEffect(() => {
    setTotalCount(getTotalCount());
    currentItems(setCurrentItems(1));
  }, []);

  return (
    <Group position="right">
      <Pagination
        mt="lg"
        withControls={false}
        total={totalCount}
        siblings={1}
        defaultValue={1}
        noWrap={true}
        onChange={(value) => currentItems(setCurrentItems(value))}
      />
    </Group>
  );
}
