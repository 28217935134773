import { useEffect } from "react";

import {
  Grid,
  Container,
  Textarea,
  Button,
  Modal,
  useMantineTheme,
  Flex,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import InputText from "../../../components/input_text";
import { IconDeviceFloppy, IconX } from "@tabler/icons-react";

const EditBranchModal = ({ opened, closeFunction, branch, operationFn }) => {
  console.log("branch", branch);
  const theme = useMantineTheme();

  const branchForm = useForm({
    initialValues: {
      name: branch?.name ? branch.name : "",
      phone: branch?.phone ? branch.phone : null,
      address: branch?.address ? branch.address : null,
    },

    validate: {
      name: (value) => value.length < 2,
    },
  });

  const update = (values) => {
    operationFn({
      id: branch?.id,
      name: values.name,
      phone: values.phone,
      address: values.address,
    });
  };

  const closeModal = () => {
    closeFunction(false);
  };

  useEffect(() => {
    branchForm.setValues({
      name: branch?.name ? branch.name : "",
      phone: branch?.phone ? branch.phone : null,
      address: branch?.address ? branch.address : null,
    });
  }, [branch]);

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={false}
      size="lg"
      title="Edit Customer"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <form onSubmit={branchForm.onSubmit((values) => update(values))}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputText
                label="Customer Name"
                // placeholder="Xbox One Controller - Black"
                required={true}
                form={branchForm}
                name="name"
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputText
                label="Phone"
                placeholder="0244444444,0200000000"
                form={branchForm}
                name="phone"
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Textarea
                placeholder="Compatible with Xbox One X, Xbox One S, Xbox One, Windows 10. 
              Includes Bluetooth technology for gaming on Windows 10 PCs and tablets."
                label="Address"
                minRows={3}
                maxRows={3}
                {...branchForm.getInputProps("address")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Flex
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  variant="outline"
                  leftIcon={<IconX size="1rem" />}
                  color="red"
                  onClick={closeModal}
                  px="xl"
                >
                  Cancel
                </Button>
                <Button
                  leftIcon={<IconDeviceFloppy size="1rem" />}
                  color={theme.primaryColor}
                  type="submit"
                  px="xl"
                >
                  Save
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </Modal>
  );
};

export default EditBranchModal;
