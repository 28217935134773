import "./ud-styles.css";
import { useWindowScroll } from "@mantine/hooks";
import {
  Accordion,
  // useMantineTheme,
  Affix,
  rem,
  Transition,
  Button,
  Menu,
  ActionIcon,
  Text,
  createStyles,
  TextInput,
  Textarea,
  Alert,
  Anchor,
  Grid,
} from "@mantine/core";
import {
  IconArrowUp,
  IconBuildingStore,
  IconCheck,
  IconCoins,
  IconMenu2,
  IconShoppingCart,
  IconUsersGroup,
  IconX,
  IconUser,
  IconMail,
  IconPhone,
  IconMailCheck,
  IconMapPin,
} from "@tabler/icons-react";
import { useState, useRef } from "react";
import TermsAndConditionsModal from "./components/tnc.modal";
import PrivacyPolicyModal from "./components/privacy.policy.modal";
import { motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import axios from "axios";

const Home = () => {
  document.title =
    "Smart Stock is an integrated software solution that streamlines stock management, sales and invoice generation for businesses.";
  // const theme = useMantineTheme();

  const API_URL = process.env.REACT_APP_API_URL;
  const CONTACT_URL = `${API_URL}/contact-us/`;

  const [scroll, scrollTo] = useWindowScroll();

  // const featureMore = [
  //   "Streamline Your Business with Our Sales & Invoice System: Boost productivity and enhance accuracy by managing sales and invoices seamlessly in one platform. Stay organized with real-time tracking and reporting, ensuring you never miss a payment or overlook a sale. Simplify complex financial processes and focus on growth while our intuitive system takes care of the rest. Experience the future of sales and invoicing today and elevate your business to new heights.",
  //   "Streamline Your Operations with Robust Stock Management: Our system empowers you to take charge of your inventory like never before. From accurate stock tracking to intelligent reorder alerts, we provide the tools you need to optimize stock levels and minimize shortages. With real-time insights and user-friendly interfaces, managing your inventory has never been this seamless. Elevate your efficiency and maximize profitability with our comprehensive Stock Management solution.",
  // ];

  // const [openFeatureModal, setOpenFeatureModal] = useState(null);
  const recaptcha = useRef();
  const [open, setOpen] = useState(false);
  const [openTnC, setOpenTnC] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [sendButton, setSendButton] = useState(null);
  const [messageSent, setMessageSent] = useState(false);

  const contactUsForm = useForm({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },

    validate: {
      name: (value) => (value.length < 2 ? " " : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : " "),
      phone: (value) => (value.length < 10 ? " " : null),
      message: (value) => (value.length < 3 ? " " : null),
    },
  });

  const useStyles = createStyles((theme) => ({
    burgerMenu: {
      [theme.fn.largerThan("md")]: {
        display: "none",
      },
    },
    authMenu: {
      [theme.fn.smallerThan("md")]: {
        display: "none",
      },
    },
  }));

  const toggleMenu = (value) => {
    setOpen(value);
  };

  const toggleTnCModal = (value) => {
    setOpenTnC(value);
  };
  const togglePrivacyPolicyModal = (value) => {
    setOpenPrivacy(value);
  };

  const scrollToView = (viewId) => {
    document.getElementById(viewId).scrollIntoView();
  };

  const { classes } = useStyles();

  const sendMessage = (values) => {
    console.log(values);
    const captchaValue = recaptcha.current.getValue();
    values.captchaToken = captchaValue; //Adding the captcha token to the contact us post data
    console.log(values);
    if (!captchaValue) {
      notifications.show({
        id: "hello-there",
        withCloseButton: true,
        autoClose: 5000,
        title: "Invalid CAPTCHA",
        message: "Please verify the reCAPTCHA!",
        color: "red",
        loading: false,
      });
      // console.log("Please verify the reCAPTCHA!");
    } else {
      // make form submission
      contactUsAPI(values);
      console.log("Form submission successful!");
    }
  };

  const contactUsAPI = async (contactData) => {
    setContactLoading(true);
    return await axios
      .post(CONTACT_URL, contactData, {})
      .then((res) => {
        console.log("contactUsAPI", res.data);
        if (res.data.status) {
          setMessageSent(true);
        } else {
          //Only show this if there's an error
          notifications.show({
            withCloseButton: true,
            autoClose: 5000,
            title: "Error",
            message: res.data.message,
            color: "red",
            // icon: <IconX />,
            loading: false,
          });
        }
        // return res.data;
        setContactLoading(false);
      })
      .catch((err) => {
        console.log("signInAPI", err.message);
        notifications.show({
          withCloseButton: true,
          autoClose: 5000,
          title: "Unknown Error",
          message: "An error occured. Please try again.",
          color: "red",
          // icon: <IconX />,
          loading: false,
        });
        setContactLoading(false);
      });
  };

  return (
    <>
      {/* <!-- ====== Go To Top Button Start ====== --> */}
      <Affix position={{ bottom: rem(20), right: rem(20) }}>
        <Transition transition="slide-up" mounted={scroll.y > 620}>
          {(transitionStyles) => (
            <Button
              radius="xl"
              size="lg"
              px="md"
              style={transitionStyles}
              onClick={() => scrollTo({ y: 0 })}
            >
              {<IconArrowUp size="1rem" />}
            </Button>
          )}
        </Transition>
      </Affix>
      {/* <!-- ====== Go To Top Button End ====== --> */}

      {/* <!-- ====== TnC Modal Start ====== --> */}
      <TermsAndConditionsModal
        opened={openTnC}
        closeFunction={toggleTnCModal}
      />
      {/* <!-- ====== TnC Modal End ====== --> */}

      {/* <!-- ====== Privacy Modal Start ====== --> */}
      <PrivacyPolicyModal
        opened={openPrivacy}
        closeFunction={togglePrivacyPolicyModal}
      />
      {/* <!-- ====== Privacy Modal End ====== --> */}

      {/* <!-- ====== Header Start ====== --> */}
      <header className="ud-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="#">
                  <img src="assets/images/logo/logo.svg" alt="Logo" />
                </a>

                {/* <button className="navbar-toggler" onClick={open}>
                  <span className="toggler-icon"> </span>
                  <span className="toggler-icon"> </span>
                  <span className="toggler-icon"> </span>
                </button>  */}

                {/* <!-- ====== Mobile Menu Start ====== --> */}
                <Menu
                  position="bottom-end"
                  shadow="md"
                  width={300}
                  offset={10}
                  // opened={open}

                  className={classes.burgerMenu}
                  onClose={() => toggleMenu(false)}
                  onOpen={() => toggleMenu(true)}

                  // clickOutsideEvents={() => toggleMenu(false)}
                >
                  <Menu.Target>
                    {!open ? (
                      <ActionIcon size="2.5rem">
                        <IconMenu2 size="2.5rem" color="#fff" />
                      </ActionIcon>
                    ) : (
                      <ActionIcon size="2.5rem">
                        <IconX size="2.5rem" color="#fff" />
                      </ActionIcon>
                    )}
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item>
                      <Text fz="md" onClick={() => scrollToView("home")}>
                        Home
                      </Text>
                    </Menu.Item>
                    <Menu.Item>
                      <Text fz="md" onClick={() => scrollToView("features")}>
                        Features
                      </Text>
                    </Menu.Item>
                    <Menu.Item>
                      <Text fz="md" onClick={() => scrollToView("about")}>
                        About
                      </Text>
                    </Menu.Item>
                    <Menu.Item>
                      <Text fz="md" onClick={() => scrollToView("pricing")}>
                        Pricing
                      </Text>
                    </Menu.Item>
                    <Menu.Item>
                      <Text fz="md" onClick={() => scrollToView("contact")}>
                        Contact
                      </Text>
                    </Menu.Item>

                    <Menu.Divider />

                    <Menu.Item>
                      <Anchor
                        c="dark"
                        fz="md"
                        fw={600}
                        href="/login"
                        target="_self"
                        style={{ display: "block", width: "100%" }}
                      >
                        Sign In
                      </Anchor>
                    </Menu.Item>
                    <Menu.Item>
                      <Anchor
                        c="dark"
                        fz="md"
                        fw={600}
                        href="/login"
                        target="_self"
                        style={{ display: "block", width: "100%" }}
                      >
                        Sign Up
                      </Anchor>
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
                {/* <!-- ====== Mobile Menu End ====== --> */}

                <div className="navbar-collapse">
                  <ul id="nav" className="navbar-nav mx-auto">
                    <li className="nav-item">
                      <a className="ud-menu-scroll" href="#home">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="ud-menu-scroll" href="#features">
                        Features
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="ud-menu-scroll" href="#about">
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="ud-menu-scroll" href="#pricing">
                        Pricing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="ud-menu-scroll" href="#contact">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>

                <div
                  // className={`navbar-btn d-none d-sm-inline-block ${classes.burgerMenu}`}
                  className={`navbar-btn ${classes.authMenu}`}
                >
                  <a href="login" className="ud-main-btn ud-login-btn">
                    Sign In
                  </a>
                  <a href="login" className="ud-main-btn ud-white-btn">
                    Sign Up
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ====== Header End ====== --> */}

      {/* <!-- ====== Hero Start ====== --> */}
      <section className="ud-hero" id="home">
        <div className="container">
          <div className="row">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, ease: "easeOut" }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 100 },
              }}
            >
              <div className="col-lg-12">
                <div className="ud-hero-content">
                  <h1 className="ud-hero-title">
                    Empowering Business Growth with
                    <br /> Seamless Stocks, Sales and
                    <br /> Invoice Management
                  </h1>
                  <p className="ud-hero-desc">
                    <b>Smart Stock </b>is an integrated software solution that
                    streamlines stock management, sales and invoice generation
                    for businesses, enhancing operational efficiency and
                    financial management.
                  </p>
                  {/* <ul className="ud-hero-buttons">
                  <li>
                    <a
                      href="https://links.uideck.com/play-bootstrap-download"
                      rel="nofollow noopener"
                      target="_blank"
                      className="ud-main-btn ud-white-btn"
                    >
                      Sign In
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/uideck/play-bootstrap"
                      rel="nofollow noopener"
                      target="_blank"
                      className="ud-main-btn ud-link-btn"
                    >
                      Register <IconArrowRight />
                    </a>
                  </li>
                </ul> */}
                </div>

                <div className="ud-hero-image  fadeInUp">
                  <img
                    src="assets/images/hero/hero-image.svg"
                    alt="hero-image"
                  />
                  <img
                    src="assets/images/hero/dotted-shape.svg"
                    alt="shape"
                    className="shape shape-1"
                  />
                  <img
                    src="assets/images/hero/dotted-shape.svg"
                    alt="shape"
                    className="shape shape-2"
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      {/* <!-- ====== Hero End ====== --> */}

      {/* <!-- ====== Features Start ====== --> */}
      <section id="features" className="ud-features">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ud-section-title">
                <span>Features</span>
                <h2>Why Choose Smart Stock?</h2>
                <p>
                  At Smart Stock, we pride ourselves on delivering exceptional
                  solutions tailored to your business needs. Here’s why our
                  customers choose us:
                </p>
              </div>
            </div>
          </div>

          <motion.div
            // initial={{ opacity: 0, y: 700 }}
            // animate={{ opacity: 1, y: 0 }}
            // transition={{ duration: 1.5, ease: "easeOut" }}
            // variants={{
            //   visible: { opacity: 1, scale: 1 },
            //   hidden: { opacity: 0, scale: 0 },
            // }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, ease: "easeOut" }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 200 },
            }}
          >
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="ud-single-feature fadeInUp">
                  <div className="ud-feature-icon">
                    <IconShoppingCart size={36} stroke={1} />
                  </div>
                  <div className="ud-feature-content">
                    <h3 className="ud-feature-title">Sales & Invoice System</h3>
                    <p className="ud-feature-desc">
                      Efficient Sales & Invoice System: Empowering Your
                      Financial Success
                    </p>
                    {/* <a href="javascript:void(0)" className="ud-feature-link">
                    Learn More
                  </a> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="ud-single-feature fadeInUp">
                  <div className="ud-feature-icon">
                    <IconBuildingStore size={36} stroke={1} />
                  </div>
                  <div className="ud-feature-content">
                    <h3 className="ud-feature-title">Stock Management</h3>
                    <p className="ud-feature-desc">
                      Efficient Stock Management: Maximizing Inventory Control
                      and Profitability
                    </p>
                    {/* <a href="javascript:void(0)" className="ud-feature-link">
                    Learn More
                  </a> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="ud-single-feature fadeInUp">
                  <div className="ud-feature-icon">
                    <IconUsersGroup size={36} stroke={1} />
                  </div>
                  <div className="ud-feature-content">
                    <h3 className="ud-feature-title">Multi Branch & Users</h3>
                    <p className="ud-feature-desc">
                      Streamlined Store Management: Unifying Multi-Branch & User
                      Operations
                    </p>
                    {/* <a href="javascript:void(0)" className="ud-feature-link">
                    Learn More
                  </a> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="ud-single-feature fadeInUp">
                  <div className="ud-feature-icon">
                    <IconCoins size={36} stroke={1} />
                  </div>
                  <div className="ud-feature-content">
                    <h3 className="ud-feature-title">Affordable Pricing</h3>
                    <p className="ud-feature-desc">
                      Pocket-Friendly Rates: Unbeatable Affordability for
                      Quality Services
                    </p>
                    {/* <a href="javascript:void(0)" className="ud-feature-link">
                    Learn More
                  </a> */}
                  </div>
                </div>
              </div>{" "}
            </div>
          </motion.div>
        </div>
      </section>
      {/* <!-- ====== Features End ====== --> */}

      {/* <!-- ====== About Start ====== --> */}
      <section id="about" className="ud-about">
        <div className="container">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, ease: "easeOut" }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 100 },
            }}
          >
            <div className="ud-about-wrapper">
              <div className="ud-about-content-wrapper">
                <div className="ud-about-content">
                  <span className="tag">About Smart Stock</span>
                  <h2>Stock, Sales & Invoice Made Simple</h2>
                  <p>
                    <b>Smart Stock</b> is a comprehensive business companion app
                    designed to revolutionize your stock management, sales
                    tracking, and invoicing processes.
                  </p>

                  <p>
                    Seamlessly monitor inventory levels, alert you on low
                    stocks, record sales transactions, and effortlessly generate
                    professional invoices on-the-go, empowering you to optimize
                    operations and elevate your financial management with ease.
                  </p>
                  {/* <a href="javascript:void(0)" className="ud-main-btn">
                  Learn More
                </a> */}
                </div>
              </div>
              <div
                className="ud-about-image ud-app-download-wrapper"
                // style={{ paddingTop: "70px", paddingRight: "70px" }}
              >
                <h3 style={{ textAlign: "center", paddingBottom: "16px" }}>
                  Always, On the Go?
                </h3>
                <p style={{ textAlign: "center" }}>
                  {" "}
                  <a
                    href="#"
                    rel="nofollow"
                    style={{ color: "#12b886", fontWeight: "bold" }}
                  >
                    Download
                  </a>{" "}
                  the mobile app from the Google Play Store.
                </p>
                <img
                  src="assets/images/about/about-image.svg"
                  alt="download-mobile-app"
                />
              </div>
            </div>
          </motion.div>
        </div>
      </section>
      {/* <!-- ====== About End ====== --> */}

      {/* <!-- ====== Pricing Start ====== --> */}
      <section id="pricing" className="ud-pricing">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ud-section-title mx-auto text-center">
                <span>Pricing</span>
                <h2>Budget-Friendly Pricing</h2>
                <p>
                  Unlock Cost-Effective Solutions with Our Affordable Pricing
                  Plans
                </p>
              </div>
            </div>
          </div>

          <div className="row g-0 align-items-center justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-10 order-lg-0 order-md-0 order-0">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, ease: "easeOut" }}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  hidden: { opacity: 0, y: 100 },
                }}
              >
                <div className="ud-single-pricing first-item">
                  <div className="ud-pricing-header">
                    {/* <h3>STARTING FROM</h3> */}
                    <h4>
                      <small>GHS</small> 0.00
                    </h4>
                  </div>
                  <div className="ud-pricing-body">
                    <ul>
                      <li>1 User</li>
                      <li>
                        1 Branch
                        <br />
                        <small style={{ color: "grey" }}>
                          (Only One Store)
                        </small>
                      </li>
                      <li>20 Products</li>
                      <li>5 Categories</li>
                      <li>
                        Mobile App Access
                        <IconX size={18} color="red" />
                      </li>

                      <li>
                        Low Stock Alert <IconX size={18} color="red" />
                      </li>
                    </ul>
                  </div>
                  <div className="ud-pricing-footer">
                    <a href="/login" className="ud-main-btn ud-border-btn">
                      Register Now
                    </a>
                  </div>
                </div>
              </motion.div>{" "}
            </div>

            <div className="col-lg-4 col-md-6 col-sm-10 order-lg-1 order-md-2 order-2">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, ease: "easeOut" }}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  hidden: { opacity: 0, y: 100 },
                }}
              >
                <div className="ud-single-pricing active">
                  <span className="ud-popular-tag">
                    SAVE <small>GHS</small> 100
                  </span>
                  <div className="ud-pricing-header">
                    {/* <h3>STARTING FROM</h3> */}
                    <h4>
                      <small>GHS</small> 500/Yr
                    </h4>
                  </div>
                  <div className="ud-pricing-body">
                    <ul>
                      <li>Unlimited Users</li>
                      <li>
                        Unlimited Branches <br />
                        <small style={{ color: "#bfe3d8" }}>
                          (Multiple Stores)
                        </small>
                      </li>
                      <li>Unlimited Products</li>
                      <li>Unlimited Categories</li>
                      <li>
                        Mobile App Access <IconCheck size={18} color="white" />
                      </li>

                      <li>
                        Low Stock Alert <IconCheck size={18} color="white" />{" "}
                        <br />
                        <small style={{ color: "#bfe3d8" }}>
                          (SMS* & Notification)
                        </small>
                      </li>
                      <li>
                        You Save{" "}
                        <b>
                          <small>GHS</small> 100
                        </b>
                      </li>
                    </ul>
                  </div>
                  <div className="ud-pricing-footer">
                    <a href="/login" className="ud-main-btn ud-white-btn">
                      Register Now
                    </a>
                  </div>
                </div>
              </motion.div>{" "}
            </div>

            <div className="col-lg-4 col-md-6 col-sm-10 order-lg-2 order-md-1 order-1">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, ease: "easeOut" }}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  hidden: { opacity: 0, y: 100 },
                }}
              >
                <div className="ud-single-pricing last-item">
                  <div className="ud-pricing-header">
                    {/* <h3>STARTING FROM</h3> */}
                    <h4>
                      <small>GHS</small> 50/Mo
                    </h4>
                  </div>
                  <div className="ud-pricing-body">
                    <ul>
                      <li>Unlimited Users</li>
                      <li>
                        Unlimited Branches <br />
                        <small style={{ color: "grey" }}>
                          (Multiple Stores)
                        </small>
                      </li>
                      <li>Unlimited Products</li>
                      <li>Unlimited Categories</li>
                      <li>
                        Mobile App Access <IconCheck size={18} color="green" />
                      </li>

                      <li>
                        Low Stock Alert <IconCheck size={18} color="green" />
                        <br />
                        <small style={{ color: "grey" }}>
                          (SMS* & Notification)
                        </small>
                      </li>
                    </ul>
                  </div>
                  <div className="ud-pricing-footer">
                    <a href="/login" className="ud-main-btn ud-border-btn">
                      Register Now
                    </a>
                  </div>
                </div>
              </motion.div>{" "}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====== Pricing End ====== --> */}

      {/* <!-- ====== FAQ Start ====== --> */}
      <section id="faq" className="ud-faq">
        <div className="shape">
          <img src="assets/images/faq/shape.svg" alt="shape" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ud-section-title text-center mx-auto">
                <span>FAQ</span>
                <h2>Any Questions? Answered</h2>
                <p>
                  Below you'll find answers to common questions about our
                  service. If you have additional questions, please don't
                  hesitate to contact us.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <Accordion
                multiple
                variant="separated"
                chevronPosition="left"
                defaultValue=""
                transitionDuration={700}
                radius="md"
              >
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <Accordion.Item
                    value="one"
                    className="bg-white"
                    py="lg"
                    my="lg"
                  >
                    <Accordion.Control>
                      <span className="fw-bold">
                        How does Smart Stock benefit my business?
                      </span>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <span>
                        Smart Stock helps streamline your inventory processes,
                        reduces the risk of overstocking or stockouts, saves
                        time on manual stocktaking, and provides valuable
                        insights through comprehensive reports, ultimately
                        improving your overall operational efficiency.
                      </span>
                    </Accordion.Panel>
                  </Accordion.Item>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }}
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <Accordion.Item
                    value="two"
                    className="bg-white"
                    py="lg"
                    my="lg"
                  >
                    <Accordion.Control>
                      <span className="fw-bold">
                        Can multiple users access the system?
                      </span>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <span>
                        Yes, Smart Stock supports multi-user access with
                        customizable permissions, allowing different team
                        members to access the information and features they
                        need.
                      </span>
                    </Accordion.Panel>
                  </Accordion.Item>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, ease: "easeOut", delay: 1 }}
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <Accordion.Item
                    value="three"
                    className="bg-white"
                    py="lg"
                    my="lg"
                  >
                    <Accordion.Control>
                      <span className="fw-bold">
                        Can I use Smart Stock without subscribing to a premium
                        plan?
                      </span>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <span>
                        Yes, upon registering, your account is offered a
                        one-month premium membership to explore the features and
                        benefits of Smart Stock. After the one-month period, you
                        can decide not to subscribe to a premium plan and
                        continue using the service in free mode with limited
                        functionalities.
                      </span>
                    </Accordion.Panel>
                  </Accordion.Item>
                </motion.div>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <Accordion
                multiple={true}
                variant="separated"
                chevronPosition="left"
                defaultValue=""
                transitionDuration={700}
                radius="md"
              >
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <Accordion.Item
                    value="four"
                    className="bg-white"
                    py="lg"
                    my="lg"
                  >
                    <Accordion.Control>
                      <span className="fw-bold">
                        What kind of customer support do you offer?
                      </span>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <span>
                        We provide 24/7 customer support via email and live
                        chat. Our support team is ready to assist you with any
                        questions or issues you may encounter.
                      </span>
                    </Accordion.Panel>
                  </Accordion.Item>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }}
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <Accordion.Item
                    value="five"
                    className="bg-white"
                    py="lg"
                    my="lg"
                  >
                    <Accordion.Control>
                      <span className="fw-bold">
                        What happens to my data if I cancel my subscription?
                      </span>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <span>
                        If you cancel your subscription, you can export your
                        data before the service is terminated. We also retain
                        your data for a period after cancellation in case you
                        decide to reactivate your account.
                      </span>
                    </Accordion.Panel>
                  </Accordion.Item>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, ease: "easeOut", delay: 1 }}
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <Accordion.Item
                    value="six"
                    className="bg-white"
                    py="lg"
                    my="lg"
                  >
                    <Accordion.Control>
                      <span className="fw-bold">
                        Will my data be shared with third parties?
                      </span>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <span>
                        GANO Tech does not share your data with third parties
                        without your consent, except as necessary to provide our
                        services or as required by law. Please see our Privacy
                        Policy for more information.
                      </span>
                    </Accordion.Panel>
                  </Accordion.Item>
                </motion.div>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====== FAQ End ====== --> */}

      {/* <!-- ====== Team Start ====== --> */}
      {/* <section id="team" className="ud-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ud-section-title mx-auto text-center">
                <span>Our Team</span>
                <h2>Meet The Team</h2>
                <p>
                  There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration in some form.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="ud-single-team wow fadeInUp" data-wow-delay=".1s">
                <div className="ud-team-image-wrapper">
                  <div className="ud-team-image">
                    <img src="assets/images/team/team-01.png" alt="team" />
                  </div>

                  <img
                    src="assets/images/team/dotted-shape.svg"
                    alt="shape"
                    className="shape shape-1"
                  />
                  <img
                    src="assets/images/team/shape-2.svg"
                    alt="shape"
                    className="shape shape-2"
                  />
                </div>
                <div className="ud-team-info">
                  <h5>Adveen Desuza</h5>
                  <h6>UI Designer</h6>
                </div>
                <ul className="ud-team-socials">
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-facebook-filled"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-twitter-filled"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-instagram-filled"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div
                className="ud-single-team wow fadeInUp"
                data-wow-delay=".15s"
              >
                <div className="ud-team-image-wrapper">
                  <div className="ud-team-image">
                    <img src="assets/images/team/team-02.png" alt="team" />
                  </div>

                  <img
                    src="assets/images/team/dotted-shape.svg"
                    alt="shape"
                    className="shape shape-1"
                  />
                  <img
                    src="assets/images/team/shape-2.svg"
                    alt="shape"
                    className="shape shape-2"
                  />
                </div>
                <div className="ud-team-info">
                  <h5>Jezmin uniya</h5>
                  <h6>Product Designer</h6>
                </div>
                <ul className="ud-team-socials">
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-facebook-filled"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-twitter-filled"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-instagram-filled"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="ud-single-team wow fadeInUp" data-wow-delay=".2s">
                <div className="ud-team-image-wrapper">
                  <div className="ud-team-image">
                    <img src="assets/images/team/team-03.png" alt="team" />
                  </div>

                  <img
                    src="assets/images/team/dotted-shape.svg"
                    alt="shape"
                    className="shape shape-1"
                  />
                  <img
                    src="assets/images/team/shape-2.svg"
                    alt="shape"
                    className="shape shape-2"
                  />
                </div>
                <div className="ud-team-info">
                  <h5>Andrieo Gloree</h5>
                  <h6>App Developer</h6>
                </div>
                <ul className="ud-team-socials">
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-facebook-filled"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-twitter-filled"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-instagram-filled"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div
                className="ud-single-team wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="ud-team-image-wrapper">
                  <div className="ud-team-image">
                    <img src="assets/images/team/team-04.png" alt="team" />
                  </div>

                  <img
                    src="assets/images/team/dotted-shape.svg"
                    alt="shape"
                    className="shape shape-1"
                  />
                  <img
                    src="assets/images/team/shape-2.svg"
                    alt="shape"
                    className="shape shape-2"
                  />
                </div>
                <div className="ud-team-info">
                  <h5>Jackie Sanders</h5>
                  <h6>Content Writer</h6>
                </div>
                <ul className="ud-team-socials">
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-facebook-filled"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-twitter-filled"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i className="lni lni-instagram-filled"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- ====== Team End ====== --> */}

      {/* <!-- ====== Contact Start ====== --> */}
      <section id="contact" className="ud-contact">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-7">
              <div className="ud-contact-content-wrapper">
                <div className="ud-contact-title">
                  <span>CONTACT US</span>
                  <h3>
                    Do you have any questions or suggestions?
                    <br />
                    We would love to hear from you.
                  </h3>
                </div>
                <div className="ud-contact-info-wrapper">
                  <div className="ud-single-info">
                    <div className="ud-info-icon">
                      <i className="lni lni-map-marker"></i>
                    </div>
                    <div className="ud-info-meta">
                      <Grid>
                        <Grid.Col span="content">
                          <IconMapPin size={40} stroke={1.7} color="teal" />
                        </Grid.Col>
                        <Grid.Col span="auto">
                          <div>
                            <h5>Our Location</h5>
                            <p>
                              No. 3 El-Senoussi Street, Ringway Estate, Accra
                            </p>
                          </div>
                        </Grid.Col>
                      </Grid>
                    </div>
                  </div>
                  <div className="ud-single-info">
                    <div className="ud-info-icon">
                      <i className="lni lni-envelope"></i>
                    </div>
                    <div className="ud-info-meta">
                      <Grid>
                        <Grid.Col span="content">
                          <IconMail size={40} stroke={1.7} color="teal" />
                        </Grid.Col>
                        <Grid.Col span="auto">
                          <div>
                            <h5>How Can We Help?</h5>
                            <p>info@ganotechgh.com</p>
                            <p>info@smartstock.ganotechgh.com</p>
                          </div>
                        </Grid.Col>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, ease: "easeOut" }}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  hidden: { opacity: 0, y: 100 },
                }}
              >
                <div className="ud-contact-form-wrapper">
                  <h3 className="ud-contact-form-title">Send us a Message</h3>

                  <form
                    onSubmit={contactUsForm.onSubmit((values) =>
                      sendMessage(values)
                    )}
                  >
                    <div className="ud-contact-form">
                      <TextInput
                        label="Name"
                        size="md"
                        withAsterisk
                        icon={<IconUser stroke={1} />}
                        {...contactUsForm.getInputProps("name")}
                        disabled={contactLoading || messageSent}
                        mb="md"
                      />

                      <TextInput
                        label="Email"
                        size="md"
                        withAsterisk
                        icon={<IconMail stroke={1} />}
                        {...contactUsForm.getInputProps("email")}
                        disabled={contactLoading || messageSent}
                        mb="md"
                      />

                      <TextInput
                        label="Phone"
                        size="md"
                        type="tel"
                        withAsterisk
                        icon={<IconPhone stroke={1} />}
                        {...contactUsForm.getInputProps("phone")}
                        disabled={contactLoading || messageSent}
                        mb="md"
                      />

                      <Textarea
                        label="Message"
                        radius="sm"
                        size="md"
                        withAsterisk
                        minRows={3}
                        maxRows={5}
                        {...contactUsForm.getInputProps("message")}
                        disabled={contactLoading || messageSent}
                        mb="md"
                      />

                      {!messageSent && (
                        <div className="ud-form-group mb-0">
                          <div
                            className="captcha"
                            style={{
                              transform: "scale(0.96)",
                              transformOrigin: "0 0",
                            }}
                          >
                            <ReCAPTCHA
                              onChange={() => setSendButton(true)}
                              onExpired={() => setSendButton(false)}
                              ref={recaptcha}
                              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            />
                          </div>

                          <Button
                            size="lg"
                            fullWidth
                            mt="md"
                            type="submit"
                            disabled={!sendButton}
                            loading={contactLoading}
                          >
                            <Text fz="md" fw={400}>
                              {contactLoading
                                ? "Sending Message..."
                                : "Send Message"}
                            </Text>
                          </Button>
                        </div>
                      )}

                      {messageSent && (
                        <Alert
                          icon={<IconMailCheck size="1rem" />}
                          title=" Message Sent"
                          color="cyan"
                          radius="sm"
                        >
                          Thank you for contacting us
                        </Alert>
                      )}
                    </div>
                  </form>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====== Contact End ====== --> */}

      {/* <!-- ====== Footer Start ====== --> */}

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1, ease: "easeOut" }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
      >
        {" "}
        <footer className="ud-footer">
          <div className="shape shape-1">
            <img src="assets/images/footer/shape-1.svg" alt="shape" />
          </div>
          <div className="shape shape-2">
            <img src="assets/images/footer/shape-2.svg" alt="shape" />
          </div>
          <div className="shape shape-3">
            <img src="assets/images/footer/shape-3.svg" alt="shape" />
          </div>

          <div className="ud-footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <ul className="ud-footer-bottom-left">
                    <li
                      onClick={() => togglePrivacyPolicyModal(true)}
                      className="cursor-pointer"
                    >
                      <a>Privacy policy</a>
                    </li>
                    {/* <li>
                    <a href="javascript:void(0)">Support policy</a>
                  </li> */}
                    <li
                      onClick={() => toggleTnCModal(true)}
                      className="cursor-pointer"
                    >
                      <a>Terms of service</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <p className="ud-footer-bottom-right">
                    © Copyright 2024{" "}
                    <a href="https://ganotechgh.com" rel="nofollow">
                      GANO Tech Co. LTD
                    </a>
                  </p>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
        </footer>
      </motion.div>
      {/* <!-- ====== Footer End ====== --> */}
    </>
  );
};

export default Home;
