import {
  Grid,
  Container,
  Text,
  Button,
  Modal,
  Divider,
  useMantineTheme,
  Flex,
} from "@mantine/core";

import { IconTrash, IconX } from "@tabler/icons-react";

const DeleteBranchModal = ({
  branchData,
  opened,
  closeFunction,
  operationFn,
  isModalLoading,
}) => {
  const theme = useMantineTheme();

  const closeModal = () => {
    closeFunction(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={false}
      size="lg"
      title="Delete Branch?"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <Grid>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Text fw={400}>
              Do you want to delete
              <Text span fw="bold" inherit>
                {` ${branchData.name} `}
              </Text>
              branch? Products in this branch will be
              <Text span c="red" fw="bold" inherit size="sm">
                {" "}
                deleted{" "}
              </Text>
              as well.
            </Text>
          </Grid.Col>

          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider my="xs" />
            <Text c="dimmed" size="sm" fw={300}>
              <Text span c="red" fw="bold" inherit size="sm">
                NB:{" "}
              </Text>
              To avoid products being deleted with the branch, go to the{" "}
              <Text span c={theme.primaryColor} inherit size="sm">
                Transfer{" "}
              </Text>
              section to tranfer all the products in this branch to another
              branch.
            </Text>
            <Divider my="xs" />
          </Grid.Col>

          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Flex
              gap="md"
              justify="flex-end"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Button
                variant="outline"
                leftIcon={<IconX size="1rem" />}
                // color="teal"
                onClick={closeModal}
                px="xl"
                disabled={isModalLoading}
              >
                Cancel
              </Button>
              <Button
                variant="outline"
                leftIcon={<IconTrash size="1rem" />}
                color="red"
                onClick={() => operationFn({ id: branchData?.id })}
                loading={isModalLoading}
                px="xl"
              >
                Delete
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};

export default DeleteBranchModal;
