import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import AddProductForm from "./components/add_product_form";
import { useNavigate } from "react-router-dom";
import { useProductsAPI } from "../../services/products";
import { useCategoriesAPI } from "../../services/categories";

export default function AddNewProduct() {
  document.title = "Add New Product || Smart Stock";
  const token = useSelector((state) => state.auth.auth.token);
  const branchData = useSelector((state) => state.branch.branch.all || []);
  const [categoriesData, setCategoriesData] = useState([]);
  const [isLoadingCategory, setIsLoadingCategory] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [branchSelectedId, setBranchSelectedId] = useState(true);
  const navigate = useNavigate();
  const { addData } = useProductsAPI();
  const { getDataFromBranch } = useCategoriesAPI();

  const getCategoriesData = async () => {
    try {
      setIsLoadingCategory(true);

      const results = await getDataFromBranch({
        branchId: branchSelectedId,
      });
      if (results.status) {
        setCategoriesData(results.categories);
      }
    } catch (error) {
    } finally {
      setIsLoadingCategory(false);
    }
  };

  const setBranch = (value) => {
    setBranchSelectedId(value);
  };

  const formatData = (data) => {
    let itemList = [];
    data.map((item) => {
      itemList.push({ value: item.id, label: item.name });
    });
    return itemList;
  };

  const saveProduct = async (values) => {
    try {
      setIsLoading(true);
      const results = await addData(values);
      if (results.status) {
        navigate("/products");
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesData();
  }, [branchSelectedId]);
  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch pageTitle="Add New Product" hideBranchSelector={true} />
        </Grid.Col>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <AddProductForm
            setBranch={setBranch}
            categories={formatData(categoriesData) || []}
            branches={formatData(branchData) || []}
            isLoading={isLoading}
            saveProduct={saveProduct}
          />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
