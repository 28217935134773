import { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import {
  Grid,
  Container,
  Textarea,
  Divider,
  Group,
  Text,
  Button,
  Image,
  rem,
  Box,
  Modal,
  useMantineTheme,
  Flex,
} from "@mantine/core";

import { Dropzone } from "@mantine/dropzone";
import InputText from "../../../components/input_text";
import InputSelect from "../../../components/input_select";
import { useForm } from "@mantine/form";
import {
  IconDeviceFloppy,
  IconX,
  IconUpload,
  IconPhoto,
} from "@tabler/icons-react";
import { useCategoriesAPI } from "../../../services/categories";

const PRODUCT_IMAGE_URL = process.env.REACT_APP_PRODUCT_IMAGE_URL;

const EditUserModal = ({
  opened,
  closeFunction,
  operationFn,
  isLoading,
  product,
}) => {
  const { getData } = useCategoriesAPI();
  const theme = useMantineTheme();
  const [files, setFiles] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  // const token = useSelector((state) => state.auth.auth.token);
  // const branchActiveId = useSelector((state) => state.branch.branch.activeId);

  const getCategoriesData = async () => {
    try {
      // setIsLoadingCategory(true);

      const results = await getData();
      if (results.status) {
        setCategoriesData(results.categories);
      }
    } catch (error) {}
  };

  const formatData = (data) => {
    let itemList = [];
    data.map((item) => {
      itemList.push({ value: item.id, label: item.name });
    });
    return itemList;
  };

  const productForm = useForm({
    initialValues: {
      name: product?.name ? product.name : "",
      image: null,
      description: product?.description ? product.description : null,
      categoryId: product?.categoryId ? product.categoryId : null,
    },

    validate: {
      name: (value) => value.length < 2,
    },
  });

  const update = (values) => {
    operationFn({
      id: product?.id,
      sku: product?.sku,
      name: values.name,
      image: values.image,
      categoryId: values.categoryId,
      description: values.description,
    });
  };

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Box maw={100} mx="auto" key={index}>
        <Image
          radius="md"
          key={index}
          src={imageUrl}
          width={100}
          height={100}
          fit="contain"
          imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
        />
      </Box>
    );
  });

  useEffect(() => {
    productForm.setValues({
      name: product?.name ? product.name : "",
      image: null,
      description: product?.description ? product.description : null,
      categoryId: product?.categoryId ? product.categoryId : null,
    });
  }, [product]);

  useEffect(() => {
    getCategoriesData();
  }, []);

  useEffect(() => {
    productForm.setFieldValue("image", files[0]);
  }, [files]);

  const closeModal = () => {
    setFiles([]);
    // productForm.reset();
    closeFunction(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={false}
      size="lg"
      title="Edit Product Details"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <form onSubmit={productForm.onSubmit((values) => update(values))}>
          <Grid>
            <Grid.Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ minHeight: rem(100) }}
            >
              <Text size="sm" fw={500}>
                Photo
              </Text>

              <Dropzone
                accept={["image/png", "image/jpeg"]}
                onDrop={setFiles}
                maxFiles={1}
                multiple={false}
              >
                {files.length <= 0 && !product?.image && (
                  <Group
                    position="center"
                    //   spacing="xl"
                    style={{ minHeight: rem(95), pointerEvents: "none" }}
                  >
                    <Dropzone.Accept>
                      <IconUpload
                        size="2.2rem"
                        stroke={1.5}
                        color={
                          theme.colors[theme.primaryColor][
                            theme.colorScheme === "dark" ? 4 : 6
                          ]
                        }
                      />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <IconX
                        size="2.2rem"
                        stroke={1.5}
                        color={
                          theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
                        }
                      />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      <IconPhoto size="2.2rem" stroke={1.5} />
                    </Dropzone.Idle>

                    <div>
                      <Text size="sm" color="dimmed" mt={2} align="center">
                        Drag image here or click to select. Accepts PNG and JPG
                        files
                      </Text>
                    </div>
                  </Group>
                )}

                {files.length <= 0 && product?.image && (
                  <Box maw={100} mx="auto">
                    <Image
                      radius="md"
                      src={
                        product?.image
                          ? `${PRODUCT_IMAGE_URL}/${product?.image}`
                          : null
                      }
                      width={100}
                      height={100}
                      fit="contain"
                    />
                  </Box>
                )}

                {previews}
              </Dropzone>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputText
                label="Product Name"
                placeholder="Xbox One Controller - Black"
                required={true}
                form={productForm}
                name="name"
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputSelect
                label="Category"
                placeholder="Xbox One Controller - Black"
                required={true}
                form={productForm}
                name="categoryId"
                data={formatData(categoriesData) || []}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Textarea
                placeholder="Product description here"
                label="Description"
                minRows={4}
                maxRows={4}
                maxLength={230}
                {...productForm.getInputProps("description")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Text c="dimmed" size="sm" fw={300}>
                <Text span c="red" fw="bold" inherit size="sm">
                  NB:{" "}
                </Text>
                To change the branch for this product, go to the{" "}
                <Text span c="teal" inherit size="sm">
                  Transfer{" "}
                </Text>
                section to tranfer to the desired branch.
              </Text>
              <Divider my="xs" />
            </Grid.Col>
            <Grid.Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              // mt="xl"
            >
              <Flex
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  variant="outline"
                  leftIcon={<IconX size="1rem" />}
                  color="red"
                  onClick={closeModal}
                  px="xl"
                >
                  Cancel
                </Button>
                <Button
                  leftIcon={<IconDeviceFloppy size="1rem" />}
                  color="teal"
                  type="submit"
                  px="xl"
                >
                  Save
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>{" "}
        </form>
      </Container>
    </Modal>
  );
};

export default EditUserModal;
