import { useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import {
  AppShell,
  Navbar,
  Header,
  //   Footer,
  //   Aside,
  //   Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  useMantineColorScheme,
  MantineProvider,
} from "@mantine/core";
import { useSessionStorage } from "@mantine/hooks";
import SideNavbar from "../components/navbar";
import { TopBar } from "../components/topbar";
import { useSelector } from "react-redux";

export default function AppSkeleton({ pageComponents, activePage }) {
  //   const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const comColour =
    useSelector((state) => state.auth.auth.userData?.colour) || "teal";
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [mode, setMode] = useSessionStorage({
    key: "theme-mode",
    defaultValue: "light",
  });

  const setColourMode = (value) => {
    setMode(value);
  };

  console.log("Skeleton Started");

  return (
    <MantineProvider
      theme={{
        primaryColor: comColour,
        colorScheme: mode,
        fontFamily: "Open Sans, sans-serif",
      }}
    >
      <AppShell
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : // : theme.colors.gray[0],
                "#f1f3f5",
        })}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        navbar={
          <Navbar
            p="md"
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ sm: 160, md: 180, lg: 300 }}
          >
            <SideNavbar
              activePage={activePage}
              closeBurger={() => setOpened(false)}
            />
          </Navbar>
        }
        //   aside={
        //     <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        //       <Aside p="md" hiddenBreakpoint="sm" width={{ sm: 200, lg: 300 }}>
        //         <Text>Application sidebar</Text>
        //       </Aside>
        //     </MediaQuery>
        //   }
        //   footer={
        //     <Footer height={60} p="md">
        //       Application footer
        //     </Footer>
        //   }
        header={
          <Header height={{ base: 50, md: 60 }} p="md">
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>

              <TopBar setColourMode={setColourMode} activeMode={mode} />
            </div>
          </Header>
        }
      >
        <Outlet />
      </AppShell>
    </MantineProvider>
  );
}
