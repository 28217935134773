import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux_slices/auth";
import showToast from "../components/toast";
import { useSelector } from "react-redux";

const API_URL = process.env.REACT_APP_API_URL;

export function useCustomersAPI() {
  const token = useSelector((state) => state.auth.auth.token);
  const branchId = useSelector((state) => state.branch.branch.activeId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCus = async () => {
    return await axios
      .get(`${API_URL}/list-customers?branchId=${branchId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "Error",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "Unknown Error",
            message: "An error occured. Please try again.",
          });
          return {
            status: false,
            message: "Could not load customer data. Please try again.",
          };
        }
      });
  };

  const addCus = async ({ name, phone, address }) => {
    return await axios
      .post(
        `${API_URL}/create-customer/`,
        { name: name, phone: phone, address: address, branchId: branchId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not save customer data. Please try again.",
          });
          return {
            status: false,
            message: "Could not save customer data. Please try again.",
          };
        }
      });
  };

  const updateCus = async ({ id, name, phone, address }) => {
    return await axios
      .post(
        `${API_URL}/update-customer/`,
        {
          id: id,
          name: name,
          phone: phone,
          address: address,
          branchId: branchId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not update customer data. Please try again.",
          });
          return {
            status: false,
            message: "Could not update customer data. Please try again.",
          };
        }
      });
  };

  const deleteCus = async ({ id }) => {
    return await axios
      .post(
        `${API_URL}/delete-customer/`,
        { id: id, branchId: branchId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not delete customer data. Please try again.",
          });
          return {
            status: false,
            message: "Could not delete customer data. Please try again.",
          };
        }
      });
  };

  return {
    getCus,
    addCus,
    updateCus,
    deleteCus,
  };
}
