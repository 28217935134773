import {
  Grid,
  Container,
  Text,
  Button,
  Modal,
  useMantineTheme,
  Flex,
} from "@mantine/core";
import { useDispatch } from "react-redux";
import { IconLogout, IconX } from "@tabler/icons-react";
import { logout } from "../redux_slices/auth";
import { useNavigate } from "react-router-dom";

const LogoutModal = ({ opened, closeFunction }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModal = () => {
    closeFunction(false);
  };

  const logoutOperation = () => {
    dispatch(logout()); //Clearing the user data in the redux state manager
    navigate("/login");
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      //   closeOnClickOutside={false}
      size="md"
      //   title="Confirm Logout?"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <Grid>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Text fw={400} fz="sm">
              Do you want to logout from the system?
            </Text>
          </Grid.Col>

          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Flex
              gap="md"
              justify="flex-end"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Button
                variant="outline"
                leftIcon={<IconX size="1rem" />}
                // color="teal"
                onClick={closeModal}
                px="xl"
              >
                Cancel
              </Button>
              <Button
                // variant="outline"
                leftIcon={<IconLogout size="1rem" />}
                color="red"
                onClick={() => logoutOperation()}
                // loading={isModalLoading}
                px="xl"
              >
                Yes
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};

export default LogoutModal;
