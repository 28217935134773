import {
  createStyles,
  Group,
  Paper,
  SimpleGrid,
  Text,
  rem,
} from "@mantine/core";
// import CountUp from "react-countup";
import {
  IconCoins,
  IconShoppingCart,
  IconArrowAutofitDown,
  IconBoxOff,
} from "@tabler/icons-react";
import StatCard from "./stat_card";

const useStyles = createStyles((theme) => ({
  root: {
    // padding: `calc(${theme.spacing.xl} * 1.5)`,
    // paddingTop: `calc(${theme.spacing.md} * 1.5)`,
  },

  card1: {
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
    transition: "transform 150ms ease, box-shadow 100ms ease",
    padding: theme.spacing.xl,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.01)",
    },

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(
        0,
        theme.colors.blue[6],
        theme.colors.blue[4]
      ),
    },
  },
  card2: {
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
    transition: "transform 150ms ease, box-shadow 100ms ease",
    padding: theme.spacing.xl,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.01)",
    },

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(
        0,
        theme.colors.teal[6],
        theme.colors.teal[4]
      ),
    },
  },
  card3: {
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
    transition: "transform 150ms ease, box-shadow 100ms ease",
    padding: theme.spacing.xl,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.01)",
    },

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(
        0,
        theme.colors.orange[6],
        theme.colors.orange[4]
      ),
    },
  },
  card4: {
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
    transition: "transform 150ms ease, box-shadow 100ms ease",
    padding: theme.spacing.xl,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.01)",
    },

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(
        0,
        theme.colors.red[6],
        theme.colors.red[4]
      ),
    },
  },

  title: {
    fontWeight: "500",
    textTransform: "uppercase",
  },

  value: {
    fontSize: rem(24),
    fontWeight: 700,
    lineHeight: 1,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[4]
        : theme.colors.dark[3],
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },

  icon1: {
    color:
      // theme.colorScheme === "dark"
      // ? theme.colors.dark[3]
      // : theme.colors.gray[4],
      theme.colors.blue[6],
  },
  icon2: {
    color: theme.colors.teal[6],
  },
  icon3: {
    color: theme.colors.orange[6],
  },
  icon4: {
    color: theme.colors.red[6],
  },

  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },
  icon: {
    color: theme.colors[theme.primaryColor][7],
  },
}));

const icons = {
  coins: IconCoins,
  "shopping-cart": IconShoppingCart,
  "arrow-autofit-down": IconArrowAutofitDown,
  "box-off": IconBoxOff,
};

export function StatsCard({ itemsSold, totalValue, LowOnStock, outOfStock }) {
  const { classes } = useStyles();

  const colors = [
    { first: "", last: "", icon: "shopping-cart" },
    { first: "", last: "", icon: "coins" },
    { first: "", last: "", icon: "arrow-autofit-down" },
    { first: "", last: "", icon: "box-off" },
  ];

  // console.log("data => ", data);

  // const stats = data?.map((stat, index) => {
  //   const Icon = icons[colors[index].icon];

  //   return (
  //     <Paper
  //       withBorder
  //       p="md"
  //       radius="md"
  //       key={index}
  //       className={classes.card1}
  //     >
  //       <Group position="apart">
  //         <Text size="xs" color="dimmed" className={classes.title}>
  //           {stat?.name}
  //         </Text>
  //         <Icon className={classes.icon} size="1.4rem" stroke={1.5} />
  //       </Group>

  //       <Group align="flex-end" spacing="xs" mt={25}>
  //         <Text className={`text-end ${classes.value}`}>
  //           <CountUp end={stat?.value} duration={4} />
  //         </Text>
  //       </Group>

  //       {/* <Text fz="xs" c="dimmed" mt={7}>
  //         Compared to previous month
  //       </Text> */}
  //     </Paper>
  //   );
  // });
  return (
    <div className={classes.root}>
      <SimpleGrid
        cols={4}
        breakpoints={[
          { maxWidth: "md", cols: 2 },
          { maxWidth: "xs", cols: 1 },
        ]}
      >
        {/* {stats} */}
        {/* <Paper withBorder p="md" radius="md" className={classes.card1}>
          <Group position="apart">
            <Text size="xs" color="dimmed" className={classes.title}>
              ITEMS SOLD
            </Text>
            <IconShoppingCart
              className={classes.icon1}
              size="1.4rem"
              stroke={1.7}
            />
          </Group>

          <Group align="flex-end" spacing="xs" mt={25} position="right">
            <Text className={classes.value}>
              <CountUp end={data[0]?.value ?? 0} duration={4} />
            </Text>
          </Group>
        </Paper> */}
        <StatCard
          title="ITEMS SOLD"
          value={itemsSold}
          badgeTitle={"This Week"}
          icon={<IconShoppingCart className={classes.icon} stroke={1.5} />}
        />
        <StatCard
          title="TOTAL VALUE"
          preValue="GHS"
          value={totalValue}
          badgeTitle={"This Week"}
          icon={<IconCoins className={classes.icon} stroke={1.5} />}
        />
        <StatCard
          title="LOW ON STOCK"
          value={LowOnStock}
          badgeTitle={"ALL PRODUCTS"}
          icon={<IconArrowAutofitDown className={classes.icon} stroke={1.5} />}
        />
        <StatCard
          title="OUT OF STOCK"
          value={outOfStock}
          badgeTitle={"all products"}
          icon={<IconBoxOff className={classes.icon} stroke={1.5} />}
        />

        {/* <StatCard /> */}
        {/* <Paper withBorder p="md" radius="md" className={classes.card2}>
          <Group position="apart">
            <Text size="xs" color="dimmed" className={classes.title}>
              Total Value
            </Text>
            <IconCoins className={classes.icon2} size="1.4rem" stroke={1.7} />
          </Group>

          <Group align="flex-end" spacing="xs" mt={25} position="right">
            <Text className={classes.value}>
              <Text span inline fz="sm" fw={500}>
                {" "}
                GHS{" "}
              </Text>
              <CountUp end={data[1]?.value ?? 0} duration={4} />
            </Text>
          </Group>
        </Paper> */}
        {/* <Paper withBorder p="md" radius="md" className={classes.card3}>
          <Group position="apart">
            <Text size="xs" color="dimmed" className={classes.title}>
              Low On Stock
            </Text>
            <IconArrowAutofitDown
              className={classes.icon3}
              size="1.4rem"
              stroke={1.7}
            />
          </Group>

          <Group align="flex-end" spacing="xs" mt={25} position="right">
            <Text className={classes.value}>
              <CountUp end={data[2]?.value ?? 0} duration={4} />
            </Text>
          </Group>
        </Paper> */}
        {/* <Paper withBorder p="md" radius="md" className={classes.card4}>
          <Group position="apart">
            <Text size="xs" color="dimmed" className={classes.title}>
              Out Of Stock
            </Text>
            <IconBoxOff className={classes.icon4} size="1.4rem" stroke={1.7} />
          </Group>

          <Group align="flex-end" spacing="xs" mt={25} position="right">
            <Text className={classes.value}>
              <CountUp end={data[3]?.value ?? 0} duration={4} />
            </Text>
          </Group>
        </Paper> */}
      </SimpleGrid>
    </div>
  );
}
