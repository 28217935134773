import { useState, useEffect } from "react";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import ReportsCard from "./components/reports_card";
import ShowLoader from "../../components/loader";
import { useReportsAPI } from "../../services/reports";

export default function Reports() {
  document.title = "Reports || Smart Stock";
  const { getReport } = useReportsAPI();

  const [reportsData, setReportsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setModalIsLoading] = useState("sales");
  const [reportType, setReportType] = useState("sales");
  const [salesFilter, setSalesFilter] = useState({
    reportType: "sales",
    dateFrom: null,
    dateTo: null,
    sellerId: null,
  });
  const [inventoryFilter, setInventoryFilter] = useState({
    reportType: "inventory",
    stock: null,
    status: null,
    categoryId: null,
  });

  const changeReportType = (value) => {
    setReportType(value);
  };

  const getReportsData = async () => {
    console.log("reportType", reportType);
    console.log("salesFilter", salesFilter);

    let filterData = {};
    if (reportType === "sales") {
      filterData = salesFilter;
    }
    if (reportType === "inventory") {
      filterData = inventoryFilter;
    }
    try {
      setIsLoading(true);

      const results = await getReport(filterData);
      if (results.status) {
        setReportsData(results.report);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReportsData();
  }, [reportType, salesFilter]);

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch
            pageTitle="Reports"
            onChangeFn={getReportsData}
            disableBranchSelector={isLoading}
          />
        </Grid.Col>
        {isLoading && (
          <ShowLoader text={`Loading ${reportType} Report...`} mt={150} />
        )}
        {!isLoading && (
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ReportsCard
              reports={reportsData}
              modalLoading={isModalLoading}
              filterReportFn={getReportsData}
              reportType={reportType}
              changeReportTypeFn={changeReportType}
              salesFilter={salesFilter}
              setSalesFilter={setSalesFilter}
              inventoryFilter={inventoryFilter}
            />
          </Grid.Col>
        )}
      </Grid>
    </Container>
  );
}
