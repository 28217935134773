import { useState } from "react";
import {
  createStyles,
  rem,
  Avatar,
  Table,
  Group,
  Text,
  ActionIcon,
  ScrollArea,
  Badge,
} from "@mantine/core";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import EditBranchModal from "./edit_branch_modal";
import DeleteBranchModal from "./delete_branch_modal";
import MyPagination from "../../../components/pagination";

const useStyles = createStyles((theme) => ({
  category: {
    padding: theme.spacing.xs,
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopLeftRadius: theme.spacing.xs,
    borderBottomLeftRadius: theme.spacing.xs,
  },

  action: {
    padding: theme.spacing.xs,
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopRightRadius: theme.spacing.xs,
    borderBottomRightRadius: theme.spacing.xs,
    textAlign: "right",
  },

  allTh: {
    padding: theme.spacing.xs,
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
  },

  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));

export function CustomersListTable({
  branches,
  updateBranchFn,
  deleteBranchFn,
  isModalLoading,
}) {
  const { classes, theme } = useStyles();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [branchData, setBranchData] = useState({});
  const [currentCustomers, setCurrentCustomers] = useState([]);

  const textColor =
    theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.dark[3];
  const fontWeightNormal = 500;
  const fontWeightBold = 700;

  const getCurrentCustomers = (items) => {
    setCurrentCustomers(items);
  };

  const rows = currentCustomers.map((item, index) => (
    <tr key={index}>
      <td>
        <Group spacing="sm">
          <div>
            <Text fz="sm" fw={fontWeightBold} color={textColor}>
              {item?.name}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Text fz="sm" fw={fontWeightNormal} color={textColor}>
          {item?.phone ?? "N/A"}
        </Text>
      </td>
      <td>
        <Text fz="sm" fw={fontWeightNormal} color={textColor}>
          {item?.address ?? "N/A"}
        </Text>
      </td>
      <td>
        <Group spacing={0} position="right">
          <ActionIcon
            color={theme.primaryColor}
            onClick={() => {
              setBranchData(item);
              toggleEditModal(
                item?.id,
                item?.name,
                item?.phone,
                item?.address,
                true
              );
            }}
          >
            <IconPencil size="1.22rem" stroke={2} />
          </ActionIcon>
          <ActionIcon
            color="red"
            onClick={() => toggleDeleteModal(item?.id, item?.name, true)}
          >
            <IconTrash size="1.22rem" stroke={2} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  const toggleEditModal = (value) => {
    setOpenEditModal(value);
  };
  const toggleDeleteModal = (branchId, branchName, value) => {
    setBranchData({ id: branchId, name: branchName });
    setOpenDeleteModal(value);
  };

  return (
    <>
      <EditBranchModal
        opened={openEditModal}
        closeFunction={toggleEditModal}
        branch={branchData}
        operationFn={updateBranchFn}
        isModalLoading={isModalLoading}
      />
      <DeleteBranchModal
        branchData={branchData}
        opened={openDeleteModal}
        closeFunction={toggleDeleteModal}
        operationFn={deleteBranchFn}
        isModalLoading={isModalLoading}
      />

      <ScrollArea>
        <Table sx={{ minWidth: 400 }} verticalSpacing="sm">
          <tr>
            <th className={classes.category}>Name</th>
            <th className={classes.allTh}>Phone</th>
            <th className={classes.allTh}>Address</th>
            <th className={classes.action}></th>
          </tr>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
      <MyPagination allItems={branches} currentItems={getCurrentCustomers} />
    </>
  );
}
