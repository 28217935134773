import { useState, useEffect } from "react";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import UsersTable from "./components/users_table";
import { useUsersAPI } from "../../services/users";
import ShowLoader from "../../components/loader";

export default function Users() {
  document.title = "Users || Smart Stock";
  const { getUsers } = useUsersAPI();

  const [usersData, setUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setModalIsLoading] = useState(false);

  const getUsersData = async () => {
    try {
      setIsLoading(true);

      const results = await getUsers();
      if (results.status) {
        setUsersData(results.users);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  // const addBranch = async ({ name, phone, address }) => {
  //   try {
  //     setModalIsLoading(true);

  //     const results = await addCustomerAPI({
  //       token: token,
  //       data: {
  //         name: name,
  //         phone: phone,
  //         address: address,
  //         branchId: branchActiveId,
  //       },
  //     });
  //     if (results.status) {
  //       getUsersData();
  //     }
  //   } catch (error) {
  //   } finally {
  //     setModalIsLoading(false);
  //   }
  // };

  // const updateBranch = async ({ id, name, phone, address }) => {
  //   try {
  //     setModalIsLoading(true);

  //     const results = await updateCustomerAPI({
  //       token: token,
  //       data: {
  //         name: name,
  //         phone: phone,
  //         address: address,
  //         id: id,
  //         branchId: branchActiveId,
  //       },
  //     });
  //     if (results.status) {
  //       getUsersData();
  //     }
  //   } catch (error) {
  //   } finally {
  //     setModalIsLoading(false);
  //   }
  // };

  // const deleteBranch = async ({ id }) => {
  //   try {
  //     setModalIsLoading(true);

  //     const results = await deleteCustomerAPI({
  //       token: token,
  //       data: {
  //         id: id,
  //         branchId: branchActiveId,
  //       },
  //     });
  //     if (results.status) {
  //       getUsersData();
  //     }
  //   } catch (error) {
  //   } finally {
  //     setModalIsLoading(false);
  //   }
  // };

  useEffect(() => {
    getUsersData();
  }, []);

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch
            pageTitle="Users"
            onChangeFn={getUsersData}
            disableBranchSelector={isLoading}
          />
        </Grid.Col>
        {isLoading && <ShowLoader text="Loading Users..." mt={150} />}
        {!isLoading && (
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <UsersTable
              users={usersData ?? []}
              isModalLoading={isModalLoading}
              // addBranchFn={addBranch}
              // updateBranchFn={updateBranch}
              // deleteBranchFn={deleteBranch}
            />
          </Grid.Col>
        )}
      </Grid>
    </Container>
  );
}
