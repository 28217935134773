import { useState } from "react";
import { Card, Group, createStyles, Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { CustomersListTable } from "./customers_list_table";
import AddBranchModal from "./add_branch_modal";
import ShowEmptyList from "../../../components/show_empty_list";

const useStyles = createStyles((theme) => ({
  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
}));

const CustomersTable = ({
  branches,
  isModalLoading,
  addBranchFn,
  updateBranchFn,
  deleteBranchFn,
}) => {
  const { classes, theme, cx } = useStyles();
  const [openAddModal, setOpenAddModal] = useState(false);

  const toggleAddModal = (value) => {
    setOpenAddModal(value);
  };

  return (
    <>
      <AddBranchModal
        opened={openAddModal}
        closeFunction={toggleAddModal}
        isModalLoading={isModalLoading}
        operationFn={addBranchFn}
      />

      <Card withBorder shadow={1} radius="md">
        <Card.Section inheritPadding py="xl">
          <Group position="right">
            <Group>
              <Button
                leftIcon={<IconPlus />}
                color={theme.primaryColor}
                onClick={() => toggleAddModal(true)}
              >
                Add New
              </Button>
            </Group>
          </Group>
        </Card.Section>
        <CustomersListTable
          branches={branches}
          updateBranchFn={updateBranchFn}
          deleteBranchFn={deleteBranchFn}
          isModalLoading={isModalLoading}
        />
        {branches?.length == 0 && (
          <ShowEmptyList label="No customers available" />
        )}
      </Card>
    </>
  );
};

export default CustomersTable;
