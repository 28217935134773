import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";

export default function AuthGuard({ component }) {
  const [status, setStatus] = useState(false);
  const isUserAuthenticated = useSelector(
    (state) => state.auth.auth.isUserAuthenticated
  );
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (!isUserAuthenticated) {
        navigate("/login");
      } else {
        setStatus(true);
      }
    } catch (error) {
      navigate("/login");
    }
  }, []);

  return status ? <Outlet /> : <></>;
}
