import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import InvoiceFormatter from "../../../utils/invoice_formatter";
import CurrencyFormatter from "../../../utils/currency_formatter";

// const PRODUCT_IMAGE_URL = process.env.REACT_APP_PRODUCT_IMAGE_URL;
const COMPANY_IMAGE_URL = process.env.REACT_APP_COMPANY_IMAGE_URL;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    //595
    paddingBottom: 99,
    margin: 38,
  },
  topSection: {
    display: "Flex",
    flexDirection: "row",
    alignContent: "space-between",
    marginBottom: "4px",
  },
  section: {
    // margin: 10,
    // padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  companyLogo: {
    backgroundColor: "#eeeeee",
    width: 55,
    height: 55,
  },
  companyName: {
    textAlign: "right",
  },

  textHeader: {
    fontSize: 18,
  },

  textTitle: {
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 700,
    fontFamily: "Times-Bold",
  },
  textTitle2: {
    fontSize: 18,
    lineHeight: 1.2,
    fontWeight: 700,
    fontFamily: "Times-Bold",
  },
  textBody: {
    fontSize: 12,
    lineHeight: 1.4,
    fontFamily: "Times-Roman",
  },

  textBodyBold: {
    fontSize: 12,
    lineHeight: 1.4,
    fontFamily: "Times-Bold",
    fontWeight: 700,
  },

  //

  table: {
    display: "table",
    width: 765,
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    fontFamily: "Times-Roman",
    // marginBottom: 38,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    fontFamily: "Times-Roman",
  },
  tableColNo: {
    width: "4%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableColDate: {
    width: "13%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    // flexDirection: "row",
    justifyContent: "center",
  },
  tableColInvoiceNo: {
    width: "6%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    // flexDirection: "row",
    justifyContent: "center",
  },
  tableColItem: {
    width: "28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    // flexDirection: "row",
    justifyContent: "center",
  },
  tableColPrice: {
    width: "8%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableColQty: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableColSubTotal: {
    width: "8%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableColCustomer: {
    width: "14%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    // flexDirection: "row",
    justifyContent: "center",
  },
  tableColSeller: {
    width: "14%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
    display: "Flex",
    // flexDirection: "row",
    justifyContent: "center",
  },
  tableCol: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Times-Roman",
  },
  tableCellHeadLeft: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Bold",
  },
  tableCellHeadRight: {
    textAlign: "right",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Bold",
  },
  tableCellHeadCenter: {
    textAlign: "center",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Bold",
  },
  tableCellImage: {
    backgroundColor: "#eeeeee",
    marginLeft: 3,
    marginTop: 3,
    marginBottom: 3,
    marginRight: 3,
    width: 25,
    height: 25,
  },
  tableCellLeft: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Roman",
  },
  tableCellRight: {
    textAlign: "right",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Roman",
  },
  tableCellCenter: {
    textAlign: "center",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 11,
    fontFamily: "Times-Roman",
  },
  tableCellSKULeft: {
    marginTop: 1,
    marginBottom: 3,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 9,
    color: "#3c3f42",
    fontFamily: "Times-Roman",
  },
  tableColGrandTotal: {
    display: "Flex",
    flexDirection: "row",
    width: 765,
  },
  tableColGrandTotalEmpty: {
    width: "46%",
    borderStyle: "solid",
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableColGrandTotalText: {
    width: "13%",
    borderStyle: "solid",
    borderTopWidth: 1.5,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
  tableColGrandTotaLAmount: {
    width: "13%",
    borderStyle: "solid",
    borderTopWidth: 1.5,
    fontFamily: "Times-Roman",
    display: "Flex",
    justifyContent: "center",
  },
});

// Create Document Component
export default function PrintSalesReport() {
  const location = useLocation();
  const data = location.state.data;
  const filter = location.state.filter;
  let grandTotal = 0;

  const rows = data?.items.map((item, index) => {
    grandTotal = grandTotal + item?.subTotal;
    return (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tableColNo}>
          <Text style={styles.tableCellRight}>{index + 1}</Text>
        </View>
        <View style={styles.tableColDate}>
          <Text style={styles.tableCellLeft}>
            {dayjs(new Date(item?.date)).format("DD-MMM-YY HH:mm:ss")}
          </Text>
        </View>
        <View style={styles.tableColInvoiceNo}>
          <Text style={styles.tableCellLeft}>
            {InvoiceFormatter(item?.invoiceNo)}
          </Text>
        </View>
        <View style={styles.tableColItem}>
          <Text style={styles.tableCellLeft}>{item?.name}</Text>
        </View>
        <View style={styles.tableColPrice}>
          <Text style={styles.tableCellRight}>
            {item?.price ? CurrencyFormatter(item?.price) : 0.0}
          </Text>
        </View>
        <View style={styles.tableColQty}>
          <Text style={styles.tableCellCenter}>{item?.quantity}</Text>
        </View>
        <View style={styles.tableColSubTotal}>
          <Text style={styles.tableCellRight}>
            {item?.subTotal ? CurrencyFormatter(item?.subTotal) : 0.0}
          </Text>
        </View>
        <View style={styles.tableColCustomer}>
          <Text style={styles.tableCellLeft}>
            {item?.customerName ?? "N/A"}
          </Text>
        </View>
        <View style={styles.tableColSeller}>
          <Text style={styles.tableCellLeft}>{item?.seller ?? "N/A"}</Text>
        </View>
      </View>
    );
  });

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" orientation="landscape" style={styles.page}>
          {/* TOP HEADER */}

          <View style={styles.topSection}>
            <div
              style={{
                deplay: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // alignContent: "space-between",
                width: 765,
              }}
            >
              <div>
                <Image
                  style={styles.companyLogo}
                  src={
                    data?.companylogo
                      ? `${COMPANY_IMAGE_URL}/${data?.companylogo}`
                      : "NoImage"
                  }
                />
              </div>
              <div style={{ textAlign: "right", width: "100%" }}>
                <Text style={styles.textTitle}>
                  {data?.companyName ? data?.companyName : null}
                </Text>
                <Text style={styles.textBody}>
                  {data?.branchAddress
                    ? data?.branchAddress
                    : data?.companyAddress}
                </Text>
                <Text style={styles.textBody}>
                  {data?.branchPhone ? data?.branchPhone : data?.companyPhone}
                </Text>
              </div>
            </div>
          </View>

          {/* DATA HEADER */}

          <View style={styles.topSection}>
            <div
              style={{
                textAlign: "center",
                width: 765,
              }}
            >
              <Text style={styles.textTitle2}>SALES REPORT</Text>
            </div>
          </View>

          <View style={styles.topSection}>
            <div
              style={{
                deplay: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // alignContent: "space-between",
                width: 765,
              }}
            >
              <div
                style={{
                  deplay: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={styles.textBodyBold}>Branch: </Text>
                <Text style={styles.textBody}>{data?.branchName}</Text>
              </div>

              {filter?.dateFrom && (
                <div
                  style={{
                    deplay: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.textBodyBold}>Date: </Text>
                  <Text style={styles.textBody}>
                    {dayjs(new Date(filter?.dateFrom)).format("DD/MM/YY")}
                    {filter?.dateTo
                      ? " - " +
                        dayjs(new Date(filter?.dateTo)).format("DD/MM/YY")
                      : null}
                  </Text>
                </div>
              )}
            </div>
          </View>

          {/* TABLE DATA */}

          {/* Row Headers */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColNo}>
                <Text style={styles.tableCellHeadRight}>No.</Text>
              </View>
              <View style={styles.tableColDate}>
                <Text style={styles.tableCellHeadLeft}>Date</Text>
              </View>
              <View style={styles.tableColInvoiceNo}>
                <Text style={styles.tableCellHeadCenter}>Invoice No.</Text>
              </View>
              <View style={styles.tableColItem}>
                <Text style={styles.tableCellHeadLeft}>Item</Text>
              </View>
              <View style={styles.tableColPrice}>
                <Text style={styles.tableCellHeadCenter}>Unit Price (GHS)</Text>
              </View>
              <View style={styles.tableColQty}>
                <Text style={styles.tableCellHeadCenter}>Qty Sold</Text>
              </View>
              <View style={styles.tableColSubTotal}>
                <Text style={styles.tableCellHeadCenter}>Sub-Total (GHS)</Text>
              </View>
              <View style={styles.tableColCustomer}>
                <Text style={styles.tableCellHeadLeft}>Customer</Text>
              </View>
              <View style={styles.tableColSeller}>
                <Text style={styles.tableCellHeadLeft}>Seller</Text>
              </View>
            </View>

            {/* Row Items  */}
            {rows}
          </View>

          {/* Row Empty */}
          <View style={{ width: 765, height: 15 }}></View>

          {/* Row Grand Total */}
          <div style={styles.tableColGrandTotal}>
            <View style={styles.tableColGrandTotalEmpty}>
              <Text></Text>
            </View>
            <View style={styles.tableColGrandTotalText}>
              <Text style={styles.tableCellHeadRight}>Grand Total (GHS)</Text>
            </View>
            <View style={styles.tableColGrandTotaLAmount}>
              <Text style={styles.tableCellHeadRight}>
                {CurrencyFormatter(grandTotal) ?? 0}
              </Text>
            </View>
          </div>
        </Page>
      </Document>
    </PDFViewer>
  );
}
