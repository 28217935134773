const getAvatarName = (name) => {
  let result;

  if (name) {
    result = name.split(" ");
  } else {
    return "-";
  }

  if (result.length > 1) {
    return result[0][0] + result[1][0];
  } else if (name.length > 2) {
    return name[0] + name[1];
  } else if (name.length === 2) {
    return name[0];
  } else {
    return "-";
  }
};

export default getAvatarName;
