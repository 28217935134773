import {
  Card,
  Group,
  ActionIcon,
  Autocomplete,
  createStyles,
  Divider,
  Button,
  Tooltip,
} from "@mantine/core";
import {
  IconSearch,
  IconPlus,
  IconFileText,
  IconFilterPlus,
  IconChevronRight,
} from "@tabler/icons-react";
import { SalesListTable } from "./sales_list_table";
import { NavLink } from "react-router-dom";
import Filter from "./filter";

const useStyles = createStyles((theme) => ({
  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
}));

const SalesTable = ({ sales, sellersData, filterFn, searchFn, filterData }) => {
  const { classes, theme, cx } = useStyles();

  return (
    <>
      <Card withBorder shadow={1} radius="md" mih={550}>
        <Card.Section inheritPadding py="xl">
          <Group position="apart">
            <Autocomplete
              disabled={sales?.length === 0 ? true : false}
              className={classes.search}
              placeholder="Search Invoice"
              icon={<IconSearch size="1rem" stroke={1.5} />}
              maxLength={20}
              rightSection={
                <Tooltip multiline width={100} withArrow label="Search Now">
                  <ActionIcon
                    variant="subtle"
                    color="orange"
                    disabled={sales?.length === 0 ? true : false}
                  >
                    <IconChevronRight size="md" stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              }
              onChange={(value) => searchFn(value)}
              data={
                [
                  // "React",
                  // "Angular",
                  // "Vue",
                  // "Next.js",
                  // "Riot.js",
                  // "Svelte",
                  // "Blitz.js",
                ]
              }
            />

            {/* <Group>
              <NavLink to="/new-sales">
                <Button
                  leftIcon={<IconPlus />}
                  color={theme.primaryColor}
                  // onClick={() => toggleAddModal(true)}
                >
                  New Sales
                </Button>
              </NavLink>

              <Divider orientation="vertical" />

              <Tooltip
                multiline
                width={220}
                withArrow
                label="Download the product list in PDF format."
              >
                <ActionIcon
                  variant="subtle"
                  color="orange"
                  disabled={sales?.length === 0 ? true : false}
                >
                  <IconFileText size="md" stroke={1.5} />
                </ActionIcon>
              </Tooltip>

              {sales?.length === 0 ? (
                <ActionIcon variant="subtle" color="blue" disabled>
                  <IconFilterPlus size="md" stroke={1.5} />
                </ActionIcon>
              ) : (
                <Filter
                  filterFn={filterFn}
                  filterData={filterData}
                  sellersData={sellersData}
                />
              )}
            </Group> */}
          </Group>
        </Card.Section>
        <SalesListTable sales={sales} />
      </Card>
    </>
  );
};

export default SalesTable;
