import React from "react";
import {
  createStyles,
  Badge,
  Group,
  Paper,
  PaperProps,
  Text,
  Box,
  rem,
} from "@mantine/core";
import CountUp from "react-countup";
import {
  IconCoins,
  IconShoppingCart,
  IconArrowAutofitDown,
  IconBoxOff,
} from "@tabler/icons-react";
import Surface from "../../../components/surface";

const useStyles = createStyles((theme) => ({
  value: {
    fontSize: rem(24),
    fontWeight: 500,
  },
  preValue: {
    fontSize: rem(12),
  },
  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },
  icon: {
    color: theme.colors[theme.primaryColor][7],
  },
}));

const StatCard = ({ title, value, preValue, badgeTitle, icon }) => {
  const { classes } = useStyles();

  return (
    <Paper shadow="xl" radius="md" p="xl">
      <Group position="apart">
        <Text size="xs" c="dimmed" className={classes.title}>
          {title}
        </Text>
        {badgeTitle && (
          <Badge variant="filled" radius="sm">
            {badgeTitle}
          </Badge>
        )}
      </Group>

      <Group position="apart" gap="xs" mt={25}>
        {/* <icon className={classes.icon} size="1.4rem" stroke={1.7} /> */}
        {icon}
        <Text className={classes.value}>
          <span className={classes.preValue}>{preValue}</span>
          <CountUp end={value ?? 0} duration={4} />
        </Text>
      </Group>
    </Paper>
  );
};

export default StatCard;
