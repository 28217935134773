import { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Divider,
  Group,
  Text,
  Button,
  Image,
  rem,
  Modal,
  useMantineTheme,
  Flex,
  NumberInput,
  ActionIcon,
  Tooltip,
} from "@mantine/core";

import {
  IconDeviceFloppy,
  IconTrendingUp,
  IconX,
  IconTrendingDown,
} from "@tabler/icons-react";

const PRODUCT_IMAGE_URL = process.env.REACT_APP_PRODUCT_IMAGE_URL;
const PriceChangeModal = ({
  opened,
  closeFunction,
  operationFn,
  isLoading,
  product,
}) => {
  const theme = useMantineTheme();
  const [currentPrice, setCurrentPrice] = useState(product?.price);
  const [newPrice, setNewPrice] = useState(product?.price);
  const [difference, setDifference] = useState(0);

  const closeModal = () => {
    setNewPrice(product?.price);
    setDifference(0);
    closeFunction(false);
  };

  const calculateDifferences = () => {
    if (typeof newPrice === "number") {
      setDifference(currentPrice - newPrice);
      diffValue();
    } else {
      setDifference(0);
      diffValue();
    }
  };

  const diffValue = () => {
    if (difference < 0) {
      let value = difference.toString().substring(1);
      value = parseFloat(value);
      return value;
    } else {
      return difference;
    }
  };

  useEffect(() => {
    setCurrentPrice(product?.price || 0);
    setNewPrice(product?.price || 0);
  }, [product]);

  useEffect(() => {
    calculateDifferences();
  }, [newPrice]);

  return (
    <Modal
      keepMounted={false}
      closeOnEscape={false}
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={false}
      size="lg"
      title="Change Price"
      withCloseButton={!isLoading}
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <Grid>
          <Grid.Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ minHeight: rem(100) }}
          >
            <Group>
              <Image
                radius="md"
                // src={imageUrl}
                width={100}
                height={100}
                fit="contain"
                src={
                  product?.image
                    ? `${PRODUCT_IMAGE_URL}/${product?.image}`
                    : null
                }
                // alt="With custom placeholder"
                withPlaceholder
              />
              <Flex
                gap="xs"
                justify="flex-start"
                align="flex-start"
                direction="column"
                // wrap="wrap"
              >
                <Text size="lg" truncate={true}>
                  {product?.name}
                </Text>
                <Text size="xs" color={theme.primaryColor} fw={700}>
                  {product?.sku}
                </Text>
              </Flex>
            </Group>
            <Divider mt="sm" />
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Group position="apart">
              <Text>Current Price:</Text>
              <NumberInput
                disabled={isLoading}
                size="md"
                value={currentPrice}
                placeholder="18"
                // disabled
                readOnly
                precision={2}
                type="number"
                hideControls
                styles={{ input: { width: rem(130), textAlign: "right" } }}
              />
            </Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Group position="apart">
              <Text>New Price:</Text>
              <NumberInput
                disabled={isLoading}
                size="md"
                value={newPrice}
                placeholder="0"
                min={0}
                precision={2}
                type="number"
                hideControls
                onChange={(value) => setNewPrice(value)}
                styles={{ input: { width: rem(130), textAlign: "right" } }}
              />
            </Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Group position="apart">
              <Text>Difference:</Text>
              <NumberInput
                disabled={isLoading}
                size="md"
                icon={
                  difference < 0 ? (
                    <PriceIncreased />
                  ) : difference > 0 ? (
                    <PriceDecreased />
                  ) : null
                }
                // {<IconTrendingUp size={16} />}
                value={diffValue()}
                onChange={(value) => console.log(value)}
                // min={0}
                // defaultValue={0}
                // placeholder="0"
                readOnly
                precision={2}
                type="number"
                hideControls
                styles={{ input: { width: rem(130), textAlign: "right" } }}
              />
            </Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider mb="md" />
            <Flex
              gap="md"
              justify="flex-end"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Button
                variant="outline"
                leftIcon={<IconX size="1rem" />}
                color="red"
                onClick={closeModal}
                px="xl"
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                leftIcon={<IconDeviceFloppy size="1rem" />}
                color={theme.primaryColor}
                // size="md"
                px="xl"
                onClick={() =>
                  operationFn({
                    id: product?.id,
                    sku: product?.sku,
                    newPrice: newPrice,
                  })
                }
                loading={isLoading}
                disabled={currentPrice === newPrice}
              >
                {isLoading ? "Updating..." : "Update"}
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};

export default PriceChangeModal;

const PriceIncreased = () => {
  return (
    <Tooltip label="Price Increased">
      <ActionIcon color="red" variant="light">
        <IconTrendingUp size="1.125rem" />
      </ActionIcon>
    </Tooltip>
  );
};

const PriceDecreased = () => {
  return (
    <Tooltip label="Price Decreased">
      <ActionIcon color="blue" variant="light">
        <IconTrendingDown size="1.125rem" />
      </ActionIcon>
    </Tooltip>
  );
};
