import { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Container,
  Group,
  useMantineTheme,
  Text,
  Button,
  Image,
  rem,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dropzone } from "@mantine/dropzone";
import InputText from "../../../components/input_text";
import InputSelect from "../../../components/input_select";
import {
  IconDeviceFloppy,
  IconX,
  IconUpload,
  IconPhoto,
} from "@tabler/icons-react";

const AddUserForm = ({ saveUser, isLoading, branches }) => {
  const theme = useMantineTheme();
  const [files, setFiles] = useState([]);

  const productForm = useForm({
    initialValues: {
      email: "",
      image: null,
      surname: "",
      branchId: "",
      firstName: "",
      phone: "",
      role: "",
    },

    validate: {
      email: (value) => value.length < 2,
      firstName: (value) => value.length < 2,
      branchId: (value) => value === "",
      surname: (value) => value.length < 2,
      role: (value) => value === "",
    },
  });

  const save = (values) => {
    saveUser(values);
  };

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Box maw={100} mx="auto" key={index}>
        <Image
          radius="md"
          key={index}
          src={imageUrl}
          width={100}
          height={100}
          fit="contain"
          imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
        />
      </Box>
    );
  });

  useEffect(() => {
    productForm.setFieldValue("image", files[0]);
  }, [files]);

  return (
    <Card withBorder shadow={1} radius="md">
      <Container fluid={true}>
        <form onSubmit={productForm.onSubmit((values) => save(values))}>
          <Grid>
            <Grid.Col
              xs={12}
              sm={12}
              md={6}
              offsetMd={3}
              lg={6}
              offsetLg={3}
              xl={6}
              offsetXl={3}
              style={{ minHeight: rem(100) }}
              mb="md"
            >
              <Text size="sm" fw={500}>
                User Photo
              </Text>

              <Dropzone
                accept={["image/png", "image/jpeg"]}
                // onDrop={setFiles}
                disabled={isLoading}
                onDrop={setFiles}
                maxFiles={1}
                multiple={false}
              >
                {files.length <= 0 && (
                  <Group
                    position="center"
                    //   spacing="xl"
                    style={{ minHeight: rem(95), pointerEvents: "none" }}
                  >
                    <Dropzone.Accept>
                      <IconUpload
                        size="2.2rem"
                        stroke={1.5}
                        color={
                          theme.colors[theme.primaryColor][
                            theme.colorScheme === "dark" ? 4 : 6
                          ]
                        }
                      />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <IconX
                        size="2.2rem"
                        stroke={1.5}
                        color={
                          theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
                        }
                      />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      <IconPhoto size="2.2rem" stroke={1.5} />
                    </Dropzone.Idle>

                    <div>
                      <Text size="sm" color="dimmed" mt={2} align="center">
                        Drag image here or click to select. Accepts PNG and JPG
                        files
                      </Text>
                    </div>
                  </Group>
                )}

                {previews}
              </Dropzone>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputText
                label="Email"
                disabled={isLoading}
                required={true}
                form={productForm}
                name="email"
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputText
                label="First Name"
                disabled={isLoading}
                // placeholder="XOC1672"
                required={true}
                // useToolTip={true}
                // toolTipLabel="This uniquely identifies the product in the inventory."
                form={productForm}
                name="firstName"
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputText
                label="Surname"
                disabled={isLoading}
                // placeholder="XOC1672"
                required={true}
                form={productForm}
                name="surname"
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputText
                label="Phone"
                disabled={isLoading}
                // placeholder="XOC1672"
                // type="number"
                maxLength={10}
                // useToolTip={true}
                // toolTipLabel="This uniquely identifies the product in the inventory."
                form={productForm}
                name="phone"
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputSelect
                label="Branch"
                disabled={isLoading}
                placeholder="Select Branch"
                required={true}
                form={productForm}
                data={branches}
                name="branchId"
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputSelect
                disabled={isLoading}
                data={[{ value: "sales", label: "Sales" }]}
                label="Role"
                required={true}
                placeholder="Select User Role"
                form={productForm}
                name="role"
              />
            </Grid.Col>

            {/* <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider label="Info" labelPosition="left" />
            </Grid.Col> */}
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Text c="dimmed" size="sm" fw={300}>
                <Text span c="red" fw="bold" inherit size="sm">
                  NB:{" "}
                </Text>
                The password would be sent to the user via the entered email
                address.
              </Text>
            </Grid.Col>

            <Grid.Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              offsetMd={8}
              offsetLg={8}
              offsetXl={8}
              // mt="xl"
            >
              <Group position="right">
                <Button
                  type="submit"
                  leftIcon={<IconDeviceFloppy size="1rem" />}
                  // size="md"
                  px="xl"
                  loading={isLoading}
                >
                  Save
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </Card>
  );
};

export default AddUserForm;
