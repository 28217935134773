import { useState } from "react";
import {
  Card,
  Table,
  Image,
  Badge,
  Group,
  Menu,
  Button,
  Text,
  Avatar,
  useMantineTheme,
} from "@mantine/core";
import { IconCurrencyCent, IconFoldUp, IconPencil } from "@tabler/icons-react";
import EditProductModal from "./edit_product_modal";
import RestockProductModal from "./restock_product_modal";
import PriceChangeModal from "./price_change_modal";
import CurrencyFormatter from "../../../utils/currency_formatter";
import getAvatarName from "../../../utils/get_avatar_name";

const PRODUCT_IMAGE_URL = process.env.REACT_APP_PRODUCT_IMAGE_URL;

const DetailsDisplay = ({
  product,
  modalLoading,
  priceModalFn,
  restockModalFn,
  editModalFn,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openRestockModal, setOpenRestockModal] = useState(false);
  const [openChangePriceModal, setOpenChangePriceModal] = useState(false);
  const theme = useMantineTheme();
  const textColor = theme.colorScheme === "dark" ? "white" : "dark.3";
  const fontWeightNormal = 500;
  const fontWeightBold = 700;

  // console.log("product", product);

  const toggleEditModal = (value) => {
    setOpenEditModal(value);
  };
  const toggleRestockModal = (value) => {
    setOpenRestockModal(value);
  };
  const toggleChangePriceModal = (value) => {
    setOpenChangePriceModal(value);
  };

  return (
    <>
      <EditProductModal
        opened={openEditModal}
        closeFunction={toggleEditModal}
        operationFn={editModalFn}
        isLoading={modalLoading}
        product={product}
      />
      <RestockProductModal
        opened={openRestockModal}
        closeFunction={toggleRestockModal}
        operationFn={restockModalFn}
        isLoading={modalLoading}
        product={product}
      />
      <PriceChangeModal
        opened={openChangePriceModal}
        closeFunction={toggleChangePriceModal}
        operationFn={priceModalFn}
        isLoading={modalLoading}
        product={product}
      />
      <Card withBorder shadow={1} radius="md">
        <Card.Section inheritPadding py="xl">
          <Group position="apart">
            <Avatar
              fit="contain"
              radius="md"
              // height={150}
              // width={150}
              size={130}
              color={theme.primaryColor}
              src={
                product?.image ? `${PRODUCT_IMAGE_URL}/${product?.image}` : null
              }
              alt={product?.name}
              // withPlaceholder
            >
              {getAvatarName(product?.name)}
            </Avatar>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button variant="outline">Action</Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconPencil size={14} />}
                  onClick={() => toggleEditModal(true)}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  icon={<IconFoldUp size={14} />}
                  onClick={() => toggleRestockModal(true)}
                >
                  Restock
                </Menu.Item>
                <Menu.Item
                  icon={<IconCurrencyCent size={14} />}
                  onClick={() => toggleChangePriceModal(true)}
                >
                  Change Price
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Card.Section>

        <Table verticalSpacing="sm" striped withBorder withColumnBorders>
          <tbody>
            <tr>
              <td>
                <Text fz="sm" fw={fontWeightBold} color={textColor}>
                  Name
                </Text>
              </td>
              <td>
                {" "}
                <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                  {product?.name}{" "}
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text fz="sm" fw={fontWeightBold} color={textColor}>
                  SKU
                </Text>
              </td>
              <td>
                {" "}
                <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                  {product?.sku}
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text fz="sm" fw={fontWeightBold} color={textColor}>
                  Description
                </Text>
              </td>
              <td>
                <Text fz="xs" fw={fontWeightNormal} color={textColor}>
                  {product?.description}
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text fz="sm" fw={fontWeightBold} color={textColor}>
                  Category
                </Text>
              </td>
              <td>
                <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                  {product?.categoryName ?? "None"}
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text fz="sm" fw={fontWeightBold} color={textColor}>
                  Price
                </Text>
              </td>
              <td>
                <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                  <Text span fw="light" size="xs">
                    GHS{" "}
                  </Text>{" "}
                  {product?.price ? CurrencyFormatter(product?.price) : 0}
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text fz="sm" fw={fontWeightBold} color={textColor}>
                  Quantity
                </Text>
              </td>
              <td>
                <Text fz="sm" fw={fontWeightNormal} color={textColor}>
                  {product?.quantity}
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text fz="sm" fw={fontWeightBold} color={textColor}>
                  Status
                </Text>
              </td>
              <td>
                {product?.available === 1 ? (
                  <Badge variant="outline" size="xs" color="teal">
                    Available
                  </Badge>
                ) : (
                  <Badge variant="outline" size="xs" color="red">
                    Unavailable
                  </Badge>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default DetailsDisplay;
