import { useState } from "react";
import {
  createStyles,
  rem,
  Table,
  Group,
  Text,
  ActionIcon,
  ScrollArea,
} from "@mantine/core";
import { NavLink } from "react-router-dom";
import { IconEye } from "@tabler/icons-react";
import dayjs from "dayjs";
import InvoiceFormatter from "../../../utils/invoice_formatter";
import CurrencyFormatter from "../../../utils/currency_formatter";
import MyPagination from "../../../components/pagination";

const useStyles = createStyles((theme) => ({
  category: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopLeftRadius: theme.spacing.xs,
    borderBottomLeftRadius: theme.spacing.xs,
  },

  action: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopRightRadius: theme.spacing.xs,
    borderBottomRightRadius: theme.spacing.xs,
    // textAlign: "right",
  },

  allTh: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
  },

  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));

export function SalesReportListTable({ sales }) {
  const [currentSales, setCurrentSales] = useState([]);
  const { classes, theme } = useStyles();

  console.log("sales", sales);

  const textColor =
    theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.dark[3];
  const fontWeightNormal = 500;
  const fontWeightBold = 700;

  // const [branchData, setBranchData] = useState({});

  const getCurrentSales = (items) => {
    setCurrentSales(items);
  };

  const rows = currentSales.map((item, index) => {
    return (
      <tr key={index}>
        <td>
          <Text fz="sm" fw={fontWeightNormal} color={textColor}>
            {dayjs(new Date(item?.date)).format("DD MMM YYYY HH:mm")}
          </Text>
        </td>
        <td>
          <Text fz="sm" fw={fontWeightNormal} color={textColor} align="center">
            {InvoiceFormatter(item?.invoiceNo)}
          </Text>
        </td>
        <td>
          <Text fz="md" fw={fontWeightNormal} color={textColor}>
            <Text fz="sm" fw={fontWeightNormal} color={textColor}>
              {item?.name}
            </Text>
          </Text>
        </td>
        <td>
          <Text
            fz="sm"
            fw={fontWeightNormal}
            color={textColor}
            className="text-end"
          >
            {CurrencyFormatter(item?.price)}
          </Text>
        </td>
        <td>
          <Text
            fz="sm"
            fw={fontWeightNormal}
            color={textColor}
            className="text-center"
          >
            {item?.quantity}
          </Text>
        </td>
        <td>
          <Text
            fz="sm"
            fw={fontWeightNormal}
            color={textColor}
            className="text-end"
          >
            {CurrencyFormatter(item?.subTotal)}
          </Text>
        </td>
        <td>
          <Text fz="sm" fw={fontWeightNormal} color={textColor}>
            {item?.customerName ? item?.customerName : "N/A"}
          </Text>
        </td>
        <td>
          <Text fz="sm" fw={fontWeightNormal} color={textColor}>
            {item?.seller}
          </Text>
        </td>
      </tr>
    );
  });

  return (
    <>
      <ScrollArea>
        <Table sx={{ minWidth: 400 }} verticalSpacing="xs">
          <thead>
            <tr>
              <th className={classes.category}>Date</th>
              <th className={`text-center ${classes.allTh}`}>Invoice No.</th>
              <th className={classes.allTh}>Item</th>
              <th className={`text-end ${classes.allTh}`}>
                Price<small> (GHS)</small>
              </th>
              <th className={`text-center ${classes.allTh}`}>Qty</th>
              <th className={`text-end ${classes.allTh}`}>
                Total<small> (GHS)</small>
              </th>
              {/* <th className={`text-center ${classes.allTh}`}>Date</th> */}
              <th className={classes.allTh}>Customer</th>
              <th className={classes.action}>Sold By</th>
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
      <MyPagination allItems={sales} currentItems={getCurrentSales} />
    </>
  );
}
