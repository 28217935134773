import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "./App.css";
import { Routes, Route } from "react-router-dom";
import AuthGuard from "./guards/auth_guard";
import UnauthGuard from "./guards/unauth_guard";

import Home from "./pages/index";
import Dashboard from "./pages/dashboard/dashboard";
import Products from "./pages/products/products";
import AddNewProduct from "./pages/products/add_product";
import ProductDetails from "./pages/products/product_details";
import Categories from "./pages/categories/categories";
import Branches from "./pages/branches/branches";
import NotFound from "./pages/errors/404";
import Invoice from "./pages/invoice/invoice";
import { Login } from "./pages/authentications/login";
// import { ForgotPassword } from "./pages/authentications/forgot_password";
import { Register } from "./pages/authentications/register";
import NewSales from "./pages/sales/new_sales";
import InvoiceDetails from "./pages/invoice/invoice_details";
import Customers from "./pages/customers/customers";
import PrintInvoice from "./pages/sales/components/print_invoice";
import Users from "./pages/users/users";
import AddNewUser from "./pages/users/add_user";
import UserDetails from "./pages/users/user_details";
import Reports from "./pages/reports/reports";
import AppSkeleton from "./pages/skeleton";
import PrintSalesReport from "./pages/reports/components/print_sales_report";
import PrintInventoryReport from "./pages/reports/components/print_inventory_report";
import VerifyEmail from "./pages/authentications/verify";
import Transfer from "./pages/transfer/transfer";
import ResetPassword from "./pages/authentications/reset-password";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route element={<UnauthGuard />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Route>

      <Route element={<AppSkeleton />}>
        <Route element={<AuthGuard />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/products" element={<Products />} />
          <Route path="/add-new-product" element={<AddNewProduct />} />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/branches" element={<Branches />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/users" element={<Users />} />
          <Route path="/user-details" element={<UserDetails />} />
          <Route path="/add-new-user" element={<AddNewUser />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/invoice-details" element={<InvoiceDetails />} />
          <Route path="/print-invoice" element={<PrintInvoice />} />
          <Route path="/sales" element={<NewSales />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/print-sales-report" element={<PrintSalesReport />} />
          <Route path="/transfer" element={<Transfer />} />
          <Route
            path="/print-inventory-report"
            element={<PrintInventoryReport />}
          />
        </Route>
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
