import {
  createStyles,
  Paper,
  Title,
  Text,
  TextInput,
  Button,
  Group,
  Anchor,
  Center,
  Box,
  rem,
  Divider,
  Alert,
} from "@mantine/core";
import smartStockLogoOnly from "./../../../assets/images/logo_only.png";
import { IconArrowLeft } from "@tabler/icons-react";
import ReCAPTCHA from "react-google-recaptcha";
import { useState, useRef } from "react";
import { useForm } from "@mantine/form";
import { resetPasswordAPI } from "../../../services/auth";
import showToast from "../../../components/toast";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(26),
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  controls: {
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column-reverse",
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
}));

const ForgotPasswordTab = ({ setAuthCard }) => {
  const { classes } = useStyles();

  const recaptcha = useRef();

  const [loading, setLoading] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false);

  const resetPasswordForm = useForm({
    initialValues: {
      email: "",
      captchaToken: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : " "),
      captchaToken: (value) => value === " ",
    },
  });

  const onCAPTCHAValid = () => {
    resetPasswordForm.setFieldValue(
      "captchaToken",
      recaptcha.current.getValue()
    );
  };

  const onCAPTCHAInvalid = () => {
    resetPasswordForm.setFieldValue("captchaToken", "");
  };

  const resetPassword = async (values) => {
    // console.log(values);

    try {
      setLoading(true);
      const results = await resetPasswordAPI(values);
      if (results.status) {
        setResetSuccessful(true);
      } else {
        recaptcha.current.reset();
        resetPasswordForm.setFieldValue("captchaToken", "");
      }
    } catch (error) {
      recaptcha.current.reset();
      resetPasswordForm.setFieldValue("captchaToken", "");
      showToast({
        color: "red",
        message: "An error occured. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
      <Center mb="md">
        <img
          src={smartStockLogoOnly}
          alt="smart stock"
          style={{ height: "80px" }}
        />
      </Center>

      <Title
        align="center"
        size="h2"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 700,
        })}
        //
      >
        Forgot Password?
      </Title>
      <Text color="dimmed" size="sm" align="center" mb="md">
        Enter your email to reset the password
      </Text>
      <Divider mb="md" color="gray.1" />

      <form
        onSubmit={resetPasswordForm.onSubmit((values) => resetPassword(values))}
      >
        <TextInput
          label="Your email"
          placeholder="email@domain.com"
          required
          {...resetPasswordForm.getInputProps("email")}
          disabled={loading || resetSuccessful}
        />

        <div
          className="captcha"
          style={{
            marginTop: 8,
            //   transform: "scale(0.96)",
            //   transformOrigin: "0 0",
          }}
        >
          {!resetSuccessful && (
            <ReCAPTCHA
              onChange={() => onCAPTCHAValid()}
              onExpired={() => onCAPTCHAInvalid()}
              ref={recaptcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />
          )}
          {resetSuccessful && (
            <Alert
              // icon={<IconMailCheck size="1.5rem" />}
              // title="Reset Successful"
              color="teal"
            >
              Password reset initiated successfully.
              <br />
              Check your inbox to complete the process
            </Alert>
          )}
        </div>
        <Group position="apart" mt="lg" className={classes.controls}>
          <Anchor color="teal" size="sm" className={classes.control}>
            <Center inline>
              <IconArrowLeft size={rem(12)} stroke={1.5} />
              <Box ml={5} onClick={() => setAuthCard("login")}>
                Back To Login
              </Box>
            </Center>
          </Anchor>
          <Button
            className={classes.control}
            type="submit"
            disabled={!resetPasswordForm.values.captchaToken || resetSuccessful}
            loading={loading}
          >
            <Text fz="md" fw={400}>
              {loading ? "Resetting..." : "Reset Password"}
            </Text>
          </Button>
        </Group>
      </form>
    </Paper>
  );
};

export default ForgotPasswordTab;
