import { Center, Loader, Stack, Text } from "@mantine/core";

export default function ShowLoader({ text, mt }) {
  return (
    <Center maw={400} mt={mt} mx="auto">
      <Stack align="center">
        <Loader />
        <Text size="sm" color="grey">
          {text}
        </Text>
      </Stack>
    </Center>
  );
}
