import { useMantineTheme } from "@mantine/core";
import { notifications } from "@mantine/notifications";

export default function showToast({ color, title, message }) {
  const theme = useMantineTheme;

  return notifications.show({
    title: title,
    message: message,
    color: color,
    withBorder: true,
    // bg: `${theme.colors.dark[4]}`,
    // theme.colorScheme === "dark"
    //   ? theme.colors.dark[4]
    //   : theme.colors.gray[1],
  });
}
