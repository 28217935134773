import { useState } from "react";
import {
  Grid,
  Container,
  Textarea,
  Divider,
  Group,
  Text,
  Button,
  Image,
  rem,
  Box,
  Modal,
  useMantineTheme,
  Flex,
} from "@mantine/core";

import { Dropzone } from "@mantine/dropzone";
import InputText from "../../../components/input_text";
import InputSelect from "../../../components/input_select";
import {
  IconDeviceFloppy,
  IconX,
  IconUpload,
  IconPhoto,
} from "@tabler/icons-react";

const EditCategoryModal = ({ opened, closeFunction }) => {
  const theme = useMantineTheme();
  const [files, setFiles] = useState([]);

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Box maw={100} mx="auto">
        <Image
          radius="md"
          key={index}
          src={imageUrl}
          width={100}
          height={100}
          fit="contain"
          imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
        />
      </Box>
    );
  });

  const closeModal = () => {
    closeFunction(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={false}
      size="lg"
      title="Edit Category"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <Grid>
          <Grid.Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ minHeight: rem(100) }}
          >
            <Text size="sm" fw={500}>
              Photo
            </Text>

            <Dropzone
              accept={["image/png", "image/jpeg"]}
              onDrop={setFiles}
              maxFiles={1}
              multiple={false}
            >
              {files.length <= 0 && (
                <Group
                  position="center"
                  //   spacing="xl"
                  style={{ minHeight: rem(95), pointerEvents: "none" }}
                >
                  <Dropzone.Accept>
                    <IconUpload
                      size="2.2rem"
                      stroke={1.5}
                      color={
                        theme.colors[theme.primaryColor][
                          theme.colorScheme === "dark" ? 4 : 6
                        ]
                      }
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX
                      size="2.2rem"
                      stroke={1.5}
                      color={
                        theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
                      }
                    />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <IconPhoto size="2.2rem" stroke={1.5} />
                  </Dropzone.Idle>

                  <div>
                    <Text size="sm" color="dimmed" mt={2} align="center">
                      Drag image here or click to select. Accepts PNG and JPG
                      files
                    </Text>
                  </div>
                </Group>
              )}

              {previews}
            </Dropzone>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <InputText
              label="Category Name"
              placeholder="Xbox One Controller - Black"
              required={true}
            />
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <InputSelect
              disabled={true}
              label="Branch"
              placeholder="Xbox One Controller - Black"
              required={true}
            />
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Textarea
              placeholder="Compatible with Xbox One X, Xbox One S, Xbox One, Windows 10. 
              Includes Bluetooth technology for gaming on Windows 10 PCs and tablets."
              label="Description"
              minRows={4}
              maxRows={4}
            />
          </Grid.Col>

          <Grid.Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            // mt="xl"
          >
            {" "}
            {/* <Divider mb="sm" /> */}
            <Flex
              gap="md"
              justify="flex-end"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Button
                variant="outline"
                leftIcon={<IconX size="1rem" />}
                color="red"
                onClick={closeModal}
                px="xl"
              >
                Cancel
              </Button>
              <Button
                leftIcon={<IconDeviceFloppy size="1rem" />}
                color="teal"
                // size="md"
                px="xl"
              >
                Save
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};

export default EditCategoryModal;
