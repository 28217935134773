import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux_slices/auth";
import showToast from "../components/toast";
import { useSelector } from "react-redux";

const API_URL = process.env.REACT_APP_API_URL;

export function useProductsAPI() {
  const token = useSelector((state) => state.auth.auth.token);
  const branchId = useSelector((state) => state.branch.branch.activeId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getData = async () => {
    return await axios
      .get(`${API_URL}/list-products?branchId=${branchId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "Error",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "Unknown Error",
            message: "An error occured. Please try again.",
          });
          // return err;
          return {
            status: false,
            message: "Could not load product data. Please try again.",
          };
        }
      });
  };

  const addData = async (data) => {
    return await axios
      .post(`${API_URL}/add-new-product/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: err.message,
            // message: "Could not save product data. Please try again.",
          });
          return {
            status: false,
            message: "Could not save product data. Please try again.",
          };
        }
      });
  };

  const getDetails = async ({ id, sku }) => {
    return await axios
      .get(`${API_URL}/product-details?id=${id}&sku=${sku}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "Error",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not load product data. Please try again.",
          });
          return {
            status: false,
            message: "Could not load product data. Please try again.",
          };
        }
      });
  };

  const updatePrice = async (data) => {
    return await axios
      .post(
        `${API_URL}/update-price/`,
        {
          newPrice: data.newPrice,
          id: data.id,
          sku: data.sku,
          branchId: branchId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not update product price. Please try again.",
          });
          return {
            status: false,
            message: "Could not update product price. Please try again.",
          };
        }
      });
  };

  const updateStock = async (data) => {
    return await axios
      .post(
        `${API_URL}/update-stock/`,
        {
          quantity: data.quantity,
          id: data.id,
          sku: data.sku,
          branchId: branchId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not update stock. Please try again.",
          });
          return {
            status: false,
            message: "Could not update stock. Please try again.",
          };
        }
      });
  };

  const updateDetails = async (data) => {
    return await axios
      .post(
        `${API_URL}/update-product/`,
        {
          name: data.name,
          image: data.image,
          categoryId: data.categoryId,
          description: data.description,
          id: data.id,
          sku: data.sku,
          branchId: branchId,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not update product. Please try again.",
          });
          return {
            status: false,
            message: "Could not update product. Please try again.",
          };
        }
      });
  };

  const updateNotification = async (data) => {
    return await axios
      .post(
        `${API_URL}/update-product-notification/`,
        {
          available: data.available,
          alert: data.alert,
          alertThreshold: data.alertThreshold,
          id: data.id,
          sku: data.sku,
          branchId: branchId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not update product. Please try again.",
          });
          return {
            status: false,
            message: "Could not update product. Please try again.",
          };
        }
      });
  };

  return {
    getData,
    addData,
    getDetails,
    updatePrice,
    updateStock,
    updateDetails,
    updateNotification,
  };
}
