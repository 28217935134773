import { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Container,
  Table,
  Divider,
  Group,
  useMantineTheme,
  Button,
  Text,
  Select,
} from "@mantine/core";
import { IconShoppingCartPlus } from "@tabler/icons-react";
import ProductSearch from "./product_search";
import { NewSalesListTable } from "./new_sales_list_table";
import CurrencyFormatter from "../../../utils/currency_formatter";
import showToast from "../../../components/toast";
import ConfirmSalesModal from "./confirm_sales_modal";
import LoadingModal from "./loading_modal";
import ContinueSalesModal from "./continue_sales_modal";
import { useSalesAPI } from "../../../services/sales";
import { useNavigate } from "react-router-dom";
import CustomerSelect from "./customer_select";

const NewSalesForm = () => {
  const { completeSale } = useSalesAPI();
  const theme = useMantineTheme();
  const [products, setProducts] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [openConfirmSalesModal, setOpenConfirmSalesModal] = useState(false);
  const [openContinueSalesModal, setOpenContinueSalesModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const addProduct = (product) => {
    setProducts((current) => [...current, product]);
  };

  const toggleRemoveModal = () => {
    setOpenConfirmSalesModal(false);
  };

  const completeSales = async () => {
    try {
      setIsLoading(true);
      setOpenConfirmSalesModal(false);

      const results = await completeSale({
        customerId: customerId,
        products: products,
      });
      if (results.status) {
        setProducts([]);
        setOpenConfirmSalesModal(false);
        setOpenContinueSalesModal(true);
        // getProductsData();
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const addCustomer = (value) => {
    setCustomerId(value);
  };

  const toggleContinueModal = () => {
    setOpenContinueSalesModal(false);
  };

  const cancelContinueSales = () => {
    navigate("/sales");
  };

  const continueSales = () => {
    setOpenContinueSalesModal(false);
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [products]);

  const calculateTotalPrice = () => {
    let total = 0;
    for (let index = 0; index < products.length; index++) {
      total = total + products[index].price * products[index].quantity;
    }
    setTotalPrice(total);
  };

  const increaseProductQty = (id) => {
    let newProducts = products.map((product) => {
      if (product.id === id) {
        if (product.quantity < product.qty_left) {
          return { ...product, ["quantity"]: product.quantity + 1 };
        } else {
          showToast({
            color: "yellow",
            message: `Only ${product.qty_left} ${
              product.qty_left > 1 ? "is" : "is"
            } available`,
          });
          return product;
        }
      } else {
        return product;
      }
    });
    setProducts(newProducts);
  };

  const decreaseProductQty = (id) => {
    let newProducts = products.map((product) => {
      if (product.id === id) {
        if (product.quantity > 1) {
          return { ...product, ["quantity"]: product.quantity - 1 };
        } else {
          showToast({
            color: "yellow",
            message: "Minimum quantity is 1",
          });
          return product;
        }
      } else {
        return product;
      }
    });
    setProducts(newProducts);
  };

  const removeProduct = (id) => {
    setProducts((current) =>
      current.filter((product) => {
        // 👇️ remove object that has id equal to 2
        return product.id !== id;
      })
    );
  };

  return (
    <Card withBorder shadow={1} radius="md" mih={450}>
      <Container fluid={true}>
        <Grid>
          <Grid.Col xs={12} sm={4} md={4} lg={4} xl={4}>
            <CustomerSelect setChangeValue={addCustomer} />
          </Grid.Col>
          <Grid.Col xs={12} sm={8} md={8} lg={8} xl={8}>
            <ProductSearch addProductFunction={addProduct} />
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <NewSalesListTable
              products={products}
              deleteFunction={removeProduct}
              increaseQty={increaseProductQty}
              decreaseQty={decreaseProductQty}
            />
          </Grid.Col>

          {products.length > 0 && (
            <>
              <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider mb="xs" mt={0} pt={0} />
              </Grid.Col>
              <Grid.Col xs={12} sm={9} md={9} lg={10} xl={10}>
                <Table verticalSpacing="sm" withBorder withColumnBorders>
                  <tbody>
                    <tr>
                      <td
                        className="fw-bold fs-6 text-dimmed"
                        width="40%"
                        style={{
                          backgroundColor:
                            theme.colorScheme === "dark"
                              ? theme.colors.dark[4]
                              : theme.colors.gray[1],
                        }}
                      >
                        Grand Total
                      </td>
                      <td className="text-end fw-bold fs-6" width="60%">
                        <Text color="teal">
                          GHS {CurrencyFormatter(totalPrice)}
                        </Text>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Grid.Col>
              <Grid.Col xs={12} sm={3} md={3} lg={2} xl={2}>
                <Group position="right">
                  <Button
                    leftIcon={<IconShoppingCartPlus size="1rem" />}
                    color={theme.primaryColor}
                    size="md"
                    px="xl"
                    onClick={() => setOpenConfirmSalesModal(true)}
                  >
                    Complete
                  </Button>
                </Group>
              </Grid.Col>
            </>
          )}
        </Grid>

        <ConfirmSalesModal
          opened={openConfirmSalesModal}
          closeFunction={toggleRemoveModal}
          operationFn={completeSales}
        />

        <LoadingModal opened={isLoading} />

        <ContinueSalesModal
          opened={openContinueSalesModal}
          closeFunction={continueSales}
          operationFn={cancelContinueSales}
        />
      </Container>
    </Card>
  );
};

export default NewSalesForm;
