import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux_slices/auth";
import showToast from "../components/toast";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const API_URL = process.env.REACT_APP_API_URL;

export function useSalesAPI() {
  const token = useSelector((state) => state.auth.auth.token);
  const branchId = useSelector((state) => state.branch.branch.activeId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getSales = async (data) => {
    console.log("data", data);
    // console.log("sellerId", sellerId);
    let url = `${API_URL}/list-sales?branchId=${branchId}`;

    // Add date filter if not null
    if (data?.date) {
      url = `${url}&date=${dayjs(new Date(data?.date)).format("YYYY-MM-DD")}`;
    }

    // Add sellerId filter if not null
    if (data?.sellerId) {
      url = `${url}&sellerId=${data?.sellerId}`;
    }

    return await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "Error",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "Unknown Error",
            message: "An error occured. Please try again.",
          });
          return {
            status: false,
            message: "Could not load sales data. Please try again.",
          };
        }
      });
  };

  const searchSales = async ({ searchString }) => {
    return await axios
      .post(
        `${API_URL}/search-sales-product/`,
        { searchString: searchString, branchId: branchId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not get product data. Please try again.",
          });
          return {
            status: false,
            message: "Could not get product data. Please try again.",
          };
        }
      });
  };

  const completeSale = async ({ products, customerId }) => {
    return await axios
      .post(
        `${API_URL}/complete-sales/`,
        { products: products, customerId: customerId, branchId: branchId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not complete the sales. Please try again.",
          });
          return {
            status: false,
            message: "Could not get product data. Please try again.",
          };
        }
      });
  };

  const saleDetails = async ({ id, invoice }) => {
    return await axios
      .get(`${API_URL}/sale-details?id=${id}&invoice=${invoice}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "Error",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not load sale data. Please try again.",
          });
          return {
            status: false,
            message: "Could not load sale data. Please try again.",
          };
        }
      });
  };

  return {
    getSales,
    searchSales,
    completeSale,
    saleDetails,
  };
}
