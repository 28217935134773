import { useSelector, useDispatch } from "react-redux";
import {
  Select,
  Group,
  Text,
  useMantineTheme,
  Divider,
  Stack,
  Title,
} from "@mantine/core";
import { setActiveBranchId } from "../redux_slices/branch";
import { useEffect, useRef } from "react";

const Branch = ({
  pageTitle,
  pageSubTitle,
  disableBranchSelector,
  hideBranchSelector,
  onChangeFn,
  component,
  componentFn,
}) => {
  const count = useRef(0);

  const dispatch = useDispatch();
  const branchData = useSelector((state) => state.branch.branch.all || []);
  const activeBranchId = useSelector((state) => state.branch.branch.activeId);
  const theme = useMantineTheme();

  const formatData = (data) => {
    let branchesList = [];

    data.map((branch) => {
      branchesList.push({ value: branch.id, label: branch.name });
    });
    return branchesList;
  };

  useEffect(() => {
    if (count.current === 0) {
      // Do not run onchange event on first change (As select component loads)
      count.current = 1;
      console.log("On Branch Fisrt Load");
    } else {
      console.log("Branch Reloaded State");
      if (onChangeFn) {
        onChangeFn();
      }
    }
  }, [activeBranchId]);

  return (
    <>
      <Group position="apart" spacing="xs" pb="md">
        <Stack spacing={4}>
          <Title
            m={0}
            p={0}
            order={3}
            color={
              theme.colorScheme === "dark"
                ? theme.colors.gray[5]
                : theme.colors.dark[9]
            }
          >
            {pageTitle}
          </Title>
          <Text
            m={0}
            p={0}
            color={
              theme.colorScheme === "dark"
                ? theme.colors.gray[5]
                : theme.colors.dark[9]
            }
          >
            {pageSubTitle}
          </Text>
        </Stack>

        {!hideBranchSelector && (
          <Select
            disabled={disableBranchSelector}
            label="Active Branch"
            placeholder="Select Branch"
            value={activeBranchId}
            onChange={(value) => dispatch(setActiveBranchId(value))}
            data={formatData(branchData)}
          />
        )}
        {component ?? null}
      </Group>
      <Divider pb="xs" />
    </>
  );
};

export default Branch;
