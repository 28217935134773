import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import {
  Card,
  Text,
  Paper,
  Group,
  ActionIcon,
  useMantineTheme,
} from "@mantine/core";
// import { IconDotsVertical } from "@tabler/icons-react";
import Chart from "react-apexcharts";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DashChart({ inStock, lowOnStock, outOfStock }) {
  const theme = useMantineTheme();

  return (
    <Paper shadow="xl" radius="md" p="xl">
      <Group justify="space-between" mb="md">
        <Text size="lg" fw={500}>
          Stock Stats View
        </Text>
      </Group>
      <Chart
        options={{
          // chart: { fontFamily: "Open Sans, sans-serif" },
          // states: {
          // hover: { filter: { type: "lighten", value: 0.9 } },
          // active: { filter: { type: "none", value: 0 } },
          // },
          stroke: { width: 0 },
          plotOptions: {
            pie: {
              donut: {
                size: "75%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "12px",
                    fontWeight: "400",
                    color:
                      theme.colorScheme === "dark"
                        ? theme.white
                        : theme.colors.dark[6],
                  },
                  value: {
                    show: true,
                    fontSize: "22px",
                    fontWeight: "600",
                    color:
                      theme.colorScheme === "dark"
                        ? theme.white
                        : theme.colors.dark[6],
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    color:
                      theme.colorScheme === "dark"
                        ? theme.white
                        : theme.colors.dark[6],
                  },
                },
                // labels: {
                //   show: true,
                //   total: {
                //     showAlways: true,
                //     show: true,
                //   },
                // },
              },
            },
          },
          labels: ["In Stock", "Low Stock", "Out Of Stock"],
          colors: [
            theme.colors[theme.primaryColor][9],
            theme.colors[theme.primaryColor][5],
            theme.colors[theme.primaryColor][2],
            // theme.colors[theme.primaryColor][2],
          ],
        }}
        series={[inStock, lowOnStock, outOfStock]}
        // series={[127, 13, 7]} //Static numbers for test
        type="donut"
        height={"100%"}
        width={"100%"}
      />
      {/* {salesError ? (
      <ErrorAlert
        title="Error loading sales data"
        message={salesError.toString()}
      />
    ) : (
      <DataTable
        highlightOnHover
        columns={[
          { accessor: 'source' },
          { accessor: 'revenue' },
          { accessor: 'value' },
        ]}
        records={salesData.slice(0, 4)}
        height={200}
        fetching={salesLoading}
      />
    )} */}
    </Paper>
  );
}

// <Card withBorder shadow={1} radius="md" style={{ maxHeight: "410px" }}>
//   <Card.Section withBorder inheritPadding py="xs">
//     <Text weight={500}>Chart View</Text>
//   </Card.Section>
//   <Pie data={data} height={410} width={400} />
// </Card>
