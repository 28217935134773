import { useState } from "react";
import {
  createStyles,
  rem,
  Avatar,
  Table,
  Group,
  Text,
  ActionIcon,
  ScrollArea,
  Badge,
} from "@mantine/core";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import EditCategoryModal from "./edit_category_modal";
import DeleteCategoryModal from "./delete_category_modal";
import getAvatarName from "../../../utils/get_avatar_name";
import MyPagination from "../../../components/pagination";

const CATEGORY_IMAGE_URL = process.env.REACT_APP_CATEGORY_IMAGE_URL;

const useStyles = createStyles((theme) => ({
  category: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopLeftRadius: theme.spacing.xs,
    borderBottomLeftRadius: theme.spacing.xs,
  },

  action: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
    borderTopRightRadius: theme.spacing.xs,
    borderBottomRightRadius: theme.spacing.xs,
    textAlign: "right",
  },

  allTh: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
  },

  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));

export function CategoriesListTable({
  categories,
  updateCategoryFn,
  deleteCategoryFn,
  isModalLoading,
}) {
  const { classes, theme } = useStyles();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [catData, setCatData] = useState({});
  const [currentCategories, setCurrentCategories] = useState([]);

  const getCurrentCategories = (items) => {
    setCurrentCategories(items);
  };

  const rows = currentCategories.map((item, index) => (
    <tr key={index}>
      <td>
        <Group spacing="sm">
          <Avatar
            size={40}
            src={item?.image ? `${CATEGORY_IMAGE_URL}/${item.image}` : null}
            radius="sm"
            alt={item?.name}
            color={theme.primaryColor}
          >
            {getAvatarName(item?.name)}
          </Avatar>

          <Text fz="sm" fw={500}>
            {item?.name}
          </Text>
        </Group>
      </td>
      <td>
        <Text fz="sm" color="dimmed">
          {item?.description}
        </Text>
      </td>

      <td>
        <Text fz="sm">
          <Badge>{item?.items} </Badge>
        </Text>
      </td>

      <td>
        <Group spacing={0} position="right">
          <ActionIcon
            color={theme.primaryColor}
            onClick={() => {
              setCatData(item);
              toggleEditModal(true);
            }}
          >
            <IconPencil size="1.22rem" stroke={2} />
          </ActionIcon>
          <ActionIcon
            color="red"
            onClick={() => {
              setCatData(item);
              toggleDeleteModal(true);
            }}
          >
            <IconTrash size="1.22rem" stroke={2} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  const toggleEditModal = (value) => {
    setOpenEditModal(value);
  };
  const toggleDeleteModal = (value) => {
    setOpenDeleteModal(value);
  };

  return (
    <>
      <EditCategoryModal
        category={catData}
        opened={openEditModal}
        closeFunction={toggleEditModal}
        operationFn={updateCategoryFn}
      />
      <DeleteCategoryModal
        catData={catData}
        opened={openDeleteModal}
        closeFunction={toggleDeleteModal}
        operationFn={deleteCategoryFn}
      />

      <ScrollArea>
        <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
          <thead>
            <tr>
              <th className={classes.category} style={{ borderBottom: "none" }}>
                Name
              </th>
              <th className={classes.allTh} style={{ borderBottom: "none" }}>
                Description
              </th>
              <th className={classes.allTh} style={{ borderBottom: "none" }}>
                Items
              </th>
              <th className={classes.action} style={{ borderBottom: "none" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
      <MyPagination allItems={categories} currentItems={getCurrentCategories} />
    </>
  );
}
