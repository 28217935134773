import { useState, useEffect } from "react";
import { Loader, Select, ThemeIcon, Tooltip } from "@mantine/core";
import AddCustomerModal from "../../customers/components/add_branch_modal";
import { useCustomersAPI } from "../../../services/customers";
import { IconPlus } from "@tabler/icons-react";

export default function CustomerSelect({ setChangeValue }) {
  const { getCus, addCus } = useCustomersAPI();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [customersData, setCustomersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCustomersData = async () => {
    try {
      setIsLoading(true);

      const results = await getCus();
      if (results.status) {
        console.log("results", results);
        setCustomersData(formatData(results.customers));
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const addCustomer = async ({ name, phone, address }) => {
    try {
      setIsLoading(true);

      const results = await addCus({
        name: name,
        phone: phone,
        address: address,
      });
      if (results.status) {
        closeNewCustomerModel();
        getCustomersData();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const valueChange = (val) => {
    setChangeValue(val);
  };

  const addNewCustomer = () => {
    setOpenAddModal(true);
  };

  const closeNewCustomerModel = () => {
    setOpenAddModal(false);
  };

  const formatData = (data) => {
    let customerList = [];

    data.map((customer) => {
      customerList.push({ value: customer.id, label: customer.name });
    });
    return customerList;
  };

  useEffect(() => {
    getCustomersData();
  }, []);

  return (
    <>
      <AddCustomerModal
        opened={openAddModal}
        closeFunction={closeNewCustomerModel}
        operationFn={addCustomer}
      />
      <Select
        label="Customer"
        placeholder={
          isLoading ? "Loading Customers..." : "Search Customer Name"
        }
        readOnly={isLoading}
        clearable={true}
        searchable
        limit={5}
        getCreateLabel={(query) => `Add New Customer`}
        data={customersData}
        rightSection={
          isLoading ? (
            <Loader size="sm" />
          ) : (
            <Tooltip label="Add New Customer">
              <ThemeIcon
                onClick={() => addNewCustomer()}
                style={{ cursor: "pointer" }}
              >
                <IconPlus />
              </ThemeIcon>
            </Tooltip>
          )
        }
        styles={(theme) => ({
          input: {
            "&:focus-within": {
              borderColor: theme.colors[theme.primaryColor][5],
            },
          },
        })}
        onChange={
          (val) => valueChange(val)
          // if (changeFunction) {
          //   changeFunction(val);
          // }
        }
      />
    </>
  );
}
