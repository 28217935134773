import { useState } from "react";
import { useSelector } from "react-redux";
import {
  createStyles,
  Navbar,
  getStylesRef,
  rem,
  useMantineTheme,
  Group,
  Avatar,
  Center,
  Text,
} from "@mantine/core";
import {
  IconShoppingBag,
  IconUsers,
  IconBuildingStore,
  IconLogout,
  IconCategory,
  IconReportAnalytics,
  IconBoxMultiple2,
  IconApps,
  IconHeartHandshake,
  IconArrowsLeftRight,
  IconReceipt,
} from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import getAvatarName from "../utils/get_avatar_name";
import LogoutModal from "./logout_modal";
import { ThemeColourTextOnPrimaryBg } from "../utils/theme_colour";

const COMPANY_IMAGE_URL = process.env.REACT_APP_COMPANY_IMAGE_URL;

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  footer: {
    paddingTop: theme.spacing.sm,
    marginTop: theme.spacing.sm,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.white : theme.colors.gray[2]
    }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === "dark" ? theme.white : theme.white,
    padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
    borderRadius: theme.radius.sm,
    fontWeight: 600,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,

      [`& .${getStylesRef("icon")}`]: {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color: theme.colorScheme === "dark" ? theme.white : theme.white,
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.black
          : theme.fn.variant({
              variant: "light",
              color: theme.primaryColor,
            }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
      [`& .${getStylesRef("icon")}`]: {
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
      },
    },
  },
}));

export default function SideNavbar({ activePage, closeBurger }) {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const [active, setActive] = useState(activePage || "Dashboard");
  const userData = useSelector((state) => state.auth.auth.userData);
  const [openLogout, setOpenLogout] = useState(false);

  // console.log("userData", userData); // Will Come Back

  let data = [];

  if (userData?.type === "admin") {
    data = [
      { link: "/dashboard", label: "Dashboard", icon: IconApps },
      { link: "/products", label: "Products", icon: IconBoxMultiple2 },
      { link: "/categories", label: "Categories", icon: IconCategory }, //Authorization Guard
      { link: "/sales", label: "Sales", icon: IconShoppingBag },
      { link: "/invoice", label: "Invoice", icon: IconReceipt },
      { link: "/customers", label: "Customers", icon: IconHeartHandshake },
      { link: "/branches", label: "Branches", icon: IconBuildingStore }, //Authorization Guard
      { link: "/reports", label: "Reports", icon: IconReportAnalytics }, //Authorization Guard
      // { link: "/suppliers", label: "Suppliers", icon: IconTruck },
      { link: "/transfer", label: "Transfer", icon: IconArrowsLeftRight }, //Authorization Guard
      { link: "/users", label: "Users", icon: IconUsers }, //Authorization Guard
    ];
  } else if (userData?.type === "sales") {
    data = [
      { link: "/dashboard", label: "Dashboard", icon: IconApps },
      { link: "/sales", label: "Sales", icon: IconShoppingBag },
      { link: "/invoice", label: "Invoice", icon: IconReceipt },
      { link: "/products", label: "Products", icon: IconBoxMultiple2 },
      // { link: "/categories", label: "Categories", icon: IconCategory },

      { link: "/customers", label: "Customers", icon: IconHeartHandshake },
      // { link: "/branches", label: "Branches", icon: IconBuildingStore },
      // { link: "/reports", label: "Reports", icon: IconReportAnalytics },
      // { link: "/suppliers", label: "Suppliers", icon: IconTruck },
      // { link: "/transfer", label: "Transfer", icon: IconArrowsLeftRight },
      // { link: "/users", label: "Users", icon: IconUsers },
    ];
  } else {
    data = [];
  }

  const toggleLogout = () => {
    setOpenLogout((value) => !value);
  };

  const navLinkClicked = (item) => {
    setActive(item.label);
    closeBurger();
  };

  const links = data.map((item) => (
    <NavLink
      key={item.label}
      to={item.link}
      onClick={() => navLinkClicked(item)}
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
    >
      <item.icon className={classes.linkIcon} size="1.5rem" stroke={1.8} />
      <span>{item.label}</span>
    </NavLink>
  ));

  return (
    <Navbar
      height="100%"
      width={{ sm: 160, md: 180, lg: 300 }}
      p="md"
      // style={{ backgroundColor: theme.colors[theme.primaryColor][5] }}
      style={{
        backgroundColor:
          theme.colorScheme === "dark"
            ? // theme.colors[theme.primaryColor][3]
              theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.6)
            : theme.colors[theme.primaryColor][9],
      }}
    >
      <LogoutModal opened={openLogout} closeFunction={toggleLogout} />
      <Navbar.Section grow>
        <Group className={classes.header}>
          <Avatar
            size={36}
            src={
              userData?.logo ? `${COMPANY_IMAGE_URL}/${userData?.logo}` : null
            }
            radius="sm"
            color={theme.primaryColor}
          >
            {getAvatarName(userData?.comName)}
          </Avatar>

          <Center className={classes.logo}>
            <Text
              ml="sm"
              fz="md"
              fw={600}
              color={ThemeColourTextOnPrimaryBg()}

              // {
              //   // theme.colorScheme === "dark"
              //   //   ?
              //   theme.colors.white
              //   // : theme.colors.dark[9]
              // }
            >
              {userData?.comName}
            </Text>
          </Center>
        </Group>
        {links}
      </Navbar.Section>

      <Navbar.Section className={classes.footer} mb={50}>
        <a
          href="#"
          className={classes.link}
          onClick={(event) => {
            event.preventDefault();
            toggleLogout();
          }}
        >
          <IconLogout className={classes.linkIcon} size="1.5rem" stroke={1.8} />
          <span>Logout</span>
        </a>
      </Navbar.Section>
    </Navbar>
  );
}
