import { useMantineTheme } from "@mantine/core";

const ThemeColourBg = () => {
  const theme = useMantineTheme();

  const bg =
    theme.colorScheme === "dark"
      ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.3)
      : theme.colors[theme.primaryColor][1];

  return bg;
};

const ThemeColourTableHeadText = () => {
  const theme = useMantineTheme();

  const color = theme.fn.variant({
    variant: "light",
    color: theme.primaryColor[4],
  }).color;

  // theme.colorScheme === "dark"
  //   ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.3)
  //   : theme.colors[theme.primaryColor][1];

  return color;
};

const ThemeColourEmptyList = () => {
  const theme = useMantineTheme();

  const color =
    theme.colorScheme === "dark"
      ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.3)
      : theme.colors.gray[5];

  return color;
};

const ThemeColourTextOnPrimaryBg = () => {
  const theme = useMantineTheme();
  let color = "white";
  // const whiteTextFroColourList = ["red"]; // These background colours will produce a text colour of white

  //   console.log(theme.primaryColor);

  return color;
};

export {
  ThemeColourBg,
  ThemeColourTableHeadText,
  ThemeColourEmptyList,
  ThemeColourTextOnPrimaryBg,
};
