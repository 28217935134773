import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StatsCard } from "./components/cards";
import { TobSellingProducts } from "./components/top_selling_products";
import DashChart from "./components/chart";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import { useDashboardAPI } from "../../services/dashboard";
import { setActiveBranchId, setBranches } from "./../../redux_slices/branch";
import ShowLoader from "../../components/loader";

export default function Dashboard() {
  document.title = "Dashboard || Smart Stock";
  const dispatch = useDispatch();
  const { getData } = useDashboardAPI();

  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const branchActiveId = useSelector((state) => state.branch.branch.activeId);
  // const userName = useSelector((state) => state.auth.auth.userData?.firstName); //const userData = useSelector((state) => state.auth.auth.userData);

  const setBranchForFirstRun = (branchId) => {
    if (branchActiveId <= 0) {
      dispatch(setActiveBranchId(branchId));
    }
  };

  // const getDashboardData = async () => {
  //   try {
  //     setIsLoading(true);

  //     const results = await DashboardDataAPI({ token: token });
  //     if (results.status) {
  //       console.log("results", results);
  //       dispatch(setBranches(results.branches)); //Storing all the branches data in the redux state manager
  //       setBranchForFirstRun(results.branches[0].id); //Storing the active branche id in the redux state manager
  //       setDashboardData(results);
  //     }
  //   } catch (error) {
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const getItemsQuantitySold = (itemsSold) => {
    let totalItemsQty = 0;

    itemsSold?.map((product) => {
      totalItemsQty = totalItemsQty + product?.quantity;
    });
    return totalItemsQty;
  };

  const getDashboardData = async () => {
    try {
      setIsLoading(true);

      const results = await getData();
      if (results.status) {
        console.log("getDashboardData => ", results);
        dispatch(setBranches(results.branches)); //Storing all the branches data in the redux state manager
        setBranchForFirstRun(results.branches[0].id); //Storing the active branche id in the redux state manager
        setDashboardData(results);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch
            pageTitle="Dashboard"
            //pageSubTitle={`Hello, ${userName}`}
          />
        </Grid.Col>
        {isLoading && <ShowLoader text="Loading Data..." mt={150} />}
        {!isLoading && (
          <>
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <StatsCard
                itemsSold={getItemsQuantitySold(
                  dashboardData?.itemsSold?.data ?? []
                )}
                totalValue={dashboardData?.itemsSold?.totalValue ?? 0}
                LowOnStock={dashboardData?.lowOnStock?.data?.length ?? 0}
                outOfStock={dashboardData?.outOfStock?.data?.length ?? 0}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={7} lg={7} xl={7}>
              <TobSellingProducts
                topSellingProducts={dashboardData?.topSelling}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={5} lg={5} xl={5}>
              <DashChart
                inStock={dashboardData?.inStock?.value ?? 0}
                lowOnStock={dashboardData?.lowOnStock?.data?.length ?? 0}
                outOfStock={dashboardData?.outOfStock?.data?.length ?? 0}
              />
            </Grid.Col>
          </>
        )}
      </Grid>
    </Container>
  );
}
