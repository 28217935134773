import {
  Grid,
  Container,
  Text,
  Button,
  Modal,
  useMantineTheme,
  Flex,
} from "@mantine/core";

import { IconTrash, IconX } from "@tabler/icons-react";

const DeleteCategoryModal = ({
  catData,
  opened,
  closeFunction,
  operationFn,
  isModalLoading,
}) => {
  const theme = useMantineTheme();

  const closeModal = () => {
    closeFunction(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size="lg"
      title="Delete Category?"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <Grid>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Text fw={400}>
              Do you want to delete
              <Text span fw="bold" inherit>
                {` ${catData.name} `}
              </Text>
              category? Products in this category will not be affected.
            </Text>
          </Grid.Col>

          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Flex
              gap="md"
              justify="flex-end"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Button
                variant="outline"
                leftIcon={<IconX size="1rem" />}
                color="teal"
                onClick={closeModal}
                px="xl"
              >
                Cancel
              </Button>
              <Button
                variant="outline"
                leftIcon={<IconTrash size="1rem" />}
                color="red"
                // size="md"
                px="xl"
                onClick={() => operationFn({ id: catData?.id })}
                loading={isModalLoading}
              >
                {isModalLoading ? "Deleing..." : "Delete"}
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};

export default DeleteCategoryModal;
