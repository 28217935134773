import axios from "axios";
import { useSelector } from "react-redux";
import showToast from "../components/toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux_slices/auth";
// import useTokenExpiredService from "./token_expired";

const API_URL = process.env.REACT_APP_API_URL;
const DASHBOARD_DATA = `${API_URL}/dashboard`;

export function useDashboardAPI() {
  const token = useSelector((state) => state.auth.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getData = async () => {
    return await axios
      .get(DASHBOARD_DATA, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "Error",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        console.log("err", err);
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "Unknown Error",
            message: "An error occured. Please try again.",
          });
          // return err;
          return {
            status: false,
            message: "Could not load dashboard data. Please try again.",
          };
        }
      });
  };

  return { getData };
}
