import { useState, useEffect } from "react";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import CategoriesTable from "./components/categories_table";
import { useCategoriesAPI } from "../../services/categories";
import ShowLoader from "../../components/loader";

export default function Categories() {
  document.title = "Categories || Smart Stock";
  const { getData, addData, updateData, deleteData } = useCategoriesAPI();

  const [categoriesData, setCategoriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setModalIsLoading] = useState(false);

  const getCategoriesData = async () => {
    try {
      setIsLoading(true);

      const results = await getData();
      if (results.status) {
        setCategoriesData(results.categories);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const addCategory = async ({ name, image, description }) => {
    try {
      setModalIsLoading(true);

      const results = await addData({
        name: name,
        image: image,
        description: description,
      });
      if (results.status) {
        getCategoriesData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  const updateCategory = async ({ id, name, image, description }) => {
    try {
      setModalIsLoading(true);

      const results = await updateData({
        name: name,
        image: image,
        description: description,
        id: id,
      });
      if (results.status) {
        getCategoriesData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  const deleteCategory = async ({ id }) => {
    try {
      setModalIsLoading(true);

      const results = await deleteData({
        id: id,
      });
      if (results.status) {
        getCategoriesData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesData();
  }, []);

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch
            pageTitle="Categories"
            onChangeFn={getCategoriesData}
            disableBranchSelector={isLoading}
          />
        </Grid.Col>
        {isLoading && <ShowLoader text="Loading Data..." mt={150} />}
        {!isLoading && (
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <CategoriesTable
              categories={categoriesData}
              isModalLoading={isModalLoading}
              addCategoryFn={addCategory}
              updateCategoryFn={updateCategory}
              deleteCategoryFn={deleteCategory}
            />
          </Grid.Col>
        )}
      </Grid>
    </Container>
  );
}
