import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import NewSalesForm from "./components/new_sales_form";

export default function NewSales() {
  document.title = "New Sales || Smart Stock";

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch pageTitle="New Sales" />
        </Grid.Col>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <NewSalesForm />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
