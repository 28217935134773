import { useState, useEffect } from "react";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import ProductsTable from "./components/products_table";
import { useProductsAPI } from "../../services/products";
import ShowLoader from "../../components/loader";

export default function Products() {
  document.title = "Products || Smart Stock";
  const { getData, updatePrice, updateStock, updateDetails } = useProductsAPI();

  const [productsData, setProductsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setModalIsLoading] = useState(false);

  const getProductsData = async () => {
    try {
      setIsLoading(true);

      const results = await getData();
      if (results.status) {
        // console.log("results", results);
        setProductsData(results.products);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const updateProductPrice = async ({ id, sku, newPrice }) => {
    try {
      setModalIsLoading(true);

      const results = await updatePrice({
        newPrice: newPrice,
        id: id,
        sku: sku,
      });
      if (results.status) {
        getProductsData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  const updateProductStock = async ({ id, sku, additionalQty }) => {
    try {
      setModalIsLoading(true);

      const results = await updateStock({
        quantity: additionalQty,
        id: id,
        sku: sku,
      });
      if (results.status) {
        getProductsData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  const editProduct = async ({
    id,
    sku,
    name,
    image,
    categoryId,
    description,
  }) => {
    try {
      setModalIsLoading(true);

      const results = await updateDetails({
        name: name,
        image: image,
        categoryId: categoryId,
        description: description,
        id: id,
        sku: sku,
      });
      if (results.status) {
        getProductsData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  useEffect(() => {
    getProductsData();
  }, []);

  return (
    // <Container pb="xl" fluid={true}>
    <Grid>
      <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Branch
          pageTitle="Products"
          onChangeFn={getProductsData}
          disableBranchSelector={isLoading}
        />
      </Grid.Col>
      {isLoading && <ShowLoader text="Loading Products..." mt={150} />}
      {!isLoading && (
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ProductsTable
            products={productsData}
            modalLoading={isModalLoading}
            priceModalFn={updateProductPrice}
            restockModalFn={updateProductStock}
            editModalFn={editProduct}
          />
        </Grid.Col>
      )}
    </Grid>
    // </Container>
  );
}
