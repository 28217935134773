import {
  createStyles,
  rem,
  Table,
  ScrollArea,
  Group,
  Avatar,
  Text,
  Card,
  useMantineTheme,
  Paper,
} from "@mantine/core";
import getAvatarName from "../../../utils/get_avatar_name";
import {
  ThemeColourTableHeadText,
  themeColourTableHeadText,
} from "../../../utils/theme_colour";
// import ThemeColourBg from "../../../utils/theme_colour";

// const themeBg = ThemeColourBg;

const PRODUCT_IMAGE_URL = process.env.REACT_APP_PRODUCT_IMAGE_URL;

const useStyles = createStyles((theme) => ({
  rowSelected: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors[theme.primaryColor],
  },

  name: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    // background:
    //   theme.colorScheme === "dark"
    //     ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2) //theme.colors[theme.primaryColor][3] //theme.colors.dark[4]
    //     : theme.colors[theme.primaryColor][1], // theme.colors.gray[1],
    borderTopLeftRadius: theme.spacing.xs,
    borderBottomLeftRadius: theme.spacing.xs,
  },

  qty: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    // background:
    //   theme.colorScheme === "dark"
    //     ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2) //theme.colors[theme.primaryColor][3] //theme.colors.dark[4]
    //     : theme.colors[theme.primaryColor][1], // theme.colors.gray[1],
    borderTopRightRadius: theme.spacing.xs,
    borderBottomRightRadius: theme.spacing.xs,
    textAlign: "right",
  },

  allTh: {
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    // background:
    //   theme.colorScheme === "dark"
    //     ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2) //theme.colors[theme.primaryColor][3] //theme.colors.dark[4]
    //     : theme.colors[theme.primaryColor][1], // theme.colors.gray[1],
  },

  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));

export function TobSellingProducts({ topSellingProducts }) {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();

  const rows = topSellingProducts?.map((item, index) => {
    return (
      <tr key={index}>
        <td>
          <Group spacing="xs">
            <Avatar
              size={36}
              src={item?.image ? `${PRODUCT_IMAGE_URL}/${item.image}` : null}
              radius="xs"
              color={theme.primaryColor}
            >
              {getAvatarName(item.name)}
            </Avatar>
            <div>
              <Text size="sm" weight={500}>
                {item.name}
              </Text>
              <Text c="dimmed" fz="xs" fw={400}>
                {item.productSKU}
              </Text>
            </div>
          </Group>
        </td>
        <td>{item.category}</td>
        <td className="text-center fw-bold">{item.qty}</td>
      </tr>
    );
  });

  return (
    // <Card withBorder shadow={1} radius="md">
    <Paper shadow="xl" radius="md" p="xl">
      {/* <Card.Section withBorder inheritPadding py="xs" mb={8}> */}
      <Group justify="space-between" mb="md">
        <Text size="lg" fw={500}>
          Top Selling Products
        </Text>
      </Group>

      {/* </Card.Section> */}
      <ScrollArea>
        <Table miw={400} verticalSpacing="xs" highlightOnHover>
          <thead>
            <tr mt={10}>
              <th
                className={classes.name}
                style={{
                  borderBottom: "none",
                  color: ThemeColourTableHeadText(),
                }}
              >
                Name
              </th>
              <th
                className={classes.allTh}
                style={{
                  borderBottom: "none",
                  // color: theme.colors[theme.primaryColor][7],
                  color: ThemeColourTableHeadText(),
                }}
              >
                Category
              </th>
              <th
                className={classes.qty}
                style={{
                  borderBottom: "none",
                  color: ThemeColourTableHeadText(),
                }}
              >
                Qty
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
            {/* <tr>
              <td>element.position</td>
              <td>element.name</td>
              <td>element.symbol</td>
            </tr>
            <tr>
              <td>element.position</td>
              <td>element.name</td>
              <td>element.symbol</td>
            </tr>
            <tr>
              <td>element.position</td>
              <td>element.name</td>
              <td>element.symbol</td>
            </tr>
            <tr>
              <td>element.position</td>
              <td>element.name</td>
              <td>element.symbol</td>
            </tr>
            <tr>
              <td>element.position</td>
              <td>element.name</td>
              <td>element.symbol</td>
            </tr> */}
          </tbody>
        </Table>
      </ScrollArea>
    </Paper>
    // </Card>
  );
}
