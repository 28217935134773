import { useState } from "react";
import {
  createStyles,
  Avatar,
  UnstyledButton,
  Group,
  Text,
  Menu,
  rem,
  Indicator,
  ActionIcon,
  Switch,
  Flex,
  Center,
  Paper,
} from "@mantine/core";
// import { useDisclosure } from "@mantine/hooks";
import { useSelector } from "react-redux";
import {
  IconLogout,
  IconSettings,
  IconChevronDown,
  IconBellFilled,
  IconSun,
  IconMoonStars,
  IconUser,
} from "@tabler/icons-react";
import LogoutModal from "./logout_modal";
import getAvatarName from "../utils/get_avatar_name";

const useStyles = createStyles((theme) => ({
  header: {
    paddingTop: theme.spacing.sm,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    borderBottom: `${rem(0)} solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[0]
    }`,
    // marginBottom: rem(120),
    // height: 70,
  },

  mainSection: {
    paddingBottom: theme.spacing.sm,
  },

  user: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.gray[1],
    },

    // [theme.fn.smallerThan("xs")]: {
    //   display: "none",
    // },
  },
  userName: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
  emptyDiv: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
  logo: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  burger: {
    [theme.fn.largerThan("xs")]: {
      display: "none",
    },
  },

  userActive: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
}));

const USER_IMAGE_URL = process.env.REACT_APP_USER_IMAGE_URL;
const COMPANY_IMAGE_URL = process.env.REACT_APP_COMPANY_IMAGE_URL;

export function TopBar({ setColourMode, activeMode }) {
  const userData = useSelector((state) => state.auth.auth.userData);
  const { classes, theme, cx } = useStyles();
  // const [opened, { toggle }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);

  // console.log("activeMode", activeMode);
  // console.log("userData => ", userData);

  const setMode = (value) => {
    if (value) {
      setColourMode("dark");
    } else {
      setColourMode("light");
    }
  };

  const toggleLogout = () => {
    setOpenLogout((value) => !value);
  };

  const user = {
    // name: `${userData?.firstName} ${userData?.surname}`,
    name: `Hi, ${userData?.firstName}`,
    email: `${userData?.email}`,
    image: `${userData?.avatar}`,
  };

  return (
    <div className="w-100">
      {/* <Paper radius={0} p={0} m={0}> */}
      <LogoutModal opened={openLogout} closeFunction={toggleLogout} />
      {/* <div className={classes.header}> */}
      {/* <Container className={classes.mainSection}> */}
      <Group position="apart">
        {/* <div className={classes.emptyDiv}></div> */}

        <div style={{ display: "Flex" }}>
          <Avatar
            className={classes.logo}
            color={theme.primaryColor}
            src={
              userData?.logo ? `${COMPANY_IMAGE_URL}/${userData?.logo}` : null
            }
            styles={{ image: { objectFit: "contain" } }}
          >
            {getAvatarName(userData?.comName)}
          </Avatar>

          <Center className={classes.logo}>
            <Text
              ml="sm"
              fz="lg"
              fw={600}
              color={
                theme.colorScheme === "dark"
                  ? theme.colors.gray[5]
                  : theme.colors.dark[9]
              }
            >
              {userData?.comName}
            </Text>
          </Center>
        </div>

        {/* <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        /> */}

        <Flex
          gap="xs"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          {userData?.type === "admin" && (
            <Indicator inline label="0" size={16} mr="sm">
              <ActionIcon variant="subtle">
                <IconBellFilled size="md" stroke={1.5} />
              </ActionIcon>
            </Indicator>
          )}

          <Switch
            checked={theme.colorScheme === "dark"}
            defaultValue={theme.colorScheme === "dark" ? false : true}
            onChange={(event) => setMode(event.target.checked)}
            size="md"
            onLabel={
              <IconSun color={theme.white} size="1.25rem" stroke={1.5} />
            }
            offLabel={
              <IconMoonStars
                color={theme.colors.gray[6]}
                size="1.25rem"
                stroke={1.5}
              />
            }
          />

          <Menu
            width={150}
            position="bottom-end"
            transitionProps={{ transition: "pop-top-right" }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton className={classes.user}>
                <Group spacing={7}>
                  <Avatar
                    src={user?.image ? `${USER_IMAGE_URL}/${user.image}` : null}
                    alt={user?.name}
                    radius="xl"
                    size={32}
                    color={theme.primaryColor}
                  >
                    {getAvatarName(user?.name)}
                  </Avatar>
                  <Text
                    weight={500}
                    size="sm"
                    sx={{ lineHeight: 1 }}
                    mr={3}
                    className={classes.userName}
                  >
                    {user.name}
                  </Text>
                  <IconChevronDown size={rem(12)} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                icon={
                  <IconUser
                    size="0.9rem"
                    color={theme.colors.gray[6]}
                    stroke={1.5}
                  />
                }
              >
                My Profile
              </Menu.Item>
              {userData?.type === "admin" && (
                <Menu.Item
                  icon={
                    <IconSettings
                      size="0.9rem"
                      color={theme.colors.gray[6]}
                      stroke={1.5}
                    />
                  }
                >
                  Settings
                </Menu.Item>
              )}

              <Menu.Divider />

              <Menu.Item
                color="red"
                icon={<IconLogout size="0.9rem" stroke={1.5} />}
                onClick={() => toggleLogout()}
              >
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Group>
      {/* </Paper> */}
      {/* </Container> */}
    </div>
  );
}
