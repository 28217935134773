import { useState, useEffect } from "react";
import { Grid, Container } from "@mantine/core";
import Branch from "../../components/branch";
import CustomersTable from "./components/customers_table";
import { useCustomersAPI } from "../../services/customers";
import ShowLoader from "../../components/loader";

export default function Customers() {
  document.title = "Customers || Smart Stock";
  const { getCus, addCus, updateCus, deleteCus } = useCustomersAPI();

  const [customersData, setCustomersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setModalIsLoading] = useState(false);

  const getCustomersData = async () => {
    try {
      setIsLoading(true);

      const results = await getCus();
      if (results.status) {
        setCustomersData(results.customers);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const addCustomer = async ({ name, phone, address }) => {
    try {
      setModalIsLoading(true);

      const results = await addCus({
        name: name,
        phone: phone,
        address: address,
      });
      if (results.status) {
        getCustomersData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  const updateCustomer = async ({ id, name, phone, address }) => {
    try {
      setModalIsLoading(true);

      const results = await updateCus({
        name: name,
        phone: phone,
        address: address,
        id: id,
      });
      if (results.status) {
        getCustomersData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  const deleteCustomer = async ({ id }) => {
    try {
      setModalIsLoading(true);

      const results = await deleteCus({
        id: id,
      });
      if (results.status) {
        getCustomersData();
      }
    } catch (error) {
    } finally {
      setModalIsLoading(false);
    }
  };

  useEffect(() => {
    getCustomersData();
  }, []);

  return (
    <Container pb="xl" fluid={true}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Branch
            pageTitle="Customers"
            onChangeFn={getCustomersData}
            disableBranchSelector={isLoading}
          />
        </Grid.Col>
        {isLoading && <ShowLoader text="Loading Data..." mt={150} />}
        {!isLoading && (
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomersTable
              branches={customersData ?? []}
              isModalLoading={isModalLoading}
              addBranchFn={addCustomer}
              updateBranchFn={updateCustomer}
              deleteBranchFn={deleteCustomer}
            />
          </Grid.Col>
        )}
      </Grid>
    </Container>
  );
}
