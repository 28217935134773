import {
  Grid,
  Container,
  Text,
  Button,
  Modal,
  useMantineTheme,
  Flex,
  Center,
  ThemeIcon,
} from "@mantine/core";

import { IconCheck, IconShoppingCart, IconX } from "@tabler/icons-react";

const ContinueSalesModal = ({ opened, closeFunction, operationFn }) => {
  const theme = useMantineTheme();

  const closeModal = () => {
    closeFunction(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      withCloseButton={false}
      centered={true}
      closeOnClickOutside={false}
      size="sm"
      // title="Sales Completed Successfully"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <Grid mt="xxl" mb="xxl">
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12} mt="lg">
            <Center>
              <ThemeIcon radius={32} color="teal" size={64}>
                <IconCheck size={32} />
              </ThemeIcon>
            </Center>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Center>
              <Text fw={400} align="center">
                Sales Completed Successfully
                <br />
                Do you want to do another one?
              </Text>
            </Center>
          </Grid.Col>

          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12} mt="sm" mb="lg">
            <Center>
              <Flex
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  variant="outline"
                  leftIcon={<IconX size="1rem" />}
                  color="gray"
                  onClick={operationFn}
                  px="xl"
                >
                  No
                </Button>
                <Button
                  onClick={closeModal}
                  variant="outline"
                  leftIcon={<IconShoppingCart size="1rem" />}
                  color={theme.primaryColor}
                  px="xl"
                >
                  Yes
                </Button>
              </Flex>
              {/* <Stack align="center" spacing="xs">
                <Text fw={400} align="center">
                  Sales In Progress.
                  <br />
                  Please Wait...
                </Text>
              </Stack> */}
            </Center>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};

export default ContinueSalesModal;
