import {
  Grid,
  Container,
  Text,
  Button,
  Modal,
  useMantineTheme,
  Flex,
} from "@mantine/core";

import { IconShoppingCart, IconX } from "@tabler/icons-react";

const ConfirmSalesModal = ({ opened, closeFunction, operationFn }) => {
  const theme = useMantineTheme();

  const closeModal = () => {
    closeFunction(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered={true}
      closeOnClickOutside={false}
      size="lg"
      title="Confirm Sales?"
      overlayProps={{
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.4,
        blur: 4,
      }}
    >
      <Container fluid={true}>
        <Grid>
          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Text fw={400}>Do you want to complete this sales?</Text>
          </Grid.Col>

          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Flex
              gap="md"
              justify="flex-end"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Button
                variant="outline"
                leftIcon={<IconX size="1rem" />}
                color="gray"
                onClick={closeModal}
                px="xl"
              >
                No
              </Button>
              <Button
                onClick={operationFn}
                variant="outline"
                leftIcon={<IconShoppingCart size="1rem" />}
                color={theme.primaryColor}
                px="xl"
              >
                Yes
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};

export default ConfirmSalesModal;
