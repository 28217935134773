// const listUsersDataAPI = async ({ token, branchId }) => {
//   return await axios
//     .get(`${API_URL}/list-users?branchId=${branchId}`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((res) => {
//       if (!res.data.status) {
//         showToast({
//           //Only show this if there's an error
//           color: "red",
//           title: "Error",
//           message: res.data.message,
//         });
//       }
//       return res.data;
//     })
//     .catch((err) => {
//       showToast({
//         color: "red",
//         title: "Unknown Error",
//         message: "An error occured. Please try again.",
//       });
//       return {
//         status: false,
//         message: "Could not load users data. Please try again.",
//       };
//     });
// };

// const addUserAPI = async ({ token, data }) => {
//   return await axios
//     .post(`${API_URL}/add-new-user/`, data, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((res) => {
//       console.log("res.data.status", res.data.status);
//       if (!res.data.status) {
//         showToast({
//           //Only show this if there's an error
//           color: "red",
//           title: "An error occured",
//           message: res.data.message,
//         });
//       } else {
//         showToast({
//           color: "green",
//           title: "Success",
//           message: res.data.message,
//         });
//       }
//       return res.data;
//     })
//     .catch((err) => {
//       showToast({
//         color: "red",
//         title: "An error occured",
//         message: "Could not save user data. Please try again.",
//       });
//       return {
//         status: false,
//         message: "Could not save product data. Please try again.",
//       };
//     });
// };

// const updateCustomerAPI = async ({ token, data }) => {
//   return await axios
//     .post(`${API_URL}/update-customer/`, data, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((res) => {
//       if (!res.data.status) {
//         showToast({
//           //Only show this if there's an error
//           color: "red",
//           title: "An error occured",
//           message: res.data.message,
//         });
//       } else {
//         showToast({
//           color: "green",
//           title: "Success",
//           message: res.data.message,
//         });
//       }
//       return res.data;
//     })
//     .catch((err) => {
//       showToast({
//         color: "red",
//         title: "An error occured",
//         message: "Could not update customer data. Please try again.",
//       });
//       return {
//         status: false,
//         message: "Could not update customer data. Please try again.",
//       };
//     });
// };

// const deleteCustomerAPI = async ({ token, data }) => {
//   return await axios
//     .post(`${API_URL}/delete-customer/`, data, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((res) => {
//       if (!res.data.status) {
//         showToast({
//           //Only show this if there's an error
//           color: "red",
//           title: "An error occured",
//           message: res.data.message,
//         });
//       } else {
//         showToast({
//           color: "green",
//           title: "Success",
//           message: res.data.message,
//         });
//       }
//       return res.data;
//     })
//     .catch((err) => {
//       showToast({
//         color: "red",
//         title: "An error occured",
//         message: "Could not delete customer data. Please try again.",
//       });
//       return {
//         status: false,
//         message: "Could not delete customer data. Please try again.",
//       };
//     });
// };

// export { listUsersDataAPI, addUserAPI, updateCustomerAPI, deleteCustomerAPI };

import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux_slices/auth";
import showToast from "../components/toast";
import { useSelector } from "react-redux";

const API_URL = process.env.REACT_APP_API_URL;

export function useUsersAPI() {
  const token = useSelector((state) => state.auth.auth.token);
  const branchId = useSelector((state) => state.branch.branch.activeId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUsers = async () => {
    return await axios
      .get(`${API_URL}/list-users?branchId=${branchId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "Error",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "Unknown Error",
            message: "An error occured. Please try again.",
          });
          return {
            status: false,
            message: "Could not load users data. Please try again.",
          };
        }
      });
  };

  const getUserDetails = async ({ id }) => {
    return await axios
      .get(`${API_URL}/user-details?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "Error",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not load user data. Please try again.",
          });
          return {
            status: false,
            message: "Could not load user data. Please try again.",
          };
        }
      });
  };

  const addUser = async (data) => {
    return await axios
      .post(
        `${API_URL}/add-new-user/`,
        {
          branchId: data.branchId,
          email: data.email,
          firstName: data.firstName,
          image: data.image,
          phone: data.phone,
          role: data.role,
          surname: data.surname,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not save user data. Please try again.",
          });
          return {
            status: false,
            message: "Could not save product data. Please try again.",
          };
        }
      });
  };

  const updateUser = async ({ id, name, phone, address }) => {
    return await axios
      .post(
        `${API_URL}/update-branch/`,
        {
          id: id,
          name: name,
          phone: phone,
          address: address,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not update branch data. Please try again.",
          });
          return {
            status: false,
            message: "Could not update branch data. Please try again.",
          };
        }
      });
  };

  const deleteUser = async ({ id }) => {
    return await axios
      .post(
        `${API_URL}/delete-branch/`,
        { id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          showToast({
            //Only show this if there's an error
            color: "red",
            title: "An error occured",
            message: res.data.message,
          });
        } else {
          showToast({
            color: "green",
            title: "Success",
            message: res.data.message,
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (
          err?.response?.status === 422 &&
          err?.response?.data?.status === false &&
          err?.response?.data?.message === "Token Expired"
        ) {
          showToast({
            color: "red",
            title: "Session Expired",
            message: "Please login to continue",
          });
          navigate("/login");
          dispatch(logout()); //Clearing the user data in the redux state manager
        } else {
          showToast({
            color: "red",
            title: "An error occured",
            message: "Could not delete branch data. Please try again.",
          });
          return {
            status: false,
            message: "Could not delete branch data. Please try again.",
          };
        }
      });
  };

  return {
    getUsers,
    addUser,
    getUserDetails,
    // updateUser,
    // deleteUser,
  };
}
